import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  DatePicker,
  Input,
  Select,
  Spin,
  TimePicker,
  Typography,
  Tooltip,
  Collapse,
  Modal,
  Button,
  Radio,
  message,
  Checkbox,
  AutoComplete,
  Card,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { LoadingOutlined } from "@ant-design/icons";

import axios from "../../../Utils/axios";
import dayjs from "dayjs";

const { TextArea } = Input;
const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const options = [
  {
    label: "Journée(s) entière(s)",
    value: "Standare",
  },
  {
    label: "Demi journée",
    value: "Half",
  },
];

const maxChecked = 4; // nombre maximum de cases à cocher

const LeaveFormAdmin = () => {
  const [form] = Form.useForm();
  const [Loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [Type, setType] = useState("");
  const [Reasons, setReasons] = useState([]);
  const [SubReason, setSubReason] = useState([]);
  const [yearlyLeaveType, setYearlyLeaveType] = useState("Standare");
  const [Xs, setXs] = useState("");
  const [users, setUsers] = useState([]);
  const [SaufDays, setSaufDays] = useState([]);
  const [checkedCount, setCheckedCount] = useState(0);
  const [userOptions, setUserOptions] = useState([]);
  const [value, setValue] = useState("");
  const [beginingDate, setBeginingDate] = useState();

  const getPanelValue = (searchText) =>
    !searchText || !users
      ? []
      : [
        ...users.filter((elem) =>
          elem?.label?.toLowerCase()?.includes(searchText.toLowerCase())
        ),
      ];

  const Navigate = useNavigate();

  const onChange3 = ({ target: { value } }) => {
    setYearlyLeaveType(value);
  };

  const onChangeX = ({ target: { value } }) => {
    setXs(value);
  };

  const onChangeType = (value) => {
    setType(value);
    setSubReason([]);
    form.setFieldsValue({
      BeginingDate: null,
      EndingDate: null,
      _special_leave: null,
      _event: null,
      _motif: null,
    });
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const onChangeReason = (value) => {
    const newSubReason = Reasons.find((Reason) => Reason.label === value);
    setSubReason(newSubReason);
  };

  useEffect(() => {
    async function fetchReasons() {
      const { data } = await axios.get("/api/types");
      setReasons(data.types);
      // setLoading(false);
      return data.types;
    }

    fetchReasons();

    async function fetchEmployees() {
      const { data } = await axios.get("/api/employees/");
      if (data.status === "success") {
        const temp = data?.employees?.map((elem) => {
          return {
            value: elem.fullName,
            id: elem.matricule,
            label: elem.fullName,
          };
        });
        setUsers(temp);
        setUserOptions(temp);
        setLoading(false);
      }
    }
    fetchEmployees();
  }, []);

  const handlePostLeave = async (formValues) => {
    setDisabled(true);
    if (formValues._typeLeave === "Autorisation") {
      formValues.EndingDate = formValues.BeginingDate;
    }
    if (formValues._typeLeave === "Télétravail") {
      formValues._except = SaufDays;
    }
    if (
      (formValues._typeLeave === "Congé annuel" ||
        formValues._typeLeave === "Absence non justifier" ||
        formValues._typeLeave === "Absence de reporting") &&
      yearlyLeaveType === "Half"
    ) {
      if (!Xs) {
        return message.error("Veuillez choisir la seance");
      }
      formValues.EndingDate = formValues.BeginingDate;
      formValues.typeAnnuel = yearlyLeaveType;
      formValues.typeHalf = Xs;
    } else if (
      (formValues._typeLeave === "Congé annuel" ||
        formValues._typeLeave === "Absence non justifier" ||
        formValues._typeLeave === "Absence de reporting") &&
      yearlyLeaveType === "Standare"
    ) {
      formValues.typeAnnuel = yearlyLeaveType;
    }

    if (
      formValues._typeLeave === "Congé sans solde" &&
      yearlyLeaveType === "Half"
    ) {
      if (!Xs) {
        return message.error("Veuillez choisir la seance");
      }
      formValues.EndingDate = formValues.BeginingDate;
      formValues.typeAnnuel = yearlyLeaveType;
      formValues.typeHalf = Xs;
    } else if (
      formValues._typeLeave === "Congé sans solde" &&
      yearlyLeaveType === "Standare"
    ) {
      formValues.typeAnnuel = yearlyLeaveType;
    }

    formValues.idEmployee = value;

    try {
      const { data } = await axios.post(
        "/api/leaves/declare/absence",
        formValues
      );
      if (data) {
        Modal.success({
          title: "Action a été soumise avec succès !",
          content: <div style={{ whiteSpace: "pre-wrap" }}>{data.message}</div>,
        });
        setDisabled(false);
        form.resetFields();
      }
    } catch (error) {
      Modal.error({
        title: "Impossible de déclarer l'absence/congé !",
        content: (
          <div style={{ whiteSpace: "pre-wrap" }}>
            {error.response.data.message}
          </div>
        ),
      });
      setDisabled(false);
    }
  };

  const slectDay = (e) => {
    if (SaufDays.includes(e.target.name)) {
      let tab = [...SaufDays];
      tab.splice(tab.indexOf(e.target.name), 1);
      setSaufDays(tab);
    } else {
      if (SaufDays.length < maxChecked) {
        let tab = [...SaufDays];
        tab.push(e.target.name);
        setSaufDays(tab);
      } else {
        return message.error("Veuillez choisir maximum 4 choix");
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    const newCount = checked ? checkedCount + 1 : checkedCount - 1;
    setCheckedCount(newCount);
  };

  const onSelect = (data) => {
    const temp = users?.find((elem) => elem.value === data).id;

    setValue(temp);
  };

  return (
    <div className="">
      {Loading ? (
        <div className="d-flex justify-content-center ">
          <Spin size="large" />
        </div>
      ) : (
        <div className="p-4 ">
          <div>
            <div className="mb-3">
              <Typography.Title level={4}>
                Déclarer une absence
              </Typography.Title>
            </div>
            <div className="row ">
              <div className="col-lg-6 col-md-12 col-sm-12 mt-3">
                <Card>
                  <Form
                    className="p-3"
                    form={form}
                    layout="vertical"
                    size="middle"
                    disabled={disabled}
                    onFinish={handlePostLeave}
                  >
                    <Form.Item
                      name="idEmployee"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez remplir le champ",
                        },
                      ]}
                      label="Collaborateur"
                    >
                      <AutoComplete
                        options={userOptions}
                        onSelect={onSelect}
                        onSearch={(text) => setUserOptions(getPanelValue(text))}
                        placeholder="Rechercher"
                      />
                    </Form.Item>
                    <Form.Item
                      name="_typeLeave"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez remplir le champ",
                        },
                      ]}
                      label="Type"
                    >
                      <Select
                        placeholder="Choisir un type"
                        onChange={onChangeType}
                      >
                        <Option key="1" value="Congé annuel">
                          Congé annuel
                        </Option>
                        <Option key="2" value="Congé Special">
                          Congé Special
                        </Option>
                        <Option key="4" value="Congé Maladie">
                          Congé Maladie
                        </Option>
                        <Option key="6" value="Congé sans solde">
                          Congé sans solde
                        </Option>
                        <Option key="3" value="Télétravail">
                          Télétravail
                        </Option>
                        <Option key="5" value="Autorisation">
                          Autorisation
                        </Option>
                        <Option key="10" value="Absence non justifier">
                          Absence non justifier
                        </Option>
                        <Option key="11" value="Absence de reporting">
                          Absence de reporting
                        </Option>
                      </Select>
                    </Form.Item>

                    {Type === "Congé Special" && (
                      <>
                        <Form.Item
                          label="Congé Special"
                          name="_special_leave"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Choisir un Congé"
                            onChange={onChangeReason}
                          >
                            {Reasons.map((Reason, index) => {
                              return (
                                <Option key={index} value={Reason.label}>
                                  {Reason.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </>
                    )}

                    {SubReason.label ===
                      "Congés exceptionnels pour raison de famille" && (
                        <>
                          <Form.Item
                            name="_event"
                            label="Motif"
                            rules={[
                              {
                                whitespace: true,
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                          >
                            <Select placeholder="Choisir un motif">
                              {SubReason.value.map((Reason) => {
                                return (
                                  <Option key={Reason._id} value={Reason.label}>
                                    {Reason.label}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          <div className="row">
                            <Form.Item
                              className="col-lg-6"
                              name="BeginingDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                },
                              ]}
                              label="Date de début"
                            >
                              <DatePicker
                                onChange={(dateString) => {
                                  setBeginingDate(dateString);
                                }}
                                format={"DD/MM/YYYY"}
                                style={{ width: "100%" }}
                                placeholder="Date de début"
                                showToday={true}
                              />
                            </Form.Item>
                            <Form.Item
                              className="col-lg-6"
                              name="EndingDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                },
                              ]}
                              label="Date de fin:"
                            >
                              <DatePicker
                                format={"DD/MM/YYYY"}
                                style={{ width: "100%" }}
                                placeholder="Date de fin"
                                showToday={true}
                              />
                            </Form.Item>
                          </div>
                        </>
                      )}
                    {(SubReason.label === "Maternité" ||
                      SubReason.label === "Obligations militaires ") && (
                        <div className="row">
                          <Form.Item
                            className="col-lg-6"
                            name="BeginingDate"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                            label="Date de début"
                          >
                            <DatePicker
                              onChange={(dateString) => {
                                setBeginingDate(dateString);
                              }}
                              format={"DD/MM/YYYY"}
                              style={{ width: "100%" }}
                              placeholder="Date de début"
                              showToday={true}
                            />
                          </Form.Item>
                          <Form.Item
                            className="col-lg-6"
                            name="EndingDate"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                            label="Date de fin:"
                          >
                            <DatePicker
                              format={"DD/MM/YYYY"}
                              style={{ width: "100%" }}
                              placeholder="Date de fin"
                              showToday={true}
                            />
                          </Form.Item>
                        </div>
                      )}

                    {Type === "Autorisation" && (
                      <>
                        <Form.Item
                          name="BeginingDate"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                          label="Date"
                        >
                          <DatePicker
                            format={"DD/MM/YYYY"}
                            style={{ width: "100%" }}
                            placeholder="Date"
                            showToday={true}
                          />
                        </Form.Item>
                        <Form.Item
                          name="_duration"
                          label="Durée"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                        >
                          <TimePicker.RangePicker
                            format="HH:mm"
                            className="w-100"
                            minuteStep={30}
                          />
                        </Form.Item>
                        <Form.Item
                          name="_motif"
                          rules={[
                            {
                              whitespace: true,
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                          label="Motif"
                        >
                          <TextArea
                            placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                            autoSize={{
                              minRows: 4,
                              maxRows: 4,
                            }}
                          />
                        </Form.Item>
                      </>
                    )}
                    {Type === "Télétravail" && (
                      <>
                        <div className="row">
                          <Form.Item
                            className="col-lg-6"
                            name="BeginingDate"
                            onChange={(dateString) => {
                              setBeginingDate(dateString);
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                            label="Date de début"
                          >
                            <DatePicker
                              format={"DD/MM/YYYY"}
                              style={{ width: "100%" }}
                              placeholder="Date de début"
                              showToday={true}
                            />
                          </Form.Item>
                          <Form.Item
                            className="col-lg-6"
                            name="EndingDate"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                            label="Date de fin:"
                          >
                            <DatePicker
                              format={"DD/MM/YYYY"}
                              style={{ width: "100%" }}
                              placeholder="Date de fin"
                            />
                          </Form.Item>
                        </div>
                        <Form.Item label="Sauf">
                          <div className="row">
                            <div className="col-lg-4">
                              <Checkbox
                                name="lundi"
                                onChange={(e) => {
                                  slectDay(e);
                                  handleCheckboxChange(e);
                                }}
                              >
                                lundi
                              </Checkbox>
                            </div>

                            <div className="col-lg-4">
                              <Checkbox
                                name="mardi"
                                onChange={(e) => {
                                  slectDay(e);
                                  handleCheckboxChange(e);
                                }}
                              >
                                Mardi
                              </Checkbox>
                            </div>
                            <div className="col-lg-4">
                              <Checkbox
                                name="mercredi"
                                onChange={(e) => {
                                  slectDay(e);
                                  handleCheckboxChange(e);
                                }}
                              >
                                Mercredi
                              </Checkbox>
                            </div>
                            <div className="col-lg-4">
                              <Checkbox
                                name="jeudi"
                                onChange={(e) => {
                                  slectDay(e);
                                  handleCheckboxChange(e);
                                }}
                              >
                                Jeudi
                              </Checkbox>
                            </div>
                            <div className="col-lg-4">
                              <Checkbox
                                name="vendredi"
                                onChange={(e) => {
                                  slectDay(e);
                                  handleCheckboxChange(e);
                                }}
                              >
                                Vendredi
                              </Checkbox>
                            </div>
                          </div>
                        </Form.Item>

                        <Form.Item
                          name="_motif"
                          rules={[
                            {
                              whitespace: true,
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                          label="Motif"
                        >
                          <TextArea
                            placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                            autoSize={{
                              minRows: 4,
                              maxRows: 4,
                            }}
                          />
                        </Form.Item>
                      </>
                    )}
                    {(Type === "Congé annuel" ||
                      Type === "Congé sans solde") && (
                        <>
                          <Form.Item label="Durée">
                            <Radio.Group
                              options={options}
                              defaultValue={"Standare"}
                              onChange={onChange3}
                              value={yearlyLeaveType}
                              optionType="button"
                            />
                          </Form.Item>
                          {yearlyLeaveType === "Standare" ? (
                            <>
                              <div className="row">
                                <Form.Item
                                  className="col-lg-6"
                                  name="BeginingDate"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Veuillez remplir le champ",
                                    },
                                  ]}
                                  label="Date de début"
                                >
                                  <DatePicker
                                    onChange={(dateString) => {
                                      setBeginingDate(dateString);
                                    }}
                                    format={"DD/MM/YYYY"}
                                    style={{ width: "100%" }}
                                    placeholder="Date de début"
                                    showToday={true}
                                  />
                                </Form.Item>
                                <Form.Item
                                  className="col-lg-6"
                                  name="EndingDate"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Veuillez remplir le champ",
                                    },
                                  ]}
                                  label="Date de fin:"
                                >
                                  <DatePicker
                                    format={"DD/MM/YYYY"}
                                    style={{ width: "100%" }}
                                    placeholder="Date de fin"
                                    showToday={true}
                                  />
                                </Form.Item>
                              </div>
                              <Form.Item
                                name="_motif"
                                rules={[
                                  {
                                    whitespace: true,
                                    required: true,
                                    message: "Veuillez remplir le champ",
                                  },
                                ]}
                                label="Motif"
                              >
                                <TextArea
                                  placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                                  autoSize={{
                                    minRows: 4,
                                    maxRows: 4,
                                  }}
                                  maxLength={400}
                                />
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <Form.Item
                                name="BeginingDate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Veuillez remplir le champ",
                                  },
                                ]}
                                label="Date"
                              >
                                <DatePicker
                                  onChange={(dateString) => {
                                    setBeginingDate(dateString);
                                  }}
                                  format={"DD/MM/YYYY"}
                                  style={{ width: "100%" }}
                                  placeholder="Date de début"
                                  showToday={true}
                                />
                              </Form.Item>
                              <Form.Item label="Séance">
                                <Radio.Group
                                  defaultValue={"X8"}
                                  onChange={onChangeX}
                                  value={Xs}
                                >
                                  <Radio.Button value={"X8"}>Matin</Radio.Button>
                                  <Radio.Button value={"X12"}>
                                    Aprés midi
                                  </Radio.Button>
                                </Radio.Group>
                              </Form.Item>

                              <Form.Item
                                name="_motif"
                                rules={[
                                  {
                                    whitespace: true,
                                    required: true,
                                    message: "Veuillez remplir le champ",
                                  },
                                ]}
                                label="Motif"
                              >
                                <TextArea
                                  placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                                  autoSize={{
                                    minRows: 4,
                                    maxRows: 4,
                                  }}
                                  maxLength={400}
                                />
                              </Form.Item>
                            </>
                          )}
                        </>
                      )}
                    {Type === "Congé Maladie" && (
                      <>
                        <div className="row">
                          <Form.Item
                            className="col-lg-6"
                            name="BeginingDate"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                            label="Date de début"
                          >
                            <DatePicker
                              onChange={(dateString) => {
                                setBeginingDate(dateString);
                              }}
                              format={"DD/MM/YYYY"}
                              style={{ width: "100%" }}
                              placeholder="Date de début"
                              showToday={true}
                            />
                          </Form.Item>
                          <Form.Item
                            className="col-lg-6"
                            name="EndingDate"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                            label="Date de fin:"
                          >
                            <DatePicker
                              format={"DD/MM/YYYY"}
                              style={{ width: "100%" }}
                              placeholder="Date de fin"
                              showToday={true}
                            />
                          </Form.Item>
                        </div>
                        <Form.Item>
                          <Text
                            type="danger"
                            strong
                            style={{ fontSize: "14px" }}
                          >
                            Vous êtes tenus de joindre un justificatif à votre
                            demande dans un délai de 48 heures.{" "}
                            <Tooltip title="Consulter la rubrique 'Mes demandes' pour joindre un justificatif">
                              <FontAwesomeIcon
                                className="text-black-50"
                                icon={faExclamationCircle}
                              />
                            </Tooltip>
                          </Text>
                        </Form.Item>
                      </>
                    )}
                    {(Type === "Absence de reporting" ||
                      Type === "Absence non justifier") && (
                        <>
                          <Form.Item label="Durée">
                            <Radio.Group
                              options={options}
                              defaultValue={"Standare"}
                              onChange={onChange3}
                              value={yearlyLeaveType}
                              optionType="button"
                            />
                          </Form.Item>

                          {yearlyLeaveType === "Standare" ? (
                            <>
                              <div className="row">
                                <Form.Item
                                  className="col-lg-6"
                                  name="BeginingDate"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Veuillez remplir le champ",
                                    },
                                  ]}
                                  label="Date de début"
                                >
                                  <DatePicker
                                    onChange={(dateString) => {
                                      setBeginingDate(dateString);
                                    }}
                                    disabledDate={disabledDate}
                                    format={"DD/MM/YYYY"}
                                    style={{ width: "100%" }}
                                    placeholder="Date de début"
                                    showToday={true}
                                  />
                                </Form.Item>
                                <Form.Item
                                  className="col-lg-6"
                                  name="EndingDate"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Veuillez remplir le champ",
                                    },
                                  ]}
                                  label="Date de fin:"
                                >
                                  <DatePicker
                                    format={"DD/MM/YYYY"}
                                    style={{ width: "100%" }}
                                    placeholder="Date de fin"
                                    disabledDate={disabledDate}
                                    showToday={true}
                                  />
                                </Form.Item>
                              </div>
                              <Form.Item
                                name="_motif"
                                rules={[
                                  {
                                    whitespace: true,
                                    required: true,
                                    message: "Veuillez remplir le champ",
                                  },
                                ]}
                                label="Motif"
                              >
                                <TextArea
                                  placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                                  autoSize={{
                                    minRows: 4,
                                    maxRows: 4,
                                  }}
                                  maxLength={400}
                                />
                              </Form.Item>
                            </>
                          ) : (
                            <>
                              <Form.Item
                                name="BeginingDate"
                                rules={[
                                  {
                                    required: true,
                                    message: "Veuillez remplir le champ",
                                  },
                                ]}
                                label="Date"
                              >
                                <DatePicker
                                  onChange={(dateString) => {
                                    setBeginingDate(dateString);
                                  }}
                                  disabledDate={disabledDate}
                                  format={"DD/MM/YYYY"}
                                  style={{ width: "100%" }}
                                  placeholder="Date de début"
                                  showToday={true}
                                />
                              </Form.Item>
                              <Form.Item label="Séance">
                                <Radio.Group
                                  defaultValue={"X8"}
                                  onChange={onChangeX}
                                  value={Xs}
                                >
                                  <Radio.Button value={"X8"}>Matin</Radio.Button>
                                  <Radio.Button value={"X12"}>
                                    Aprés midi
                                  </Radio.Button>
                                </Radio.Group>
                              </Form.Item>

                              <Form.Item
                                name="_motif"
                                rules={[
                                  {
                                    whitespace: true,
                                    required: true,
                                    message: "Veuillez remplir le champ",
                                  },
                                ]}
                                label="Motif"
                              >
                                <TextArea
                                  placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                                  autoSize={{
                                    minRows: 4,
                                    maxRows: 4,
                                  }}
                                  maxLength={400}
                                />
                              </Form.Item>
                            </>
                          )}
                        </>
                      )}

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        disabled={disabled || checkedCount >= 5}
                        type="primary"
                      >
                        Soumettre !
                      </Button>
                      {disabled && (
                        <Spin
                          style={{ marginLeft: "10px" }}
                          size="large"
                          indicator={antIcon}
                        />
                      )}
                    </Form.Item>
                  </Form>
                </Card>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 pb-5 ">
                <h5 className="fw-bold py-2" style={{ color: "#2E79A8" }}>
                  Rèférences issues de la convention collective
                </h5>
                <Collapse
                  className="shadow-md rounded"
                  defaultActiveKey={["1"]}
                >
                  {Type === "Télétravail" && (
                    <Panel
                      header={
                        <Text strong style={{ color: "#2E79A8" }}>
                          Demande de télétravail
                        </Text>
                      }
                      key="1"
                    >
                      <p style={{ fontSize: "14px" }}>
                        La demande de télétravail doit :
                        <ul>
                          <li>
                            Etre approuvée en amont, en absence d'approbation à
                            temps, la demande est considérée comme rejetée.
                          </li>
                          <li>Respecter les conditions suivantes :</li>
                          <li>
                            Fournir une raison valable et acceptable dans la
                            demande de télétravail.
                          </li>
                          <li>
                            Etre sûr que les tâches à réaliser ne nécessitent
                            pas d’être physiquement présent sur site.
                          </li>
                          <li>
                            Disposer d’une connexion internet stable, des accès
                            à distance aux fichiers et applications et du
                            matériel nécessaire à l’exercice de l’activité.
                          </li>
                          <li>
                            Veiller à ce que la sécurité des données et des
                            appareils soit une priorité.
                          </li>
                          <li>
                            Etre disponible sur un outil de communication interne pendant les plages
                            horaires de travail convenues.
                          </li>
                        </ul>
                        <br />
                        <span className="text-danger">
                          ** Tout manquement aux conditions de la politique de
                          télétravail peut engendrer des mesures disciplinaires.
                        </span>
                      </p>
                    </Panel>
                  )}
                  {Type === "Congé annuel" && (
                    <Panel
                      header={
                        <Text strong style={{ color: "#2E79A8" }}>
                          Article 34: Congés payés
                        </Text>
                      }
                      key="1"
                    >
                      <p style={{ fontSize: "14px" }}>
                        Tout salarié en activité a droit annuellement à un congé
                        payé proportionnel à la durée effective du travail. La
                        durée du congé annuel payé, correspondant à une année de
                        travail effectif, est ainsi fixée :
                        <ul>
                          <li>
                            21 jours calendaires pour le personnel d’exécution
                            auxquels s’ajoute un jour de congé par tranche de
                            cinq années d’ancienneté dans l’établissement, sans
                            que la durée totale du congé dépasse un mois
                            calendaire. Cette ancienneté est calculée à partir
                            du 1er mai 2000.
                          </li>
                          <li>
                            1 mois calendaire pour les agents de maîtrise et les
                            cadres.
                          </li>
                        </ul>
                        La période de congé annuel est fixée par le règlement
                        intérieur de l’établissement. Toutefois, en cas de force
                        majeure, le travailleur pourrait, sur sa demande,
                        obtenir que tout ou partie de son congé lui soit accordé
                        à tout autre dayjs.
                        <br />
                        <br />
                        La date de départ en congé est fixée d’un commun accord
                        entre l’employeur et les intéressés, compte tenu des
                        besoins du service, de la situation de famille et de
                        l’ancienneté du bénéficiaire.
                        <br />
                        <br />
                        L’ordre des départs en congé doit être affiché 15 jours
                        au moins avant sa date d’entrée en application.
                        <br />
                        <br />
                        Le travailleur perçoit pendant son congé une indemnité
                        calculée sur la base de la durée de congé à laquelle il
                        a droit, d’une part et de l’intégralité du traitement et
                        des indemnités qu’il perçoit habituellement pendant
                        l’exercice effectif du travail, d’autre part.
                        <br />
                        <br />
                        N’entrent pas en ligne de compte dans le calcul de la
                        période de congé payé :
                        <ol>
                          <li>Les jours fériés,</li>
                          <li>
                            Les journées d’interruption de travail pour causes
                            de maladie ou accident
                          </li>
                        </ol>
                      </p>
                    </Panel>
                  )}
                  {Type === "Congé sans solde" && (
                    <Panel
                      header={
                        <Text strong style={{ color: "#2E79A8" }}>
                          Article 40 : Congé sans solde
                        </Text>
                      }
                      key="1"
                    >
                      <p style={{ fontSize: "14px" }}>
                        Un congé sans solde pourra être accordé par l’employeur
                        à tout travailleur qui en ferait la demande, dans la
                        limite des nécessités de service.
                        <br />
                        La durée de ce congé qui porte interruption du droit à
                        l’avancement et aux versements effectués aux organismes
                        d’assurances sociales, ne pourra excéder 90 jours par
                        an, sauf dispositions contractuelles plus favorables.
                      </p>
                    </Panel>
                  )}
                  {SubReason.label ===
                    "Congés exceptionnels pour raison de famille" && (
                      <Panel
                        header={
                          <Text strong style={{ color: "#2E79A8" }}>
                            Article 35: Congés exceptionnels pour raison de
                            famille
                          </Text>
                        }
                        key="1"
                      >
                        <p style={{ fontSize: "14px" }}>
                          Le travailleur bénéficie de congés avec maintien
                          intégral de tous les éléments du salaire à l’occasion
                          d’évènements survenus dans la famille.
                          <br />
                          La durée de ces congés est fixée comme suit :
                          <ul>
                            <li>naissance d’un enfant : 2 jours ouvrables</li>
                            <li>décès du conjoint : 3 jours ouvrables</li>
                            <li>
                              décès d’un père, d’une mère ou d’un fils : 3 jours
                              ouvrables
                            </li>
                            <li>
                              décès d’un frère, d’une sœur, d’un petit-fils ou
                              d’une petite- fille, d’un grand père ou d’une grande
                              mère : 2 jours ouvrables
                            </li>
                            <li>mariage du travailleur : 3 jours ouvrables</li>
                            <li>mariage d’un enfant : 1 jour ouvrable</li>
                            <li>circoncision d’un enfant : 1 jour ouvrable</li>
                          </ul>
                          Le bénéficiaire de ces congés doit produire les
                          justificatifs de ces évènements dans un délai de 10
                          jours
                        </p>
                      </Panel>
                    )}
                  {SubReason.label === "Maternité" && (
                    <Panel
                      header={
                        <Text strong style={{ color: "#2E79A8" }}>
                          Article 36: Congés de Maternité
                        </Text>
                      }
                      key="1"
                    >
                      <p style={{ fontSize: "14px" }}>
                        A l’occasion de son accouchement et sur production d’un
                        certificat médical le justifiant, la femme a droit à un
                        congé de repos de 30 jours. Ce congé peut être prorogé
                        chaque fois d’une période de 15 jours après production
                        de certificats médicaux.
                        <br />
                        <br />
                        La suspension du travail par la femme pendant la période
                        qui précède et suit l’accouchement ne peut être, pour
                        l’employeur, une cause de rupture du contrat de travail
                        sauf si elle se prolonge sans motif valable au-delà de
                        la période légale de congé de maternité telle que
                        définie à l’alinéa 1er du présent article ; sinon
                        l’employeur est astreint à payer des dommages intérêts.
                        <br />
                        <br />
                        De même, la maladie de la femme du fait de la grossesse
                        ou de l’accouchement, étant soumise au régime du congé
                        de maladie ne produit les effets énoncés dans l’alinéa
                        cidessus que si elle prolonge au de-là des limites
                        fixées par les dispositions légales en matière de congé
                        de maladie ou de maladie de longue durée.
                        <br />
                        <br />
                        Dans tous les cas, la femme qui allaite son enfant aura
                        droit, pendant une année à compter du jour de la
                        naissance, à deux repos d’une demi-heure chacun durant
                        les heures du travail. L’un est fixé pendant la séance
                        du matin, l’autre pendant la séance de l’après midi et
                        ce en accord avec l’employeur.
                        <br />A défaut d’accord, ces repos sont accordés au
                        milieu de chaque séance. Ces repos sont considérés comme
                        heures de travail et ouvrent droit à rémunération
                        <br />
                        <br />
                        La travailleuse qui allaite son enfant est tenue de
                        présenter un certificat médical prouvant l’allaitement
                        naturel
                      </p>
                    </Panel>
                  )}
                  {Type === "Autorisation" && (
                    <Panel
                      header={
                        <Text strong style={{ color: "#2E79A8" }}>
                          Article 27 : Absence
                        </Text>
                      }
                      key="1"
                    >
                      <p style={{ fontSize: "14px" }}>
                        Aucune absence n’est tolérée sans l’autorisation
                        préalable de l’employeur.
                        <br />
                        <br />
                        L’employeur sera avisé des absences résultant d’un cas
                        fortuit le plus rapidement possible et au plus tard dans
                        les deux jours ouvrables consécutifs à l’absence (48
                        heures).
                        <br />
                        <br />
                        Dans le cas d’absence prévisible, le travailleur ne
                        pourra s’absenter qu’après avoir obtenu au préalable
                        l’autorisation de l’employeur.
                        <br />
                        <br />
                        En cas d’absence du travailleur sans aucun motif et sans
                        présenter aucune pièce prouvant le motif de cette
                        absence, la direction de l’entreprise lui adresse une
                        lettre recommandée dans un délai de deux jours ouvrables
                        consécutifs à l’absence (48 heures). Si le travailleur
                        ne justifie pas son absence dans un délai ne dépassant
                        pas quinze jours ouvrables, il est considéré
                        démissionnaire de sa propre volonté.
                        <br />
                        <br />
                        Le travailleur est tenu d’informer l’employeur de tout
                        changement de son adresse.
                      </p>
                    </Panel>
                  )}
                  {Type === "Congé Maladie" && (
                    <Panel
                      header={
                        <Text strong style={{ color: "#2E79A8" }}>
                          Article 38: Congés de maladie
                        </Text>
                      }
                      key="1"
                    >
                      <p style={{ fontSize: "14px" }}>
                        Le travailleur atteint d’une incapacité de travail par
                        suite de maladie est placé dans la position de congé de
                        maladie à condition qu’il fournisse dans les 48 heures,
                        un certificat médical précisant la nature de la maladie
                        et sa durée probable.
                        <br />
                        <br />
                        L’employeur se réserve le droit de faire effectuer à
                        domicile du travailleur malade tout contrôle médical
                        qu’il juge utile.
                        <br />
                        <br />
                        Sera exclu du bénéfice des dispositions du 1er alinéa du
                        présent article tout travailleur qui :
                        <ul>
                          <li>
                            Interrompt son travail pour des raisons qui
                            résultent soit de son intempérance ou de son
                            inconduite, soit de blessures subies en dehors du
                            travail s’il est reconnu fautif
                          </li>
                          <li>
                            N’observe pas les prescriptions médicales ou
                            s’absente de son domicile sans autorisation du
                            médecin.
                          </li>
                          <li>
                            Étant malade, se livre à un autre travail rémunéré
                            ou non.
                          </li>
                          <li>
                            Prolonge la cessation du travail au-delà du délai
                            prescrit par les médecins. Il est alors considéré
                            comme étant en absence injustifiée et passible, à ce
                            titre, de sanctions disciplinaires.
                          </li>
                        </ul>
                        Le travailleur placé en position de congé de maladie,
                        bénéficie éventuellement, d’un régime complémentaire, en
                        sus du bénéfice des dispositions prévues par le
                        règlement de la Caisse Nationale de Sécurité Sociale. Ce
                        régime complémentaire est prévu par l’article 48 de la
                        présente convention
                      </p>
                    </Panel>
                  )}
                  {SubReason.label === "Obligations militaires " && (
                    <Panel
                      header={
                        <Text strong style={{ color: "#2E79A8" }}>
                          Article 39: Congé pour obligations militaires
                        </Text>
                      }
                      key="1"
                    >
                      <p style={{ fontSize: "14px" }}>
                        Les travailleurs effectuant leur service militaire légal
                        seront considérés dans la position « sous drapeaux » et
                        sans solde, mais ils conservent leurs droits à
                        l’ancienneté et à l’avancement. Ils sont réintégrés de
                        plein droit dans leur catégorie, à leur libération ou,
                        en cas de maladie, dés l’envoi d’un certificat médical.
                        <br />
                        <br />
                        Ils ont priorité pour être affectés aux postes qu’ils
                        occupaient avant leur départ pour l’armée.
                      </p>
                    </Panel>
                  )}
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaveFormAdmin;
