import React from "react";
import { Menu, theme, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faGears,
  faUser,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import { Link, Outlet } from "react-router-dom";

function getItem(label, key, icon, children, disabled, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}

const Parameters = () => {
  const {
    token: { colorBgLayout },
  } = theme.useToken();
  const items = [
    getItem(
      <Link
        className="text-decoration-none"
        to="/administration/parametre/account"
      >
        Mon compte
      </Link>,
      "2",
      <FontAwesomeIcon icon={faUser} />
    ),
    getItem(
      <Link
        className="text-decoration-none"
        to="/administration/parametre/roles"
      >
        Rôles et prévillages
      </Link>,
      "3",
      <FontAwesomeIcon icon={faUserLock} />
    ),
    getItem(
      <Link
        className="text-decoration-none"
        to="/administration/parametre/other"
      >
        Autres
      </Link>,
      "4",
      <FontAwesomeIcon icon={faBarsStaggered} />
    ),
  ];

  return (
    <div className="">
      <div style={{ minHeight: "60vh" }} className="p-4">
        <div className="mb-3">
          <Typography.Title level={4}>Paramètres</Typography.Title>
        </div>
        <div className="row h-100">
          <div className="col-lg-2 col-md-3 h-100">
            <Menu
              defaultSelectedKeys={["2"]}
              className="h-100"
              mode="inline"
              items={items}
              style={{ borderRadius: 13 }}
            />
          </div>
          <div
            className="col-lg-10 col-md-9"
            style={{
              backgroundColor: colorBgLayout,
              borderRadius: "13px",
              padding: "20px",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parameters;
