import { Button, Empty, Space, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import NewBucketModal from "./NewBucketModal";
import SkillBucket from "./SkillBucket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faLaptopCode,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../Utils/axios";

const ShareSkillBucket = () => {
  const [skillsBuckets, setSkillsBuckets] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/grid/skills/bucket");

        setSkillsBuckets(data?.skillsBuckets);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchData();
  }, [refresh]);
  const Navigate = useNavigate();
  return (
    <div className="p-4">
      <div className="mb-3">
        <Typography.Title level={4}> Campagnes</Typography.Title>
      </div>
      <div>
        <Space className="d-flex flex-column align-items-start flex-lg-row justify-content-lg-between">
          <Button
            onClick={() => Navigate("/administration/employees/skills")}
            shape="round"
            icon={<FontAwesomeIcon icon={faCircleChevronLeft} />}
          >
            Grille des compétances
          </Button>
          <Space>
            <NewBucketModal handleRefresh={handleRefresh} />
            <Button
              shape="round"
              type="primary"
              onClick={() => {
                Navigate("/administration/manage/skills");
              }}
              icon={<FontAwesomeIcon icon={faLaptopCode} />}
            >
              Compétences
            </Button>
          </Space>
        </Space>
        <div className="row">
          {skillsBuckets?.map((elem) => {
            return (
              <div className="col-lg-4 col-md-12 col-sm-12 p-2">
                <SkillBucket data={elem} handleRefresh={handleRefresh} />
              </div>
            );
          })}

          {skillsBuckets?.length === 0 && <Empty />}
        </div>
      </div>
    </div>
  );
};

export default ShareSkillBucket;
