import React, { useState } from "react";
import { Button, Descriptions, Modal, Tag, Tooltip, Skeleton } from "antd";
import dayjs from "dayjs";
import axiosInstance from "../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';

const LeaveRequestsDrawer = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const showDrawer = () => {
    setLoading(true);

    axiosInstance.get(`/api/leaves/${record?._id}`).then((res) => {
      setData(res?.data?.Leave);
      setLoading(false);
    });

    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Voir les détails">
        <Button size="small" shape="circle" onClick={showDrawer}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </Tooltip>

      <Modal
        open={open}
        onCancel={onClose}
        onOk={onClose}
        width={1000}
        footer={[
          <Button key="back" type="primary" onClick={onClose}>
            Ok
          </Button>,
        ]}
      >
        <div className="mx-3">
          {!loading ? (
            <Descriptions bordered title="Détails de la demande">
              <Descriptions.Item span={3} label="Date de soumission">
                {dayjs(data?._posted_date).format("DD/MM/YYYY HH:mm")}
              </Descriptions.Item>
              {data?.fullName && (
                <Descriptions.Item span={3} label={"Demandeur"}>
                  {data?.fullName}
                </Descriptions.Item>
              )}

              {data?._typeLeave && (
                <Descriptions.Item span={3} label={"Type de la demande"}>
                  {data?._typeLeave}
                </Descriptions.Item>
              )}

              <Descriptions.Item
                span={3}
                label={
                  data?._typeLeave !== "Autorisation" ? "Date de début" : "Date"
                }
              >
                {dayjs(data?.BeginingDate).format("DD/MM/YYYY")}
              </Descriptions.Item>
              {data?._typeLeave !== "Autorisation" && (
                <Descriptions.Item span={3} label="Date de fin">
                  {dayjs(data?.EndingDate).format("DD/MM/YYYY")}
                </Descriptions.Item>
              )}

              <Descriptions.Item span={3} label="Durée">
                {data?._typeLeave !== "Autorisation"
                  ? data?.DaysNumber +
                  " Jour(s)" +
                  `${data?._is_half_day ? " (" + data?._is_half_day + ")" : ""
                  }`
                  : data?._duration}{" "}
              </Descriptions.Item>
              {data?._motif && (
                <Descriptions.Item
                  span={3}
                  label="Motif"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {data?._motif}
                </Descriptions.Item>
              )}
              <Descriptions.Item
                span={3}
                label="Statut"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {data?.status === "Pending" && (
                  <Tag color="orange">En attente</Tag>
                )}
                {data?.status === "Accepted" && (
                  <Tag color="green">Acceptée</Tag>
                )}
                {data?.status === "Rejected" && <Tag color="red">Rejetée</Tag>}
                {data?.status === "Canceled" && <Tag color="red">Annulée</Tag>}
                {record.status === "Validating" && (
                  <Tag color={"green"}>En attente d'approbation</Tag>
                )}
                {record.status === "Unjustified" && (
                  <Tag color={"red"}>Non encore justifiée</Tag>
                )}
                {record.status === "Failed" && <Tag color={"red"}>Échue</Tag>}
              </Descriptions.Item>
              {data?.OptionalFiles && (
                <Descriptions.Item span={3} label="Justification">
                  <a
                    href={data?.OptionalFiles ?? "#"}
                    download={
                      "Justification-Absence-" +
                      data?.fullName.replace(" ", "-") +
                      "-" +
                      dayjs(data?.BeginingDate).format("DD-MM-YYYY")
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Tag
                      color={"blue"}
                      style={{ fontSize: "12px", padding: "5px 10px" }}
                    >
                      <FontAwesomeIcon
                        icon={faFileArrowDown}
                        style={{ marginRight: "5px" }}
                      />
                      {"Justification-Absence-" +
                        data?.fullName.replace(" ", "-") +
                        "-" +
                        dayjs(data?.BeginingDate).format("DD-MM-YYYY")}
                    </Tag>
                  </a>
                </Descriptions.Item>
              )}
              {data?.status === "Rejected" && (
                <Descriptions.Item
                  span={3}
                  label="Motif de rejet"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {data?.rejected_reason ?? "N/A"}
                </Descriptions.Item>
              )}
              <Descriptions.Item span={3} label="Traité par">
                {data?._answered_by ?? <Tag color="orange">En attente</Tag>}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <div className="row">
              <Descriptions bordered title="Détails de la demande">
                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

LeaveRequestsDrawer.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired
};
export default LeaveRequestsDrawer;
