import { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Avatar,
  Dropdown,
  Menu,
  Typography,
  Tooltip,
  Badge,
  Layout,
  message,
  theme as antdTheme,
  Select,
  Input,
  Modal,
  Form,
  Space,
  DatePicker,
} from "antd";

import iconDark from "../../Assets/LOGO-NEOLINK-blanc.svg";

import logoDark from "../../Assets/brand_large_dark.png";

import closeIcon from "../../Assets/close.svg";
import eyeIcon from "../../Assets/eye.svg";
import eyeHiddenIcon from "../../Assets/eyehidden.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import axios from "../../Utils/axios";
import dayjs from "dayjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faUser,
  faHouse,
  faBell,
  faCalendarXmark,
  faLaptopFile,
  faPeopleLine,
  faFileLines,
  faToolbox,
  faPeopleGroup,
  faQuestionCircle,
  faCalendarDays,
  faArrowsRotate,
  faKey,
  faHouseLaptop,
  faBoxArchive,
  faClock,
  faFileSignature,
  faIdBadge,
  faFolder,
  faComputer,
  faCode,
} from "@fortawesome/free-solid-svg-icons";

import LocalStorageService from "../../Utils/localStorageService";
import GlobalContext from "../../Context/GlobalContext";

import pathLocations from "../../Constants/clientPathLocations";

import data from "../../../package.json";
import { LinkOutlined, PullRequestOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const LayoutClient = ({ theme }) => {
  const location = useLocation();
  const {
    profile,
    setProfile,
    handleRoleSwap,
    role,
    apiCollab,
    contextHolderCollab,
    stats,
    setStats,
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [isTaskedToDo, setIsTaskedTodo] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("1");

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  const handleRequestProd = async () => {
    try {
      form
        .validateFields()
        .then(async (values) => {
          modal.confirm({
            title: "Confirmation de Conformité du Déploiement",
            width: 500,
            content: (
              <p>
                Je certifie par la présente que toutes les conditions requises
                pour le déploiement en production sont remplies:
                <br />
                <br />
                <ul>
                  <li>L'intégration de Sentry</li>
                  <li>La mise en place d'une procédure de versionnage</li>
                  <li>La mise à jour du changelog sur le tag Git.</li>
                </ul>
              </p>
            ),
            okText: "Confirmer",
            onOk: async () => {
              try {
                const { data } = await axios.post(
                  "/api/platform/request/prod",
                  values
                );

                message.success(data.message);

                form.resetFields();

                setOpen(false);
              } catch (error) {
                message.error(
                  error?.response?.data?.message ||
                  "Erreur lors de la soumission de la demande"
                );
              }
            },
          });
        })
        .catch((error) => {
          message.error("Veuillez vérifier tous les champs");
        });
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    async function getStats() {
      const { data } = await axios.get("/api/platform/employee");
      setStats(data);
      return data;
    }

    async function verifyTodo() {
      const { data } = await axios.get("api/todo/me");
      if (data.todos.length > 0) {
        setIsTaskedTodo(true);
        apiCollab["info"]({
          message: "Vous avez des tâches à faire",
          description: `Veuillez noter que vous avez ${data.todos.length
            } tâche(s) à faire en tant que ${profile?.sub_role === "SUPER_ADMIN"
              ? "resources humaines"
              : profile?.sub_role === "SYS_ADMIN"
                ? "sys admin"
                : "comptabilité"
            }`,
        });
      }
    }
    if (profile) {
      verifyTodo();
      getStats();
    }
    // eslint-disable-next-line
  }, [profile]);

  useEffect(() => {
    const temp = pathLocations.find((elem) => elem.path === location.pathname);

    setSelectedLocation(temp?.key);
  }, [location]);

  const items = [
    getItem(
      <Link to="/" style={{ textDecoration: "none" }}>
        Accueil
      </Link>,
      "1",
      <FontAwesomeIcon icon={faHouse} />
    ),
    getItem("Mon Profil", "Prof", <FontAwesomeIcon icon={faUser} />, [
      getItem(
        <Link to="/profile" style={{ textDecoration: "none" }}>
          Mes informations
        </Link>,
        "Prof-1",
        <FontAwesomeIcon icon={faIdBadge} />
      ),
      getItem(
        <Link to="/docs/me" style={{ textDecoration: "none" }}>
          Mes documents
        </Link>,
        "Prof-2",
        <FontAwesomeIcon icon={faFolder} />
      ),
      getItem(
        <Link to="/team/me" style={{ textDecoration: "none" }}>
          Mon équipe
        </Link>,
        "Prof-3",
        <FontAwesomeIcon icon={faPeopleGroup} />
      ),
      getItem(
        <Link to="/access/me" style={{ textDecoration: "none" }}>
          Mes accès
        </Link>,
        "Prof-4",
        <FontAwesomeIcon icon={faKey} />
      ),
      getItem(
        <Link to="/equipments/me" style={{ textDecoration: "none" }}>
          Mes équipements
        </Link>,
        "Prof-5",
        <FontAwesomeIcon icon={faComputer} />
      ),
      getItem(
        <Link to="/skills" style={{ textDecoration: "none" }}>
          Grille des compétances
        </Link>,
        "Prof-99",
        <FontAwesomeIcon icon={faCode} />
      ),
      getItem(
        <Link to="/balance/archive" style={{ textDecoration: "none" }}>
          Mon solde de congé
        </Link>,
        "34",
        <FontAwesomeIcon icon={faCalendarXmark} />
      ),
    ]),
    getItem(
      <a style={{ textDecoration: "none" }}>Mes demandes</a>,
      "25",
      <FontAwesomeIcon icon={faFileLines} />,
      [
        getItem("Historique", "255", <FontAwesomeIcon icon={faBoxArchive} />, [
          getItem(
            <Link to="/manage/self" style={{ textDecoration: "none" }}>
              Congés/Autorisations
            </Link>,
            "2",
            <FontAwesomeIcon icon={faFileLines} />
          ),

          getItem(
            <Link to="/manage/self/telework" style={{ textDecoration: "none" }}>
              Télétravail
            </Link>,
            "988",
            <FontAwesomeIcon icon={faHouseLaptop} />
          ),

          getItem(
            <Link to="/self/equipments" style={{ textDecoration: "none" }}>
              Equipements
            </Link>,
            "512",
            <FontAwesomeIcon icon={faLaptopFile} />
          ),

          getItem(
            <Link to="/self/access" style={{ textDecoration: "none" }}>
              Accès
            </Link>,
            "977",
            <FontAwesomeIcon icon={faKey} />
          ),
        ]),
        getItem(
          <Link to="/pending/requests" style={{ textDecoration: "none" }}>
            En attente
          </Link>,
          "256",
          <FontAwesomeIcon icon={faClock} />
        ),
      ]
    ),
    getItem(
      <Link to="/absences" style={{ textDecoration: "none" }}>
        Mes absences
      </Link>,
      "33",
      <FontAwesomeIcon icon={faFileSignature} />
    ),
    stats?.isTeamLead &&
    getItem("Mes subordonnés", "5", <FontAwesomeIcon icon={faPeopleLine} />, [
      getItem(
        <Link to="/manage/subordinates" style={{ textDecoration: "none" }}>
          Demandes en attentes
        </Link>,
        "556",
        <FontAwesomeIcon icon={faClock} />
      ),
      getItem(
        <Link
          to="/manage/subordinates/archive"
          style={{ textDecoration: "none" }}
        >
          Historique
        </Link>,
        "557",
        <FontAwesomeIcon icon={faBoxArchive} />
      ),
    ]),
    getItem(
      <Link to="/team/calendar" style={{ textDecoration: "none" }}>
        Calendrier de l'équipe
      </Link>,
      "13",
      <FontAwesomeIcon icon={faCalendarDays} />
    ),
    getItem(
      <Link to="/trombinoscope" style={{ textDecoration: "none" }}>
        Trombinoscope
      </Link>,
      "7",
      <FontAwesomeIcon icon={faPeopleGroup} />
    ),
    getItem(
      <Link to="/tools" style={{ textDecoration: "none" }}>
        Boîte à outils
      </Link>,
      "6",
      <FontAwesomeIcon icon={faToolbox} />
    ),

    getItem(
      <Link to="/faq" style={{ textDecoration: "none" }}>
        FAQ
      </Link>,
      "8",
      <FontAwesomeIcon icon={faQuestionCircle} />
    ),
    profile?.sub_role &&
    profile?.sub_role !== "EMPLOYEE" &&
    profile?.sub_role !== "TEAM_LEAD" &&
    getItem(
      <button
        style={{
          cursor: "pointer",
          fontWeight: "600",
          color: "#2B96FF",
          background: "none",
          border: "none",
          padding: 0,
        }}
        onClick={() => handleRoleSwap(role)}
      >
        Mode{" "}
        {profile?.sub_role === "SUPER_ADMIN"
          ? "Resources Humaines"
          : profile?.sub_role === "SYS_ADMIN"
            ? "Sys Admin"
            : "Comptabilité"}
      </button>,
      "Random Key",
      <Badge dot={isTaskedToDo}>
        <FontAwesomeIcon icon={faArrowsRotate} style={{ color: "#2B96FF" }} />{" "}
      </Badge>
    ),
  ];

  useEffect(() => {
    async function GetNotifications(autoNotif) {
      const { data } = await axios.get("/api/notif/employee");

      setNotifications(data.notifs);
      setAutoNotif(false);
      LocalStorageService().setNotif(false);
      return data;
    }

    GetNotifications(autoNotif);

    async function fetchProfilePhotoAndName() {
      const { data } = await axios.get("/api/employees/me/mini");
      setProfile(data);
      setLoading(false);
      return data;
    }

    fetchProfilePhotoAndName();
    // eslint-disable-next-line
  }, []);

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  // eslint-disable-next-line
  const showDrawerVisible = () => {
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const Navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);
      setTimeout(() => {
        localStorage.removeItem("access_token");
        Navigate("/login");
      }, [1000]);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleHome = () => {
    Navigate("/");
  };

  const [autoNotif, setAutoNotif] = useState(
    JSON.parse(LocalStorageService().getNotif() || true)
  );

  const [Notifications, setNotifications] = useState([]);

  const handleDisableNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/read/" + id, {
        status: status,
      });
      if (data.status === "success") {
        const new_array = Notifications.map((notif) => {
          if (notif._id === id) {
            notif.read = status;
            return notif;
          } else {
            return notif;
          }
        });
        setNotifications(new_array);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleDeleteNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/deleted/" + id, {
        status: status,
      });
      if (data.status === "success") {
        let new_array = [];
        Notifications.forEach((notif) => {
          if (notif._id !== id) new_array.push(notif);
        });
        setNotifications(new_array);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <button
              onClick={handleLogin}
              style={{
                cursor: "pointer",
                background: "none",
                border: "none",
                width: "100%",
                textAlign: "left",
              }}
              role="menuitem"
              tabIndex={0}
            >
              <FontAwesomeIcon
                style={{ marginRight: "3px" }}
                icon={faArrowRightFromBracket}
                aria-hidden="true"
              />
              <span>Se deconnecter</span>
            </button>
          ),
        },
      ]}
    />
  );
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, colorBgLayout, colorBorderSecondary },
  } = antdTheme.useToken();

  return loading ? (
    <div className="loading">
      <div class="dot-spinner">
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
      </div>
    </div>
  ) : (
    <Layout className="vh-100 vw-100 overflow-hidden">
      <Header
        style={{
          padding: 0,
          backgroundColor: colorBgContainer,
          borderBottom: "1px solid " + colorBorderSecondary,
        }}
        className="d-flex justify-content-between align-items-center"
      >
        <div>
          <img
            src={theme === "dark" ? iconDark : logoDark}
            style={{
              height: "45px",
              cursor: "pointer",
              transition: "width 0.2s ease",
            }}
            alt="Neopolis"
            className="px-2"
            onClick={handleHome}
          />
          <span className="px-5 mx-5">
            <span className="d-none d-lg-inline-block ">Nous sommes le </span>

            <span
              className="d-none d-lg-inline-block d-md-inline-block"
              style={{
                fontSisze: "16px",
                fontWeight: "600",
                marginLeft: "3px",
              }}
            >
              {" "}
              {" " + dayjs().format("dddd") + " "}
              {dayjs().format("DD MMMM YYYY")}{" "}
            </span>
          </span>
        </div>

        <div className="mx-3 d-flex align-items-center ">
          <button
            style={{ paddingRight: "5px", cursor: "pointer" }}
            onClick={showDrawerVisible}
          >
            <Badge dot={Notifications.length > 0}>
              <Avatar
                shape="square"
                size={"large"}
                icon={
                  <FontAwesomeIcon
                    icon={faBell}
                    style={{ fontSize: "22px", marginTop: "2px" }}
                  />
                }
                style={{
                  cursor: "pointer",
                }}
              />
            </Badge>
          </button>

          <div
            className="d-flex flex-column align-items-end mx-2"
            style={{ height: "64px", marginTop: "-16px" }}
          >
            <p
              style={{
                height: "20px",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              {profile?.fullName}{" "}
              {profile?.sub_role &&
                profile?.sub_role !== "EMPLOYEE" &&
                profile?.sub_role !== "TEAM_LEAD" && <>#{profile?.matricule}</>}
            </p>
            <p style={{ height: "28px", fontWeight: "400", fontSize: "12px" }}>
              {profile?.sub_role &&
                profile?.sub_role !== "EMPLOYEE" &&
                profile?.sub_role !== "TEAM_LEAD" ? (
                <div style={{ cursor: "pointer" }}>
                  En tant que collaborateur
                </div>
              ) : (
                <>Matricule: #{profile?.matricule}</>
              )}
            </p>
          </div>

          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            {profile?.imgProfile ? (
              <Avatar
                size={"large"}
                src={profile?.imgProfile}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <Avatar
                size={"large"}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px",
                }}
              >
                {profile?.fullName[0] ?? "N"}
              </Avatar>
            )}
          </Dropdown>
        </div>
      </Header>

      <Layout style={{ backgroundColor: colorBgContainer }}>
        <Sider
          className="responsive-sider"
          style={{ backgroundColor: colorBgContainer }}
          breakpoint="lg"
          collapsedWidth="0"
          collapsible
          collapsed={collapsed}
          onCollapse={(collapsed) => setCollapsed(collapsed)}
          width={270}
        >
          <div style={{ height: "calc(100vh - 135px)", marginTop: 15 }}>
            <Menu
              selectedKeys={[selectedLocation]}
              className="h-100"
              mode="inline"
              style={{
                overflowY: "scroll",
                backgroundColor: colorBgContainer,
                borderRight: "1px solid " + colorBorderSecondary,
              }}
              items={items}
            />
          </div>
          <div>
            <Menu
              mode="inline"
              items={[
                getItem(
                  <button
                    onClick={() => setOpen(true)}
                    style={{
                      background: 'none',
                      border: 'none',
                      width: '100%',
                      textAlign: 'left',
                      cursor: 'pointer',
                      padding: '5px 0'
                    }}
                  >
                    Déploiement Prod
                  </button>,
                  1,
                  <PullRequestOutlined />
                ),
              ]}
            />
          </div>
          <div className="fw-bold" style={{ textAlign: "center" }}>
            <Typography.Text>
              &copy; {new Date().getFullYear()} NeoLink V{data.version}
            </Typography.Text>
          </div>
        </Sider>
        <Drawer
          title="Notifications"
          placement="right"
          onClose={onCloseDrawer}
          visible={visibleDrawer}
        >
          {Notifications.map((notif) => {
            return notif.title !== "" && !notif.read ? (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "5px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text style={{ fontSize: "12px", fontWeight: "600" }}>
                      {notif.title}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {dayjs(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      title
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />
                    <Tooltip
                      title="Cliquez pour désactiver"
                      placement="topRight"
                    >
                      <img
                        onClick={() => {
                          handleDisableNotif(notif._id, true);
                        }}
                        src={eyeHiddenIcon}
                        alt="hidden_icon"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "7px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.title}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {dayjs(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />

                    <img src={eyeIcon} alt="hidden_icon" />
                  </div>
                </div>
              </div>
            );
          })}
        </Drawer>
        <Modal
          title="Nouvelle demande de déploiement en prod"
          centered
          open={open}
          okText="Soumettre"
          onOk={handleRequestProd}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {contextHolder}
          <Form form={form} layout="vertical" className="row">
            <div className="col-lg-6">
              <Form.Item
                label="Type de la demande"
                name="requestType"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Demande de déploiement PROD">
                    Demande de déploiement PROD
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Typography.Link
                href="https://wiki.neopolis-dev.com/fr/livraison"
                style={{ fontWeight: 700 }}
                target="_blank"
              >
                <LinkOutlined /> Voir le détails de la procédure de déploiement
              </Typography.Link>
            </div>
            <div className="col-lg-8">
              <Form.Item
                name="gitUrl"
                label="Adresse GIT"
                rules={[
                  { required: true },
                  { type: "url" },
                  { type: "string", min: 6 },
                  {
                    pattern: "^https:\\/\\/git\\.interface-crm\\.com\\/.*$",
                    message: "Veuillez entrer une adresse git valide",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                name="instanceName"
                label="Nom de l'instance"
                rules={[
                  { whitespace: true },
                  { required: true },
                  { type: "string", min: 2 },
                ]}
              >
                <Input placeholder="Nom de client concerné" />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Space>
                <Form.Item
                  label="Numéro de Version"
                  name="versionNumber"
                  className="w-100"
                  tooltip={
                    <Typography.Link
                      target="_blank"
                      href="https://docs.google.com/spreadsheets/d/1MvSQadPBeqXaMCSmmftCvmLKHC8_FL29c4KvGSlOErY/edit?usp=sharing"
                    >
                      <LinkOutlined /> Voir le document de versionning
                    </Typography.Link>
                  }
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input className="w-100" />
                </Form.Item>
                <div style={{ fontSize: "12px" }}>
                  Copier coller a partir du{" "}
                  <Typography.Link
                    style={{ fontSize: "12px" }}
                    target="_blank"
                    href="https://docs.google.com/spreadsheets/d/1MvSQadPBeqXaMCSmmftCvmLKHC8_FL29c4KvGSlOErY/edit?usp=sharing"
                  >
                    <LinkOutlined /> Document de versionning
                  </Typography.Link>
                </div>
              </Space>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="Tag Git"
                name="gitTag"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="Date de traitement souhaitée"
                name="requestedDate"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  showTime={{
                    format: "HH:mm",
                  }}
                  className="w-100"
                  format={"YYYY-MM-DD HH:mm"}
                  disabledDate={(current) =>
                    current && current < dayjs().endOf("day").subtract(1, "d")
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="Nature du déploiement"
                name="deployNature"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Nouvelles Fonctionnalités">
                    Nouvelles Fonctionnalités
                  </Select.Option>
                  <Select.Option value="Hotfix">Hotfix</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              label="Notes de Version"
              name="versionNotes"
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>

            <Form.Item label="Liens Utiles" name="usefullLink">
              <Space direction="vertical" className="w-100 ">
                <Input.TextArea rows={5} className="w-100" />
              </Space>
            </Form.Item>
            <Form.Item
              label="Instructions (Pour le cas de Trinita Prod)"
              name="instructions"
            >
              <Input.TextArea rows={5} className="w-100" />
            </Form.Item>
          </Form>
        </Modal>
        <Content
          className="rounded-3"
          style={{
            border: "1px solid " + colorBorderSecondary,
            display: "block",
            margin: "24px 16px 24px",
            overflowY: "scroll",
            backgroundColor: colorBgLayout,
          }}
        >
          {contextHolderCollab}
          <div>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutClient;
