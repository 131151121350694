/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
  Dropdown,
  Input,
  Modal,
  Form,
  Checkbox,
  notification,
} from "antd";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";
import AffectToModal from "../AffectToModal";
import EquipmentHistoryDrawer from "../EquipmentHistoryDrawer";
import EditEquipmentModal from "../EditEquipmentModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faFileExport,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";

const EquipmentStock = ({ access }) => {
  const { equipments, setEquipments, rolePrevilleges } =
    useContext(GlobalContext);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [colonnes, setColonnes] = useState([
    "N°Serie",
    "Designation",
    "Marque",
    "Affectée à",
    "Date d'acquisition",
    "N°Facture",
  ]);
  const [tempEquipments, setTempEquipemets] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <dialog
        open
        style={{
          padding: 8,
          border: "none",
          backgroundColor: "white",
          boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
          position: "absolute",
          zIndex: 1000,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
          >
            Réinitialiser
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </dialog>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "N°Serie",
      dataIndex: "_ref",
      key: "Catégorie",
      ...getColumnSearchProps("_ref", "réference"),
    },
    {
      title: "Designation",
      dataIndex: "_categorie",
      key: "Référence",
      ...getColumnSearchProps("_categorie", "catégorie"),
    },
    {
      title: "Marque",
      dataIndex: "_brand",
      key: "Marque",
      ...getColumnSearchProps("_brand", "marque"),
    },
    access
      ? {
        title: "Affectée à",
        filters: [
          {
            text: <Tag color="blue">Affecté</Tag>,
            value: "AFFECTED_TO",
          },
          {
            text: <Tag color="green">Disponible</Tag>,
            value: "AVAILABLE",
          },
          {
            text: <Tag color="red">En pane</Tag>,
            value: "BROKEN",
          },
        ],
        onFilter: (value, record) => record._status === value,
        render: (record) => (
          <>
            {record._status === "AFFECTED_TO" ? (
              <Tooltip
                title={
                  "Affecté à " +
                  employees?.find(
                    (elem) => elem.matricule === record._current_holder
                  )?.fullName
                }
              >
                <Tag color="blue">
                  {" "}
                  {
                    employees?.find(
                      (elem) => elem.matricule === record._current_holder
                    )?.fullName
                  }
                </Tag>
              </Tooltip>
            ) : (
              <Typography.Text>
                {record._status === "AVAILABLE" ? (
                  <Tag color="green">Disponible</Tag>
                ) : (
                  <Tag color="red">En pane</Tag>
                )}
              </Typography.Text>
            )}
          </>
        ),
      }
      : { width: 0, className: "d-none" },
    {
      title: "Date d'acquisition",
      key: "date",
      sorter: (a, b) =>
        dayjs(a?._time_line[a._time_line.length - 1]?._start_date) -
        dayjs(b?._time_line[b._time_line.length - 1]?._start_date),
      render: (record) => (
        <Typography.Text>
          {dayjs(
            record._time_line[record._time_line.length - 1]._start_date
          ).format("DD/MM/YYYY")}
        </Typography.Text>
      ),
    },
    {
      title: "N°Facture",
      dataIndex: "_num_bill",
      ...getColumnSearchProps("_num_bill", "n° facture"),
    },
    {
      title: "Actions",
      render: (record) => (
        <Space>
          {rolePrevilleges?.previllegesList?.find(
            (elem) => elem?.code === "EDIT_IT_EQUIPMENT"
          ) && <EditEquipmentModal record={record} />}
          {rolePrevilleges?.previllegesList?.find(
            (elem) => elem?.code === "AFFECT_IT_EQUIPMENT"
          ) && <AffectToModal record={record} employees={employees} />}
          {access && (
            <Dropdown
              menu={{
                items: [
                  rolePrevilleges?.previllegesList?.find(
                    (elem) => elem?.code === "CONSULT_HISTORIQUE_IT_EQUIPMENT"
                  ) && {
                    label: (
                      <EquipmentHistoryDrawer timeLine={record._time_line} />
                    ),
                    key: "2",
                  },
                  rolePrevilleges?.previllegesList?.find(
                    (elem) => elem?.code === "DISAFFECT_IT_EQUIPMENT"
                  ) && {
                    label: "Désaffecter",
                    key: "3",
                    danger: true,
                    onClick: async () => {
                      try {
                        const { data } = await axios.patch(
                          "/api/equipments/desaffect/" + record._id
                        );
                        const tmp = equipments.map((elem) => {
                          if (elem._id === record._id) {
                            elem._status = "AVAILABLE";
                            elem._current_holder = "N/A";
                          }
                          return elem;
                        });
                        setEquipments(tmp);
                        message.success(data.message);
                      } catch (error) {
                        message.error(error.response.data.message);
                      }
                    },
                  },
                ],
              }}
            >
              <Button size="small" shape="circle">
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </Button>
            </Dropdown>
          )}
        </Space>
      ),
    },
  ];
  const options = [
    {
      label: (
        <Tag
          color={colonnes.includes("N°Serie") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          N°Serie
        </Tag>
      ),
      value: "N°Serie",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Designation") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Designation
        </Tag>
      ),
      value: "Designation",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Marque") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Marque
        </Tag>
      ),
      value: "Marque",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Affectée à") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Affectée à
        </Tag>
      ),
      value: "Affectée à",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Date d'acquisition") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Date d'acquisition
        </Tag>
      ),
      value: "Date d'acquisition",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("N°Facture") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          N°Facture
        </Tag>
      ),
      value: "N°Facture",
    },
  ];
  useEffect(() => {
    async function fetchData() {
      axios
        .get("/api/employees/")
        .then(async (res) => {
          setEmployees(res.data.employees);
          const { data } = await axios.get(
            `/api/equipments/${!access ? "?status=AVAILABLE" : ""}`
          );

          setEquipments(data.equipments);
          setLoading(false);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
    }

    fetchData();
  }, []);
  const Navigate = useNavigate();

  const handleNavigate = () => {
    Navigate("/administration/equipments/managment");
  };

  const onChangeColonnes = (checkedValues) => {
    setColonnes(checkedValues);
  };

  const handleExportToXLSX = () => {
    if (colonnes.length > 0) {
      let tableData = tempEquipments.length > 0 ? tempEquipments : equipments;
      const data = tableData?.map((record) => {
        return colonnes.reduce((acc, key) => {
          switch (key) {
            case "N°Serie":
              acc[key] = record._ref;
              break;
            case "Designation":
              acc[key] = record._categorie;
              break;
            case "Marque":
              acc[key] = record._brand;
              break;
            case "Affectée à":
              acc[key] = employees?.find(
                (elem) => elem.matricule === record._current_holder
              )?.fullName;
              break;
            case "Date d'acquisition":
              acc[key] = dayjs(
                record._time_line[record._time_line.length - 1]._start_date
              ).format("DD/MM/YYYY");
              break;
            case "N°Facture":
              acc[key] = record._num_bill;
              break;

            default:
              acc[key] = ""; // Handle default case or provide a default value
              break;
          }
          return acc;
        }, {});
      });

      const worksheet = XLSX.utils.json_to_sheet(data);

      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(
        workbook,
        `Liste des équipements ${dayjs().format("DD/MM/YYYY HH-mm")}.xlsx`
      );
    } else {
      notification.warning({
        message: "",
        description: "Veuillez choisir au moins une colonne",
      });
    }
  };

  return (
    <div className="p-4">
      <div className="mb-3 d-flex justify-content-between">
        <Typography.Title level={4}>Liste des équipements</Typography.Title>
        <Space>
          <Button
            type="primary"
            shape="round"
            onClick={() => setOpen(true)}
            //   disabled={!(employees.length > 0)}
            icon={<FontAwesomeIcon icon={faFileExport} />}
          >
            Exporter
          </Button>
          {rolePrevilleges?.previllegesList?.find(
            (elem) => elem?.code === "EDIT_IT_EQUIPMENT"
          ) && (
              <Button
                shape="round"
                onClick={handleNavigate}
                type="primary"
                icon={<FontAwesomeIcon icon={faPlus} />}
              >
                Nouveaux équipements
              </Button>
            )}
        </Space>
      </div>

      <Table
        dataSource={equipments}
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={columns}
        onChange={(pagination, filters, sorter, extra) => {
          setTempEquipemets(extra.currentDataSource);
        }}
      />
      <Modal
        title="Choisir les colonnes à exporter"
        open={open}
        centered
        okText="Exporter"
        onOk={handleExportToXLSX}
        onCancel={() => setOpen(false)}
      >
        <Form.Item>
          <Checkbox.Group
            className="row justify-content-evenly"
            value={colonnes}
            onChange={(e) => onChangeColonnes(e)}
          >
            {options.map((option, index) => (
              <div className="col-lg-3 col-6 my-1">
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Modal>
    </div>
  );
};

export default EquipmentStock;
