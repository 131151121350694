import { useState, useEffect } from "react";
import axios from "../../Utils/axios";
import {
  Form,
  Input,
  Button,
  Typography,
  Spin,
  theme as antdTheme,
} from "antd";

import LogoDark from "../../Assets/LOGO-NEOLINK-blanc.svg";
import Logo from "../../Assets/brand_large_dark.png";
import NeoLogo from "../../Assets/brand_large_light.svg";
import NeoLogoDark from "../../Assets/brand_large_dark.svg";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../Utils/localStorageService";
import data from "../../../package.json";

import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const { Text, Link } = Typography;

const Login = ({ _setToken, _setRole, theme, maintainance }) => {
  const {
    token: { colorBgContainer, colorBorder, colorBgLayout },
  } = antdTheme.useToken();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");

  const [role, setRole] = useState("");
  const [form] = Form.useForm();

  const Navigate = useNavigate();

  const handleLogin = async (formValues) => {
    setLoading(true);
    try {
      const { data } = await axios.patch("/api/auth/login", formValues);
      if (data.status) {
        setToken(data.token);
        setRole(jwtDecode(data.token).Role);
        _setToken(data.token);
        _setRole(jwtDecode(data.token).Role);
      } else {
        setMessage("Le compte est désactivé.");
        setLoading(false);
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.clear();
    localStorage.removeItem("access_token");
  }, []);

  useEffect(() => {
    if (token) {
      LocalStorageService().setToken(token);
      if (role === "EMPLOYEE" || role === "TEAM_LEAD") {
        if (!maintainance) {
          Navigate("/maintenance");
        } else {
          Navigate("/");
        }
      } else {
        Navigate("/administration/");
        LocalStorageService().setNotif(true);
      }
    }
    // eslint-disable-next-line
  }, [role, token]);

  return (
    <div
      className="vh-100 vw-100"
      style={{ overflow: "hidden", backgroundColor: colorBgLayout }}
    >
      <div className="d-flex vw-100 justify-content-center align-items-center vh-100 px-3 m-0 ">
        <div
          className="col-lg-4 col-md-6 col-12 p-4 shadow-lg rounded-3"
          style={{
            border: `1px solid ${colorBorder}`,
            backgroundColor: colorBgContainer,
          }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div className="d-flex justify-content-center ">
              <img
                src={theme === "dark" ? LogoDark : Logo}
                style={{
                  height: "64px",
                  cursor: "pointer",
                  transition: "width 0.2s ease",
                }}
                alt="Neopolis"
              />
            </div>
            <div>
              <Typography.Paragraph className="pt-3">
                Veuillez vous authentifier pour accéder à votre compte.
              </Typography.Paragraph>
              <Form
                disabled={loading}
                form={form}
                layout="vertical"
                size="large"
                onFinish={handleLogin}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "S'il vous plaît entrer votre email!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        email: e.target.value.replace(/ /g, ""),
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Mot de passe"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "S'il vous plaît entrer votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password
                    onChange={(e) => {
                      form.setFieldsValue({
                        password: e.target.value.replace(/ /g, ""),
                      });
                    }}
                  />
                </Form.Item>
                {message && <Text type="danger">{message}</Text>}
                <Form.Item>
                  <div className="d-flex justify-content-between align-items-center flex-column flex-xl-row">
                    <Button
                      type="primary"
                      className="my-1"
                      htmlType="submit"
                      shape="round"
                    >
                      Se connecter
                    </Button>
                    {loading && (
                      <Spin
                        style={{ marginLeft: "10px" }}
                        size="large"
                        indicator={antIcon}
                      />
                    )}
                    <Link
                      className="my-1 fw-bold"
                      onClick={() => {
                        Navigate("/forgot-password");
                      }}
                    >
                      Mot de passe oublié ?
                    </Link>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <Text type="primary" className="mt-2" strong>
                  &copy; {new Date().getFullYear()} NeoLink V{data.version}
                </Text>
                <img
                  src={theme === "dark" ? NeoLogoDark : NeoLogo}
                  style={{ height: "35px" }}
                  alt="Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  _setToken: PropTypes.func.isRequired,
  _setRole: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  maintainance: PropTypes.bool
};

Login.defaultProps = {
  maintainance: false
};

export default Login;
