import React, { useState } from "react";
import {
  Button,
  Descriptions,
  Space,
  Tag,
  Modal,
  Skeleton,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown, faEye } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../Utils/axios";
import dayjs from "dayjs";
import PropTypes from "prop-types";
const EquipmentRequestsDrawer = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const showDrawer = () => {
    setLoading(true);
    axiosInstance.get(`/api/request/byId/${record?._id}`).then((res) => {
      setData(res?.data?.request);
      setLoading(false);
    });
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Voir les détails">
        <Button size="small" shape="circle" onClick={showDrawer}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </Tooltip>

      <Modal
        open={open}
        onCancel={onClose}
        onOk={onClose}
        width={1000}
        footer={[
          <Button key="back" type="primary" onClick={onClose}>
            Ok
          </Button>,
        ]}
      >
        <div className="mx-3">
          {!loading ? (
            <Descriptions bordered title="Détails de la demande">
              {data?.sender && (
                <Descriptions.Item span={3} label={"Demandeur"}>
                  {data?.sender}
                </Descriptions.Item>
              )}

              {data?.request && (
                <Descriptions.Item span={3} label={"Équipement demandé"}>
                  {data?.request}
                </Descriptions.Item>
              )}
              {data?.send_time_date && (
                <Descriptions.Item span={3} label={"Date de soumission"}>
                  {dayjs(data?.send_time_date).format("DD/MM/YYYY")}
                </Descriptions.Item>
              )}

              <Descriptions.Item span={3} label="Documents">
                <Space direction={"vertical"}>
                  {data?._docs?.length === 0 && "N/A"}
                  {data?._docs?.map((elem) => {
                    return (
                      <a
                        href={`${process.env.REACT_APP_API_URL}/api/files/${elem}`}
                        download={elem.substring(29)}
                        target="_blank"
                        rel="noreferrer" // Add this line
                      >
                        <Tag
                          color={"blue"}
                          style={{ fontSize: "12px", padding: "5px 10px" }}
                        >
                          <FontAwesomeIcon
                            icon={faFileArrowDown}
                            style={{ marginRight: "5px" }}
                          />
                          {elem?.substring(29)}
                        </Tag>
                      </a>
                    );
                  })}
                </Space>
              </Descriptions.Item>

              <Descriptions.Item
                span={4}
                label="Motif"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {data?.motif}
              </Descriptions.Item>
              {data?.json && JSON.parse(data?.json)._description && (
                <Descriptions.Item
                  span={4}
                  label="Description"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {JSON.parse(data?.json)._description}
                </Descriptions.Item>
              )}
              <Descriptions.Item
                span={4}
                label="Statut"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {data?.status === "PENDING" && (
                  <Tag color="orange">En attente</Tag>
                )}
                {data?.status === "APPROVED" && (
                  <Tag color="green">Acceptée</Tag>
                )}
                {data?.status === "REJECTED" && <Tag color="red">Rejetée</Tag>}
                {data?.status === "CANCELLED" && <Tag color="red">Annulée</Tag>}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <div className="row">
              <Descriptions bordered title="Détails de la demande">
                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>

                <Descriptions.Item
                  span={3}
                  label={
                    <Skeleton.Input active className="w-100" size="large" />
                  }
                >
                  <Skeleton.Input active className="w-100" size="large" />
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

EquipmentRequestsDrawer.propTypes = {
  record: PropTypes.shape({
    _id: PropTypes.string,
    sender: PropTypes.string,
    request: PropTypes.string,
    send_time_date: PropTypes.string,
    _docs: PropTypes.arrayOf(PropTypes.string),
    motif: PropTypes.string,
    json: PropTypes.string,
    status: PropTypes.string,
  }),
};

EquipmentRequestsDrawer.defaultProps = {
  record: {},
};
export default EquipmentRequestsDrawer;
