import {
    Button,
    Popconfirm,
    Space,
    Table,
    Tag,
    Typography,
    message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import dayjs from "dayjs";
import GlobalContext from "../../../Context/GlobalContext";

const PlannedTasks = () => {
    const { rolePrevilleges } = useContext(GlobalContext);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            title: "Tâche",
            dataIndex: "task",
        },
        {
            title: "Date de déclenchement",
            dataIndex: "triggerDate",
            render: (text) => <>{dayjs(text).format("DD/MM/YYYY HH:mm")}</>,
        },
        {
            title: "Statut",
            dataIndex: "status",
            render: (text) => (
                <>
                    {text === "pending" ? (
                        <Tag color="warning">En Attente</Tag>
                    ) : text === "cancelled" ? (
                        <Tag>Annulée</Tag>
                    ) : (
                        <Tag color="success">Terminé</Tag>
                    )}
                </>
            ),
        },
        {
            title: "Action",
            render: (_, record) => (
                <Space>
                    {rolePrevilleges?.previllegesList?.find(
                        (elem) => elem?.code === "CANCEL_SCHEDULED_RESIGNATION"
                    ) && (
                            <Popconfirm
                                disabled={true}
                                placement="topLeft"
                                title="Etes-vous sûr d'annuler cette tâche ?"
                                okText="Oui"
                                cancelText="Non"
                            //   onConfirm={() => handleCancel(record?._id)}
                            >
                                <Button
                                    disabled={true}
                                    size="small"
                                    shape="round"
                                >
                                    Relancer
                                </Button>
                            </Popconfirm>
                        )}
                    <Button size="small" shape="round" disabled>
                        Consulter
                    </Button>
                </Space>
            ),
        },
    ];

    // const handleCancel = async (id) => {
    //     try {
    //         const { data } = await axiosInstance.patch(`/api/plan/cancel/${id}`);

    //         setTasks(
    //             tasks.map((elem) => {
    //                 if (elem?._id === id) {
    //                     elem.status = "cancelled";
    //                 }
    //                 return elem;
    //             })
    //         );
    //         message.success(data?.message);
    //     } catch (error) {
    //         message.error(error?.response?.data?.message);
    //     }
    // };

    useEffect(() => {
        async function fetchData() {
            try {
                const { data } = await axiosInstance.get("/api/plan/");

                setTasks(data?.tasks);
                setLoading(false);
            } catch (error) {
                message.error(error?.response?.data?.message);
                setLoading(false);
            }
        }
        fetchData();
    }, []);
    return (
        <div className="  p-4  ">
            <div className="mb-3">
                <Typography.Title level={4}>Tâches planifiées</Typography.Title>
            </div>
            <Table columns={columns} loading={loading} dataSource={tasks} />
        </div>
    );
};

export default PlannedTasks;
