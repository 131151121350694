import { useEffect, useRef, useState } from "react";
import { Button, Input, Space, message, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import LocalStorageService from "../Utils/localStorageService";
import GlobalContext from "./GlobalContext";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../Utils/axios";

const themes = [
  {
    color: "#2d79a8",
    logo: "light",
    siderMode: "light",
    siderBg: "#ffffff",
    headerBg: "#ffffff",
  },
  {
    color: "#2b5497",
    logo: "dark",
    siderMode: "dark",
    siderBg: "#2b5497",
    headerBg: "#F4F5F7",
  },
  {
    color: "#4663ac",
    logo: "dark",
    siderMode: "dark",
    siderBg: "#001529",
    headerBg: "#F4F5F7",
  },
];

const AppContext = (props) => {
  const [employees, setEmployees] = useState([]);
  const [apiCollab, contextHolderCollab] = notification.useNotification();
  const [profile, setProfile] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [stats, setStats] = useState({});
  const [redirectData, setRedirectData] = useState({});
  const [theme, setTheme] = useState(
    localStorage.getItem("custom_theme")
      ? JSON.parse(localStorage.getItem("custom_theme"))
      : themes[
      localStorage.getItem("theme") ? localStorage.getItem("theme") : 0
      ]
  );

  const [role, setRole] = useState(
    LocalStorageService().getAccessToken()
      ? jwtDecode(LocalStorageService().getAccessToken()).Role
      : null
  );

  const [rolePrevilleges, setRolePrevilleges] = useState(null);

  const handleTheme = (idx) => {
    localStorage.removeItem("custom_theme");
    localStorage.setItem("theme", idx);
    setTheme(themes[idx]);
  };

  const handleRoleSwap = async (current_role) => {
    axiosInstance
      .post("/api/auth/swap/role/", {
        current_role: current_role,
      })
      .then((res) => {
        LocalStorageService().setToken(res?.data?.token);
        if (current_role !== "SUPER_ADMIN") {
          window.location.pathname = "/administration";
        } else {
          window.location.pathname = "/";
        }
      })
      .catch((error) =>
        message.error(error?.response?.data?.message || "Something went wrong")
      );
  };

  useEffect(() => {
    async function fetchProfiles() {
      try {
        const response = await axiosInstance.get("api/employees/");

        setEmployees(response?.data?.employees);
      } catch (error) {
        if (error?.response?.status === 401) {
          message.info("Votre session a expiré. Veuillez vous reconnecter.");
          setTimeout(() => {
            window.location.href = "/login";
          }, [2000]);
        }
      }
    }
    if (role !== "EMPLOYEE" && role) fetchProfiles();
  }, [role]);

  useEffect(() => {
    async function fetchRoles() {
      try {
        const { data } = await axiosInstance.get(
          `/api/role/${role ? role : "EMPLOYEE"}`
        );

        if (data?.status === "success") {
          setRolePrevilleges(data?.role);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchRoles();
  }, [role]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <dialog
        open
        style={{
          padding: 8,
          border: "none",
          backgroundColor: "white",
          boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
          position: "absolute",
          zIndex: 1000,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche par ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 110,
            }}
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 110,
            }}
          >
            Réinitialiser
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </dialog>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  return (
    <GlobalContext.Provider
      value={{
        employees,
        setEmployees,
        redirectData,
        setRedirectData,
        profile,
        setProfile,
        role,
        setRole,
        getColumnSearchProps,
        equipments,
        setEquipments,
        theme,
        setTheme,
        handleTheme,
        handleRoleSwap,
        apiCollab,
        contextHolderCollab,
        rolePrevilleges,
        setRolePrevilleges,
        stats,
        setStats,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
