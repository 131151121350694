import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Card, Empty, message, theme, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../Utils/axios";
import PropTypes from 'prop-types';

const Section = ({ section, index, columnId }) => {
  return (
    <Draggable draggableId={section.id} index={index}>
      {(provided) => (
        <Card
          bordered={true}
          hoverable
          size="small"
          className="mt-1"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <span className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faUpDownLeftRight}
              style={{ marginRight: "5px" }}
            />
            <div>{section.content}</div>
          </span>
        </Card>
      )}
    </Draggable>
  );
};

Section.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  columnId: PropTypes.string.isRequired
};

const Column = ({ sections, column, columnId }) => {
  return (
    <div className="px-1">
      <Droppable droppableId={columnId}>
        {(provided) => (
          <div
            style={{
              minHeight: "100px",
              padding: "10px",
              height: "55vh",
              overflowY: "scroll",
            }}
            className="rounded-3 shadow-sm border"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <h6 style={{ color: column?.titleColor }}>{column?.title}</h6>
            {sections?.length === 0 && column?.title !== "Compétences" && (
              <Empty description="Faites glisser depuis les compétences et déposez ici." />
            )}

            {sections.map((section, index) => (
              <Section
                key={section.id}
                section={section}
                index={index}
                columnId={columnId}
              />
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const SkillsMe = () => {
  const [sectionOrder, setSectionOrder] = useState(null);
  const [skills, setSkills] = useState([]);
  const [idCurrentBucket, setIdCurrentBucket] = useState("");

  const onDragEnd = (result) => {
    // Handle drag and drop logic here
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = sectionOrder.columns[source.droppableId];
    const finish = sectionOrder.columns[destination.droppableId];

    if (start === finish) {
      const newSectionIds = Array.from(start.sectionIds);
      newSectionIds.splice(source.index, 1);
      newSectionIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        sectionIds: newSectionIds,
      };

      setSectionOrder({
        ...sectionOrder,
        columns: {
          ...sectionOrder.columns,
          [newColumn.id]: newColumn,
        },
      });

      return;
    }

    // Moving from one list to another
    const startSectionIds = Array.from(start.sectionIds);
    startSectionIds.splice(source.index, 1);
    const newStart = {
      ...start,
      sectionIds: startSectionIds,
    };

    const finishSectionIds = Array.from(finish.sectionIds);
    finishSectionIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      sectionIds: finishSectionIds,
    };

    setSectionOrder({
      ...sectionOrder,
      columns: {
        ...sectionOrder.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/grid/skills/bucket/me");
        if (data?.skillsBuckets?.length > 0) {
          let temp = {
            sections: {},
            columns: {
              skills: {
                id: "skills",
                title: "Compétences",
                sectionIds: [],
              },
              notApplicable: {
                id: "notApplicable",
                title: "N/A",
                sectionIds: [],
              },
              beginner: {
                id: "beginner",
                title: "Débutant",
                sectionIds: [],
              },
              average: {
                id: "average",
                title: "Moyen",
                sectionIds: [],
              },
              advanced: {
                id: "advanced",
                title: "Avancé",
                sectionIds: [],
              },
            },
            columnOrder: [
              "skills",
              "notApplicable",
              "beginner",
              "average",
              "advanced",
            ],
          };

          data?.skillsBuckets[0]?.skillsList.forEach((element) => {
            temp.sections[element?.label] = {
              id: element?.label,
              content: element?.label,
            };
            temp.columns?.skills?.sectionIds.push(element?.label);
          });

          setSectionOrder(temp);
          setSkills(data?.skillsBuckets[0]?.skillsList);
          setIdCurrentBucket(data?.skillsBuckets[0]?._id);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
  }, []);

  const handleSave = async () => {
    const temp = {
      notApplicable: sectionOrder?.columns["notApplicable"]?.sectionIds?.map(
        (element) => {
          return skills?.find((elem) => elem?.label === element);
        }
      ),
      average: sectionOrder?.columns["average"]?.sectionIds?.map((element) => {
        return skills?.find((elem) => elem?.label === element);
      }),
      beginner: sectionOrder?.columns["beginner"]?.sectionIds?.map(
        (element) => {
          return skills?.find((elem) => elem?.label === element);
        }
      ),
      advanced: sectionOrder?.columns["advanced"]?.sectionIds?.map(
        (element) => {
          return skills?.find((elem) => elem?.label === element);
        }
      ),
    };
    try {
      const { data } = await axiosInstance.post(
        "/api/grid/save/skills/bucket/" + idCurrentBucket,
        temp
      );

      if (data?.status === "success") {
        message.success("Enregistré avec succès");
        setSectionOrder(null);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  return (
    <div className="p-4">
      <div style={{ minHeight: "80vh" }}>
        <div style={{ maxHeight: "82vh", overflowY: "scroll" }}>
          <div >
            <Typography.Title level={4}>💡 Grille des Compétences :</Typography.Title>
            <Typography.Text>
              Faites glisser et déposez les compétences correspondantes sur le
              niveau approprié. Une fois terminé, cliquez sur Enregistrer.
            </Typography.Text>
            <br />

            {sectionOrder ? (
              <div>
                <DragDropContext onDragEnd={onDragEnd} className="w-100">
                  <div
                    className="d-flex p-2 my-3 rounded-3 shadow-sm"
                    style={{
                      width: "100%",
                      overflowX: "scroll",
                      backgroundColor: colorBgContainer,
                    }}
                  >
                    {sectionOrder?.columnOrder?.map((columnId, index) => {
                      const column = sectionOrder.columns[columnId];
                      const sections = column.sectionIds.map(
                        (sectionId) => sectionOrder.sections[sectionId]
                      );

                      return (
                        <div className={"my-2"} style={{ width: "300px" }}>
                          <Column
                            key={columnId}
                            column={column}
                            sections={sections}
                            columnId={columnId}
                          />
                        </div>
                      );
                    })}
                  </div>
                </DragDropContext>
                <Button
                  size="large"
                  type="primary"
                  onClick={handleSave}
                  shape="round"
                  disabled={
                    sectionOrder?.columns?.skills?.sectionIds?.length > 0
                  }
                  className="my-3"
                >
                  Enregistrer
                </Button>
              </div>
            ) : (
              <Empty className="mt-4" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillsMe;
