import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Space,
  Transfer,
  message,
} from "antd";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import axiosInstance from "../../../Utils/axios";

const { RangePicker } = DatePicker;
const NewBucketModal = ({ handleRefresh }) => {
  const [form] = Form.useForm();
  const [skills, setSkills] = useState([]);
  const [data, setData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [targetUsersKeys, setTargetUserKeys] = useState([]);
  const [selectedUsersKeys, setSelectedUsersKeys] = useState([]);

  const [users, setUsers] = useState([]);
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    async function fetchProfiles() {
      const { data } = await axiosInstance.get("api/employees/");
      const temp = data?.employees?.map((elem) => {
        return {
          key: elem?.matricule,
          title: "#" + elem?.matricule + " " + elem?.fullName,
        };
      });
      setUsers(temp);
      setUsersData(data?.employees);
      return data;
    }

    fetchProfiles();
  }, []);

  const onChangeUsers = (nextTargetKeys, direction, moveKeys) => {
    setTargetUserKeys(nextTargetKeys);
  };

  const onSelectChangeUsers = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedUsersKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/grid/skills");

        const temp = data?.skills.map((elem) => {
          return {
            key: elem?._id,
            title: elem?.label + " (" + elem?.category + ")",
          };
        });
        setData(temp);
        setSkills(data?.skills);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= dayjs().endOf("day");
  };

  const filterOption = (inputValue, option) =>
    option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const handleSave = () => {
    form
      .validateFields()
      .then(async (res) => {
        const tempSkills = skills.filter((elem) =>
          targetKeys?.includes(elem?._id)
        );

        const tempUsers = usersData?.filter((elem) =>
          targetUsersKeys?.includes(elem?.matricule)
        );

        const payload = {
          name: res?.name,
          skillsList: tempSkills,
          dedicatedFor: tempUsers,
          startDate: dayjs(res?.dateRange[0]),
          endDate: dayjs(res?.dateRange[1]),
        };

        try {
          const { data } = await axiosInstance.post(
            "/api/grid/save/skills/bucket",
            payload
          );

          if (data.status === "success") {
            message.success("Enregistré avec succès");
            handleCancel();
            handleRefresh();
            form.resetFields();
            setTargetKeys([]);
            setTargetUserKeys([]);
          }
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Quelque chose s'est mal passé");
      });
  };
  return (
    <>
      <Button
        type="primary"
        shape="round"
        onClick={showModal}
        icon={<FontAwesomeIcon icon={faCirclePlus} />}
      >
        Nouvelle campagne
      </Button>
      <Drawer
        width={720}
        title="Nouvelle campagne"
        open={isModalOpen}
        extra={
          <Space>
            <Button onClick={handleCancel}>Annuler</Button>
            <Button onClick={handleSave} type="primary">
              Soumettre
            </Button>
          </Space>
        }
        onClose={handleCancel}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name={"name"}
            label="Nom"
            rules={[{ required: true, message: "Veuillez saisir un nom" }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name={"dateRange"}
            label="Plage de disponibilité"
            rules={[
              {
                required: true,
                message: "Veuillez sélectionner une plage de dates",
              },
            ]}
          >
            <RangePicker disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item label="Compétences">
            <Transfer
              listStyle={{
                width: 300,
                height: 300,
              }}
              dataSource={data}
              titles={["Disponibles", "Nouvelle Grille"]}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              onChange={onChange}
              filterOption={filterOption}
              showSearch
              onSelectChange={onSelectChange}
              render={(item) => item.title}
            />
          </Form.Item>
          <Form.Item label="Dédié à">
            <Transfer
              listStyle={{
                width: 300,
                height: 300,
              }}
              dataSource={users}
              titles={["Disponibles", "Nouvelle Grille"]}
              targetKeys={targetUsersKeys}
              selectedKeys={selectedUsersKeys}
              onChange={onChangeUsers}
              filterOption={filterOption}
              showSearch
              onSelectChange={onSelectChangeUsers}
              render={(item) => item.title}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};
export default NewBucketModal;
