import { message } from "antd";
import axios from "axios";
import localStorageService from "./localStorageService";

const apiHost = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({ baseURL: apiHost });

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorageService().getAccessToken();
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 500) {
        message.info("Quelque chose mal s'est passé.");
      } else if (error.response.status === 401) {
        message.info("Votre session a expiré. Veuillez vous reconnecter.");
        setTimeout(() => {
          window.location.href = "/login";
        }, [2000]);
      } else if (error.response.status === 403) {
        message.info("Vous n'êtes pas autorisé à effectuer cette action.");
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
