import { useState, useEffect, useRef, useContext } from "react";

import { SearchOutlined } from "@ant-design/icons";

import {
  Button,
  Space,
  Table,
  Tag,
  Typography,
  Input,
  Form,
  Tooltip,
  message,
  Modal,
  Popconfirm,
  DatePicker,
  Timeline,
} from "antd";
import GlobalContext from "../../../Context/GlobalContext";
import Highlighter from "react-highlight-words";

import axios from "../../../Utils/axios";
import dayjs from "dayjs";
import TeleworkRequests from "./TeleworkRequests";
import EquipmentsRequests from "./EquipmentsRequests";
import AccessRequests from "./AccessRequests";
import LeaveRequestsDrawer from "../../../Drawers/LeaveRequestsDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheck } from "@fortawesome/free-solid-svg-icons";
import EditLeaveModal from "./EditLeaveModal";

const { Text, Paragraph } = Typography;

const HistoryLeave = () => {
  const [form] = Form.useForm();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [dateRange, setDateRange] = useState("");
  const searchInput = useRef(null);
  const optionsTab = [
    "fullName",
    "request",
    "status",
    "motif",
    "startDate",
    "endDate",
    "duration",
    "answered_by",
  ];
  const [loading, setLoading] = useState(true);
  const [requestedLeaves, setRequestedLeaves] = useState([]);
  const { profile, redirectData, rolePrevilleges } = useContext(GlobalContext);

  const [size, setSize] = useState("small");
  const handleSizeChange = (new_size) => {
    setSize(new_size);
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("large");
          }}
        >
          Plus grand
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("middle");
          }}
        >
          Moyenne
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("small");
          }}
        >
          Compact
        </a>
      ),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  /* eslint-disable-next-line */
  const [expand, setExpand] = useState(true);

  const columns = [
    optionsTab.find((elem) => elem === "fullName")
      ? {
        title: "Demandeur",
        dataIndex: "fullName",
        key: "1",
        ...getColumnSearchProps("fullName"),
        render: (_, requestedLeaves) => (
          <Text>
            #{requestedLeaves.idEmployee} - {requestedLeaves.fullName}
          </Text>
        ),
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "request")
      ? {
        title: "Demande",
        dataIndex: "_typeLeave",
        width: 200,
        render: (_, requestedLeaves) => (
          <>
            {requestedLeaves._special_leave ? (
              <Paragraph
                style={{ margin: 0 }}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "Plus",
                  onExpand: () => {
                    setExpand(true);
                  },
                  onEllipsis: () => {
                    setExpand(false);
                  },
                }}
              >
                {requestedLeaves._typeLeave +
                  `${requestedLeaves._special_leave &&
                  " - " + requestedLeaves._special_leave
                  }`}
              </Paragraph>
            ) : (
              <Text>{requestedLeaves._typeLeave}</Text>
            )}
          </>
        ),
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "status")
      ? {
        title: "Statut",
        dataIndex: "status",
        key: "7",
        filters: [
          {
            text: <Tag color={"orange"}>En attente</Tag>,
            value: "Pending",
          },

          {
            text: <Tag color={"green"}>Approuvée</Tag>,
            value: "Accepted",
          },

          {
            text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
            value: "Validating",
          },

          {
            text: <Tag color={"red"}>Rejeté</Tag>,
            value: "Rejected",
          },

          {
            text: <Tag color="red">Annulée</Tag>,
            value: "Canceled",
          },

          {
            text: <Tag color={"gold"}>Non encore justifié</Tag>,
            value: "Unjustified",
          },

          {
            text: <Tag>Échue</Tag>,
            value: "Failed",
          },
        ],
        onFilter: (value, record) => record.status.includes(value),
        render: (_, record) => (
          <>
            {record.status === "Pending" && (
              <Tag color={"orange"}>En attente</Tag>
            )}
            {record.status === "Accepted" && (
              <Tag color={"green"}>Approuvée</Tag>
            )}
            {record.status === "Validating" && (
              <Tag color={"cyan"}>En attente d'approbation</Tag>
            )}
            {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
            {record.status === "Canceled" && <Tag color="red">Annulée</Tag>}
            {record.status === "Unjustified" && (
              <Tag color={"gold"}>Non encore justifié</Tag>
            )}
            {record.status === "Failed" && <Tag>Échue</Tag>}
          </>
        ),
      }
      : { width: 0, className: "d-none" },

    optionsTab.find((elem) => elem === "startDate")
      ? {
        title: "Date de début",
        dataIndex: "BeginingDate",
        sorter: (a, b) => dayjs(a.BeginingDate) - dayjs(b.BeginingDate),
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "endDate")
      ? {
        title: "Date de fin",
        dataIndex: "EndingDate",
        key: "6",
        sorter: (a, b) => dayjs(a.EndingDate) - dayjs(b.EndingDate),
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "duration")
      ? {
        title: "Durée",
        render: (_, requestedLeaves) => (
          <Text>
            {requestedLeaves._typeLeave === "Autorisation"
              ? requestedLeaves._duration
              : !requestedLeaves._is_half_day
                ? requestedLeaves.DaysNumber + " Jour(s)"
                : requestedLeaves._is_half_day}
          </Text>
        ),
      }
      : { width: 0, className: "d-none" },

    optionsTab.find((elem) => elem === "answered_by")
      ? {
        title: "Répondu par",
        dataIndex: "_answered_by",
        render: (_, requestedLeaves) => (
          <>
            {requestedLeaves.status === "Pending" ? (
              <Tag color={"orange"}>En attente</Tag>
            ) : requestedLeaves._answered_by ? (
              <Tooltip
                title={
                  "Répondu à " +
                  dayjs(requestedLeaves._answered_at).format(
                    "DD/MM/YYYY HH:mm"
                  )
                }
              >
                <Text> {requestedLeaves._answered_by} </Text>
              </Tooltip>
            ) : (
              <Text>N/A</Text>
            )}
          </>
        ),
      }
      : { width: 0, className: "d-none" },

    rolePrevilleges?.previllegesList?.find(
      (elem) => elem?.code === "CANCEL_LEAVE"
    )
      ? {
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Space>
            {record.status === "Canceled" ? (
              <Tooltip title="Valider la demande">
                <Button size="small" type="primary" danger shape="circle" disabled>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </Tooltip>
            ) : (
              <Popconfirm
                placement="topLeft"
                okText="Oui"
                title="Vous êtes sûr d'annuler la demande?"
                onConfirm={() => {
                  Modal.info({
                    title: "Annuler la demande",
                    content: (
                      <>
                        <Form form={form} layout="vertical">
                          <Form.Item name="motif" label="Reponse:">
                            <Input.TextArea placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace." />
                          </Form.Item>
                        </Form>
                      </>
                    ),
                    onOk: () => {
                      handleActions({
                        _id: record._id,
                        value: "Canceled",
                      });
                    },
                  });
                }}
              >
                <Tooltip title="Annuler la demande">
                  <Button size="small" type="primary" danger shape="circle">
                    <FontAwesomeIcon icon={faBan} />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}

            <LeaveRequestsDrawer record={record} />
            <EditLeaveModal record={record} />
          </Space>
        ),
      }
      : { width: 0, className: "d-none" },
  ];

  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/force/" + options._id, {
        status: options.value,
        motif: form.getFieldsValue().motif,
      });
      if (data.status === "success") {
        message.info(data.message);
        const newRequestedLeaves = requestedLeaves.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
          }
          return RL;
        });
        setRequestedLeaves(newRequestedLeaves);
      }
    } catch (error) { }
  };

  useEffect(() => {
    async function fetchRequestedLeaves() {
      try {
        const { data } = await axios.get(
          `/api/leaves/admin/requests${dateRange
            ? "?startDate=" +
            dateRange.startDate +
            "&endDate=" +
            dateRange.endDate
            : ""
          }`
        );
        const temp = data?.filter((elem) => elem?._typeLeave !== "Télétravail");
        setRequestedLeaves(temp);

        setLoading(false);
        return data;
      } catch (error) {
        message.info(error.response.data.message);
      }
    }

    fetchRequestedLeaves();
  }, [dateRange]);

  return (
    <div className=" p-4  ">
      <div className="  mb-5">
        <Typography.Title level={4}>
          📋 Historique des demandes :
        </Typography.Title>
      </div>

      <Timeline>
        {profile.sub_role !== "SYS_ADMIN" && (
          <Timeline.Item>
            <h5 style={{ fontWeight: "400" }}>✈️ Congés/Autorisations</h5>
            <Table
              columns={columns}
              loading={loading}
              dataSource={requestedLeaves}
              scroll={{ x: "max-content" }}
            />
          </Timeline.Item>
        )}

        {profile.sub_role !== "SYS_ADMIN" && (
          <Timeline.Item>
            <h5 style={{ fontWeight: "400" }}>🏡 Télétravail</h5>
            <TeleworkRequests />
          </Timeline.Item>
        )}

        <Timeline.Item>
          <h5 style={{ fontWeight: "400" }}>🔑 Accès</h5>
          <AccessRequests
            getColumnSearchProps={getColumnSearchProps}
            size={size}
          />
        </Timeline.Item>
        <Timeline.Item>
          <h5 style={{ fontWeight: "400" }}>💻 Equipements</h5>
          <EquipmentsRequests
            getColumnSearchProps={getColumnSearchProps}
            size={size}
          />
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

export default HistoryLeave;
