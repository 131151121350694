import { Button, Radio, Empty, message, Space, Input, DatePicker, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DocumentCard from "../../../Components/DocumentCard";
import axios from "../../../Utils/axios";
import dayjs from "dayjs";

const { Search } = Input;

const DocumentsList = () => {
  const [documents, setDocuments] = useState([]);
  const [reset, setReset] = useState(false);
  const [value, setValue] = useState("tous");
  const [changed, setChange] = useState(false);

  const clearFilters = () => {
    setChange(!changed);
    setValue("tous");
  };

  const onChangeDate = async (date, dateString) => {
    setValue("tous");
    const [year, weekNum] = dateString.split("-");
    // Calculate the start date and end date of the week using dayjs
    const startDate = dayjs().year(year).week(weekNum).startOf("week");
    const endDate = dayjs().year(year).week(weekNum).endOf("week");
    // Do something with the start date and end date
    try {
      const { data } = await axios.get(
        `/api/docs/filter/date?startDate=${dayjs(startDate).format(
          "DD-MM-YYYY"
        )}&endDate=${dayjs(endDate).format("DD-MM-YYYY")}`
      );

      setDocuments(data.docs);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  async function fetchData() {
    try {
      const { data } = await axios.get("/api/docs/");
      setDocuments(data.docs);
      return data;
    } catch (error) { }
  }

  useEffect(() => {
    fetchData();
  }, [changed]);

  const Navigate = useNavigate();

  const handleDisable = async (_id, _disabled) => {
    try {
      await axios.patch(`/api/docs/${_id}`, {
        _disabled: !_disabled,
      });
      setReset(!reset);
      message.success("Le document a été mis à jour avec succès");
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleDelete = async (_id) => {
    try {
      await axios.delete(`/api/docs/${_id}`);
      const temp = documents.filter((doc) => doc._id !== _id);
      setDocuments(temp);
      message.info("Le document a été supprimé avec succès");
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const onChange = async (e) => {
    setValue(e.target.value);
    if (
      e.target.value === "Assurance" ||
      e.target.value === "Best Practise" ||
      e.target.value === "Administratif" ||
      e.target.value === "Autre"
    ) {
      try {
        const { data } = await axios.get(
          `/api/docs/filter/category/${e.target.value}`
        );
        setDocuments(data.docs);
      } catch (error) { }
    } else {
      fetchData();
    }
  };

  const handleBlur = async (value) => {
    setValue("tous");
    try {
      const { data } = await axios.get(`/api/docs/filter/title/${value}`);
      setDocuments(data.docs);
    } catch (error) {
      message.info("Veuillez remplir le nom du document");
    }
  };

  return (
    <div
      className="p-4 pb-5"
      style={{ height: "88vh", overflowY: "scroll" }}
    >
      <div className="">
        <Typography.Title level={4}>Documents partagés</Typography.Title>
      </div>
      <div className="row">
        <div
          className="col-lg-3 col-md-12 col-sm-12"
          style={{
            padding: "20px",
            borderRadius: "13px",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: ".5rem",
            }}
          >
            <div className="border-bottom pb-2">
              <Typography.Text>Catégorie:</Typography.Text>
              <br />
              <Radio.Group onChange={onChange} value={value}>
                <Space direction="vertical">
                  <Radio value="Assurance">Assurance</Radio>
                  <Radio value="Best Practise">Best Practice</Radio>
                  <Radio value="Administratif">Administratif</Radio>
                  <Radio value="Autre">Autre</Radio>
                  <Radio value="tous">Toutes les catégories</Radio>
                </Space>
              </Radio.Group>
            </div>
            <div className="border-bottom pb-2 pt-2">
              <Typography.Text>Nom document:</Typography.Text>
              <br />
              <Search
                placeholder="Exp: Guide ActiveCollab"
                allowClear
                onSearch={handleBlur}
              />
            </div>
            <div className="border-bottom pb-2 pt-2">
              <Typography.Text>Semaine:</Typography.Text>
              <br />
              <DatePicker
                onChange={onChangeDate}
                picker="week"
                className="w-100"
              />
            </div>
            <div className="pb-2 pt-2">
              <Button type="primary" onClick={clearFilters}>
                Effacer les filtres
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="w-100 d-flex justify-content-center"> */}
        <div className="col-lg-8 col-md-12 col-sm-12 ">
          {documents.length > 0 ? (
            documents.map((doc) => {
              return (
                <DocumentCard
                  id={doc._id}
                  adminAccess={true}
                  handleDelete={handleDelete}
                  handleDisable={handleDisable}
                  reset={reset}
                />
              );
            })
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={<span>Il n'y a pas de documents partagés.</span>}
            >
              <Button
                type="primary"
                onClick={() => {
                  Navigate("/administration/documents/form");
                }}
              >
                Partager maintenant !
              </Button>
            </Empty>
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentsList;
