import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "antd";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import PropTypes from "prop-types";
const PlusModal = ({ data, date }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const capitalizeFirstLetter = (str) => {
    const capitalized = str[0].toUpperCase() + str.slice(1).toLowerCase();
    return capitalized;
  };
  const getBackgroundColor = (elem) => {
    if (elem?._is_half_day) return "#FFBE0B";
    switch (elem?._typeLeave) {
      case "Congé annuel": return "#1ECB44";
      case "Autorisation": return "#8338EC";
      case "Congé sans solde": return "#FB0707";
      case "Congé Special": return "#FB33FF";
      default: return "#3A86FF";
    }
  };

  const getLeaveLabel = (elem) => {
    if (elem?._is_half_day) return "Congé 1/2";
    if (elem?._typeLeave === "Congé annuel") return "Congé";
    if (elem?._typeLeave === "Autorisation") return "Autorisation";
    return "Congé";
  };

  const renderCollaboratorItem = (elem, index) => (
    <div className="d-flex justify-content-between my-1" key={index + "-list"}>
      <span style={{ fontWeight: "500" }}>
        {capitalizeFirstLetter(elem?.fullName)}
      </span>
      <span
        style={{
          backgroundColor: getBackgroundColor(elem),
          borderRadius: "20px",
          color: "#FFFFFF",
          fontSize: "12px",
          fontWeight: "500",
          padding: "4px 7px",
          textAlign: "center",
        }}
      >
        {getLeaveLabel(elem)}
      </span>
    </div>
  );

  return (
    <>
      <Button
        block
        type="primary"
        size="small"
        style={{ fontWeight: "500" }}
        onClick={() => setModalOpen(true)}
      >
        Voir Plus
      </Button>
      <Modal
        okButtonProps={{
          disabled: false,
        }}
        footer={null}
        title={
          <span style={{ color: "#2B96FF", fontSize: "14px" }}>
            <FontAwesomeIcon icon={faCalendar} /> Calendrier
          </span>
        }
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        <div>
          <h1 style={{ fontSize: "18px", fontWeight: "600" }}>
            {dayjs(date).format("DD MMMM YYYY")}
          </h1>
          <h2 style={{ fontSize: "14px", fontWeight: "400" }}>
            {data?.length} Collaborateur(s) en congé(s)
          </h2>
        </div>
        <div
          style={{ height: "317px", overflowY: "scroll" }}
          className="d-flex flex-column"
        >
          {data?.map(renderCollaboratorItem)}
        </div>
      </Modal>
    </>
  );
};

PlusModal.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      _is_half_day: PropTypes.bool,
      _typeLeave: PropTypes.string,
    })
  ),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

PlusModal.defaultProps = {
  data: [],
};
export default PlusModal;
