import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./Reducers";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import AppContext from "./Context/AppContext";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import * as Sentry from "@sentry/react";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

dayjs.locale("fr");

dayjs.tz.setDefault("Africa/Tunis");

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: "system",
      triggerLabel: "Signaler un problème",
      triggerAriaLabel: "Signaler un problème",
      formTitle: "Signaler un problème",
      submitButtonLabel: "Envoyer le rapport",
      cancelButtonLabel: "Annuler",
      confirmButtonLabel: "Confirmer",
      addScreenshotButtonLabel: "Ajouter une capture d'écran",
      removeScreenshotButtonLabel: "Supprimer la capture d'écran",
      nameLabel: "Nom",
      namePlaceholder: "Votre nom",
      emailLabel: "E-mail",
      emailPlaceholder: "votre.email@exemple.fr",
      isRequiredLabel: "(obligatoire)",
      messageLabel: "Description",
      messagePlaceholder: "Quel est le problème ? Qu'attendiez-vous ?",
      successMessageText: "Merci pour votre rapport !",
    }),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  environment: process.env.REACT_APP_NODE_ENV,
});

const store = createStore(reducers, compose(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <AppContext>
        <App />
      </AppContext>
    </Provider>
  </BrowserRouter>
);
