import {
  Button,
  Checkbox,
  Form,
  Modal,
  Space,
  Table,
  Tag,
  Typography,
  message,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import CreateContract from "./CreateContract";
import axiosInstance from "../../../Utils/axios";
import dayjs from "dayjs";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import GlobalContext from "../../../Context/GlobalContext";
import EditContract from "./EditContract";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";

const ContractsManagement = () => {
  const { getColumnSearchProps } = useContext(GlobalContext);
  const [dataSource, setDataSource] = useState([]);
  const [open, setOpen] = useState(false);
  const [colonnes, setColonnes] = useState([
    "Nom et prénom",
    "Type Contrat",
    "Date de début",
    "Date de fin",
    "Statut",
    "Periode d'essai",
    "Matricule",
  ]);
  const [tempDataSource, setTempDataSource] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/contract/");

        setDataSource(data?.contracts);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchData();
  }, []);

  const columns = [
    {
      title: "Nom et prénom",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName", "Nom et prénom"),
    },
    {
      title: "Type Contrat",
      dataIndex: "contractType",
      filters: [
        {
          text: "CDI",
          value: "CDI",
        },
        {
          text: "CIVP",
          value: "CIVP",
        },
        {
          value: "KARAMA",
          text: "KARAMA",
        },
        {
          value: "CDD",
          text: "CDD",
        },
      ],
      onFilter: (value, record) => record.contractType === value,
    },
    {
      title: "Period d'essai",
      render: (_, record) => (
        <Space>
          {record?.trail?.endDate
            ? dayjs(record?.trail?.startDate).format("DD/MM/YYYY") +
            " - " +
            dayjs(record?.trail?.endDate).format("DD/MM/YYYY")
            : "N/A"}
        </Space>
      ),
    },
    {
      title: "Date de début",
      dataIndex: "embaucheDate",
      sorter: (a, b) => dayjs(a.embaucheDate) - dayjs(b.embaucheDate),
      render: (text) => <>{dayjs(text).format("DD/MM/YYYY")}</>,
    },
    {
      title: "Date de fin",
      dataIndex: "endContractDate",
      sorter: (a, b) => dayjs(a.endContractDate) - dayjs(b.endContractDate),
      render: (text) => <>{text ? dayjs(text).format("DD/MM/YYYY") : "N/A"}</>,
    },
    {
      title: "Statut",
      dataIndex: "status",
      filters: [
        {
          text: (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Activé
            </Tag>
          ),
          value: "actif",
        },
        {
          text: <Tag icon={<ExclamationCircleOutlined />}>Expiré</Tag>,
          value: "expired",
        },
        {
          text: (
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              En Attente
            </Tag>
          ),
          value: "onHold",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (text) => (
        <>
          {text === "actif" ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Activé
            </Tag>
          ) : text === "onHold" ? (
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              En Attente
            </Tag>
          ) : (
            <Tag icon={<ExclamationCircleOutlined />}>Expiré</Tag>
          )}
        </>
      ),
    },
    {
      title: "Action(s)",
      render: (_, record) => (
        <Space>
          <EditContract
            editableData={record}
            dataSource={dataSource}
            setDataSource={setDataSource}
          />
        </Space>
      ),
    },
  ];
  const options = [
    {
      label: (
        <Tag
          color={colonnes.includes("Matricule") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Matricule
        </Tag>
      ),
      value: "Matricule",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Nom et prénom") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Nom et prénom
        </Tag>
      ),
      value: "Nom et prénom",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Type Contrat") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Type Contrat
        </Tag>
      ),
      value: "Type Contrat",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Periode d'essai") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Periode d'essai
        </Tag>
      ),
      value: "Periode d'essai",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Date de début") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Date de début
        </Tag>
      ),
      value: "Date de début",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Date de fin") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Date de fin
        </Tag>
      ),
      value: "Date de fin",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Statut") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Statut
        </Tag>
      ),
      value: "Statut",
    },
  ];

  const onChangeColonnes = (checkedValues) => {
    setColonnes(checkedValues);
  };

  const handleExportToXLSX = () => {
    if (colonnes.length > 0) {
      let tableData = tempDataSource.length > 0 ? tempDataSource : dataSource;
      const data = tableData?.map((record) => {
        return colonnes.reduce((acc, key) => {
          switch (key) {
            case "Nom et prénom":
              acc[key] = record.fullName;
              break;
            case "Matricule":
              acc[key] = record.matricule;
              break;
            case "Type Contrat":
              acc[key] = record.contractType;
              break;
            case "Periode d'essai":
              acc[key] = record.trail
                ? `${dayjs(record?.taril?.startDate).format(
                  "DD/MM/YYYY"
                )}-${dayjs(record?.taril?.endDate).format("DD/MM/YYYY")}`
                : "N/A";
              break;
            case "Date de début":
              acc[key] = dayjs(record.embaucheDate).format("DD/MM/YYYY");
              break;
            case "Date de fin":
              acc[key] = record.endContractDate
                ? dayjs(record.endContractDate).format("DD/MM/YYYY")
                : "N/A";
              break;
            case "Statut":
              acc[key] = record.status;
              break;

            default:
              acc[key] = ""; // Handle default case or provide a default value
              break;
          }
          return acc;
        }, {});
      });
      const worksheet = XLSX.utils.json_to_sheet(data);

      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(
        workbook,
        `Liste des Contrats ${dayjs().format("DD/MM/YYYY HH-mm")}.xlsx`
      );
    } else {
      notification.warning({
        message: "",
        description: "Veuillez choisir au moins une colonne",
      });
    }
  };

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between">
        <Typography.Title level={4} className="">
          Gestion des Contrats
        </Typography.Title>
        <Space>
          <Button
            type="primary"
            shape="round"
            onClick={() => setOpen(true)}
            //   disabled={!(employees.length > 0)}
            icon={<FontAwesomeIcon icon={faFileExport} />}
          >
            Exporter
          </Button>
          <CreateContract
            dataSource={dataSource}
            setDataSource={setDataSource}
          />
        </Space>
      </div>
      <div>
        <div>
          <Table
            columns={columns}
            dataSource={dataSource}
            onChange={(pagination, filters, sorter, extra) => {
              setTempDataSource(extra.currentDataSource);
            }}
          />
        </div>
      </div>
      <Modal
        title="Choisir les colonnes à exporter"
        open={open}
        centered
        okText="Exporter"
        onOk={handleExportToXLSX}
        onCancel={() => setOpen(false)}
      >
        <Form.Item>
          <Checkbox.Group
            value={colonnes}
            className="row gap-2"
            onChange={(e) => onChangeColonnes(e)}
            style={{ margin: "2rem 0" }}
          >
            {options.map((option) => (
              <div className="col-lg-4 col-6">
                <Checkbox key={option.value} value={option.value}>
                  {option.label}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Modal>
    </div>
  );
};

export default ContractsManagement;
