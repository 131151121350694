import dayjs from "dayjs";
import * as XLSX from "xlsx";
import axios from "../../../Utils/axios";

import Highlighter from "react-highlight-words";
import GlobalContext from "../../../Context/GlobalContext";

import { useContext, useEffect, useState, useRef } from "react";
import {
  Table,
  Tag,
  Typography,
  Space,
  Form,
  Modal,
  Button,
  Popconfirm,
  Input,
  DatePicker,
  message,
  Checkbox,
  notification,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
const { Text } = Typography;
const { RangePicker } = DatePicker;

const OtherAbsenceListAdmin = () => {
  const { role } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [absences, setAbsences] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [tempAbsences, setTempAbsence] = useState([]);
  const [colonnes, setColonnes] = useState([
    "Collaborateur",
    "Date d'acquisition",
    "Date de début",
    "Date de fin",
    "Type d'absence",
    "Nombre de jours",
    "Statut",
  ]);
  const [open, setOpen] = useState(false);

  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    async function fetchAbsences() {
      try {
        const { data } = await axios.get(
          `/api/leaves/absences/admin/list${dateRange
            ? "?startDate=" +
            dateRange.startDate +
            "&endDate=" +
            dateRange.endDate
            : ""
          }`
        );

        const temp = data?.absences?.filter(
          (elem) => elem?._typeLeave !== "Absence de reporting"
        );
        setAbsences(temp);
        setLoading(false);
      } catch (error) {
        message.info(error.response.data.message);
      }
    }
    fetchAbsences();
  }, [dateRange]);

  const onChange = (date) => {
    if (date) {
      const startDate = dayjs(date[0]).format("DD-MM-YYYY");
      const endDate = dayjs(date[1]).format("DD-MM-YYYY");
      setDateRange({ startDate: startDate, endDate: endDate });
    } else {
      setDateRange("");
    }
  };

  const onChangeColonnes = (checkedValues) => {
    setColonnes(checkedValues);
  };

  const handleExportToXLSX = () => {
    if (colonnes.length > 0) {
      let tableData = tempAbsences.length > 0 ? tempAbsences : absences;
      const data = tableData?.map((record) => {
        return colonnes.reduce((acc, key) => {
          switch (key) {
            case "Collaborateur":
              acc[key] = record.fullName + "#" + record.idEmployee;
              break;

            case "Type d'absence":
              acc[key] = record._typeLeave;
              break;

            case "Date d'acquisition":
              acc[key] = dayjs(record._posted_date).format("DD/MM/YYYY");
              break;
            case "Date de début":
              acc[key] = dayjs(record.BeginingDate).format("DD/MM/YYYY");
              break;
            case "Date de fin":
              acc[key] = dayjs(record.EndingDate).format("DD/MM/YYYY");
              break;

            case "Nombre de jours":
              acc[key] = record.DaysNumber;
              break;
            case "Statut":
              acc[key] =
                record.status === "Pending"
                  ? "Non encore justifiée."
                  : record.status === "Accepted"
                    ? "Approuvée"
                    : record.status === "Validating"
                      ? "En attente d'approbation"
                      : record.status === "Rejected"
                        ? "Rejeté"
                        : record.status === "Canceled"
                          ? "Annulée"
                          : record.status === "Unjustified"
                            ? "Non encore justifiée"
                            : "Échue";
              break;

            default:
              acc[key] = ""; // Handle default case or provide a default value
              break;
          }
          return acc;
        }, {});
      });

      const worksheet = XLSX.utils.json_to_sheet(data);

      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      XLSX.writeFile(
        workbook,
        `Liste des absences ${dayjs().format("DD/MM/YYYY HH-mm")}.xlsx`
      );
    } else {
      notification.warning({
        message: "",
        description: "Veuillez choisir au moins une colonne",
      });
    }
  };
  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/force/" + options._id, {
        status: options.value,
        motif: form.getFieldsValue().motif,
      });
      if (data.status === "success") {
        message.info(data.message);
        const newRequestedLeaves = absences.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
          }
          return RL;
        });
        setAbsences(newRequestedLeaves);
      }
    } catch (error) { }
  };

  const columns = [
    {
      title: "Collaborateur",
      ...getColumnSearchProps("fullName", "Nom et prénom"),
      render: (_, record) => (
        <Link
          to={"/administration/employees/" + record.idEmployee}
          style={{ textDecoration: "none", fontWeight: "bold" }}
        >
          {record.fullName} #{record.idEmployee}
        </Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "_typeLeave",
    },
    {
      title: "Date de début",
      dataIndex: "BeginingDate",
      key: "5",
      render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
    },
    {
      title: "Date de Fin",
      dataIndex: "EndingDate",
      key: "6",
      render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
    },
    {
      title: "Nombre du jours",
      dataIndex: "DaysNumber",
      key: "8",
      render: (_, record) => (
        <Text>
          {record._is_half_day
            ? record._is_half_day
            : record.DaysNumber + " jour(s)"}
        </Text>
      ),
    },
    {
      title: "Statut",
      dataIndex: "status",
      key: "7",
      render: (_, record) => (
        <>
          {record.status === "Pending" && (
            <Tag color={"orange"}>Non encore justifiée.</Tag>
          )}
          {record.status === "Accepted" && <Tag color={"green"}>Approuvée</Tag>}
          {record.status === "Validating" && (
            <Tag color={"green"}>En attente d'approbation</Tag>
          )}
          {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
          {record.status === "Canceled" && <Tag color="red">Annulée</Tag>}
          {record.status === "Unjustified" && (
            <Tag color={"red"}>Non encore justifiée</Tag>
          )}
          {record.status === "Failed" && <Tag color={"red"}>Échue</Tag>}
        </>
      ),
    },
    {
      title: "Fichie(s)",
      dataIndex: "OptionalFiles",
      render: (_, record) => (
        <a href={record.OptionalFiles} download>
          télécharger
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {role === "RH" || role === "SUPER_ADMIN" ? (
            <>
              {record.status === "Pending" ||
                record.status === "Unjustified" ? (
                <>
                  {/* eslint-disable-next-line */}

                  <Popconfirm
                    placement="topLeft"
                    okText="Oui"
                    title="Vous êtes sûr d'annuler la demande?"
                    onConfirm={() => {
                      Modal.info({
                        title: "Annuler la demande",
                        content: (
                          <>
                            <Form form={form} layout="vertical">
                              <Form.Item name="motif" label="Reponse:">
                                <Input.TextArea />
                              </Form.Item>
                            </Form>
                          </>
                        ),
                        onOk: () => {
                          handleActions({
                            _id: record._id,
                            value: "Canceled",
                          });
                        },
                      });
                    }}
                  >
                    <Button size="small" type="primary">
                      Annuler
                    </Button>
                  </Popconfirm>
                </>
              ) : (
                <>
                  {record.status === "Canceled" ? (
                    <Button size="small" type="primary" disabled={true}>
                      Annuler
                    </Button>
                  ) : (
                    <>
                      {record.status === "Validating" ? (
                        <Popconfirm
                          placement="topLeft"
                          okText="Oui"
                          title="Vous êtes sûr d'approuver la demande?"
                          onConfirm={() => {
                            handleActions({
                              _id: record._id,
                              value: "Accepted",
                            });
                          }}
                        >
                          <Button size="small" ghost type="primary">
                            Approuver
                          </Button>
                        </Popconfirm>
                      ) : (
                        <Popconfirm
                          placement="topLeft"
                          okText="Oui"
                          title="Vous êtes sûr d'annuler la demande?"
                          onConfirm={() => {
                            handleActions({
                              _id: record._id,
                              value: "Canceled",
                            });
                          }}
                        >
                          <Button size="small" type="primary">
                            Annuler
                          </Button>
                        </Popconfirm>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <Button disabled size="small">
              N/A
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const options = [
    {
      label: (
        <Tag
          color={colonnes.includes("Collaborateur") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Collaborateur
        </Tag>
      ),
      value: "Collaborateur",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Date d'acquisition") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Date d'acquisition
        </Tag>
      ),
      value: "Date d'acquisition",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Date de début") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Date de début
        </Tag>
      ),
      value: "Date de début",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Date de fin") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Date de fin
        </Tag>
      ),
      value: "Date de fin",
    },
    {
      label: (
        <Tag
          color={colonnes.includes("Type d'absence") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Type d'absence
        </Tag>
      ),
      value: "Type d'absence",
    },

    {
      label: (
        <Tag
          color={colonnes.includes("Nombre de jours") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Nombre de jours
        </Tag>
      ),
      value: "Nombre de jours",
    },

    {
      label: (
        <Tag
          color={colonnes.includes("Statut") ? "blue" : "default"}
          style={{
            fontSize: "12px",
            padding: "5px 10px",
          }}
        >
          Statut
        </Tag>
      ),
      value: "Statut",
    },
  ];

  return (
    <div className="p-4 rounded-4">
      <div className=" mb-3 ">
        <Typography.Title level={4}>Absences</Typography.Title>

        <div className="col-lg-12 col-md-5 col-sm-12 d-flex mb-3 justify-content-between">
          <div className="pb-2 border-bottom">
            <RangePicker
              onChange={onChange}
              className="w-100"
              placement="bottomLeft"
            />
          </div>
          <Button
            style={{ marginLeft: "10px" }}
            type="primary"
            onClick={() => setOpen(true)}
            disabled={!(absences.length > 0)}
          >
            <FontAwesomeIcon
              icon={faFileExport}
              style={{ marginRight: "5px" }}
            />{" "}
            Exporter
          </Button>
        </div>
      </div>

      <Table
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={absences}
        onChange={(pagination, filters, sorter, extra) => {
          setTempAbsence(extra.currentDataSource);
        }}
      />
      <Modal
        title="Choisir les colonnes à exporter"
        open={open}
        centered
        okText="Exporter"
        onOk={handleExportToXLSX}
        onCancel={() => setOpen(false)}
      >
        <Form.Item>
          <Checkbox.Group
            value={colonnes}
            className="row justify-content-evenly"
            onChange={(e) => onChangeColonnes(e)}
          >
            {options.map((option, index) => (
              <div className="col-lg-3 my-1 col-6" key={index}>
                <Checkbox key={option.value} value={option.value}>
                  {option.label}
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>
        </Form.Item>
      </Modal>
    </div>
  );
};

export default OtherAbsenceListAdmin;
