import {
  Button,
  Popconfirm,
  Space,
  Table,
  Tag,
  Typography,
  message,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import AccessRequestDrawer from "../../../Components/LayoutClient/AccessRequestDrawer/AccessRequestDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

const Acces = ({ size }) => {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);

  const handleCancel = async (record) => {
    try {
      const { data } = await axiosInstance.patch(
        "/api/request/cancel/" + record?._id
      );

      if (data?.status === "success") {
        const temp = records.filter((elem) => {
          if (elem?._id === record?._id) {
            elem.status = "CANCELED";
          }

          return elem;
        });

        setRecords(temp);
        message.success(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    async function fetchRecords() {
      try {
        const { data } = await axiosInstance.get("/api/request/access/me");
        const tempData = data?.requests?.filter((elem) => {
          return elem?.status === "PENDING";
        });
        setRecords(tempData);
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchRecords();
  }, []);

  const columns = [
    {
      title: "Soumis le",
      width: 200,
      render: (_, record) => (
        <Typography.Text strong>
          {record?.send_time_date
            ? dayjs(record.send_time_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Typography.Text>
      ),
      sorter: (a, b) => a.send_time_date - b.send_time_date,
    },
    {
      width: 350,
      title: "Accès demandé",
      dataIndex: "request",
      render: (text) => (
        <Typography.Text className="text-uppercase">{text}</Typography.Text>
      ),
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (_, record) => (
        <>
          <Tag
            color={
              record?.status === "PENDING"
                ? "orange"
                : record?.status === "APPROVED"
                  ? "green"
                  : record?.status === "REJECTED"
                    ? "red"
                    : "default"
            }
          >
            {record?.status === "PENDING"
              ? "En attente"
              : record?.status === "APPROVED"
                ? "Approuvée"
                : record?.status === "REJECTED"
                  ? "Rejetée"
                  : "Annulée"}
            {record?.status === "PENDING" ? (
              <LoadingOutlined style={{ marginLeft: "5px" }} />
            ) : record?.status === "APPROVED" ? (
              <CheckCircleOutlined style={{ marginLeft: "5px" }} />
            ) : record?.status === "REJECTED" ? (
              <CloseCircleOutlined style={{ marginLeft: "5px" }} />
            ) : (
              <MinusCircleOutlined style={{ marginLeft: "5px" }} />
            )}
          </Tag>
        </>
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          <Popconfirm
            placement="topLeft"
            title="Etes-vous sûr d'annuler la demande ?"
            okText="Oui"
            cancelText="Non"
            onConfirm={() => handleCancel(record)}
            disabled={record?.status !== "PENDING"}
          >
            <Tooltip title="Annuler vote demande.">
              <Button
                shape="circle"
                size="small"
                type="primary"
                disabled={record?.status !== "PENDING"}
              >
                <FontAwesomeIcon icon={faBan} />
              </Button>
            </Tooltip>
          </Popconfirm>
          <AccessRequestDrawer record={record} />
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={records}
        loading={loading}
      />
    </div>
  );
};

export default Acces;
