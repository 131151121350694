import React from "react";
import { Button, Descriptions, Modal, Tag, Skeleton } from "antd";
import dayjs from "dayjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const LeaveDetailsDrawer = ({ open, setOpen, record, loading }) => {
  const onClose = () => {
    setOpen(false);
  };
  const renderDuration = () => {
    if (record?.data?._typeLeave !== "Autorisation") {
      const daysText = record?.data?.DaysNumber + " Jour(s)";
      const halfDayText = record?.data?._is_half_day
        ? " (" + record?.data?._is_half_day + ")"
        : "";
      return daysText + halfDayText;
    } else {
      return record?.data?._duration;
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={onClose}
      width={1000}
      footer={[
        <Button key="back" type="primary" onClick={onClose}>
          Ok
        </Button>,
      ]}
    >
      <div className="mx-3">
        {!loading ? (
          <Descriptions bordered title={record?.title}>
            <Descriptions.Item span={3} label="Date de soumission">
              {dayjs(record?.data?._posted_date).format("DD/MM/YYYY HH:mm")}
            </Descriptions.Item>
            {record?.data?.fullName && (
              <Descriptions.Item span={3} label={"Demandeur"}>
                {record?.data?.fullName}
              </Descriptions.Item>
            )}

            {record?.data?._typeLeave && (
              <Descriptions.Item span={3} label={"Type de la demande"}>
                {record?.data?._typeLeave}
              </Descriptions.Item>
            )}

            <Descriptions.Item
              span={3}
              label={
                record?.data?._typeLeave !== "Autorisation"
                  ? "Date de début"
                  : "Date"
              }
            >
              {dayjs(record?.data?.BeginingDate).format("DD/MM/YYYY")}
            </Descriptions.Item>
            {record?.data?._typeLeave !== "Autorisation" && (
              <Descriptions.Item span={3} label="Date de fin">
                {dayjs(record?.data?.EndingDate).format("DD/MM/YYYY")}
              </Descriptions.Item>
            )}

            <Descriptions.Item span={3} label="Durée">
              {renderDuration()}{" "}
            </Descriptions.Item>
            {record?.data?._motif && (
              <Descriptions.Item
                span={3}
                label="Motif"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {record?.data?._motif}
              </Descriptions.Item>
            )}
            <Descriptions.Item
              span={3}
              label="Statut"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {record?.data?.status === "Pending" && (
                <Tag color="orange">En attente</Tag>
              )}
              {record?.data?.status === "Accepted" && (
                <Tag color="green">Acceptée</Tag>
              )}
              {record?.data?.status === "Rejected" && (
                <Tag color="red">Rejetée</Tag>
              )}
              {record?.data?.status === "Canceled" && (
                <Tag color="red">Annulée</Tag>
              )}
              {record.status === "Validating" && (
                <Tag color={"green"}>En attente d'approbation</Tag>
              )}
              {record.status === "Unjustified" && (
                <Tag color={"red"}>Non encore justifiée</Tag>
              )}
              {record.status === "Failed" && <Tag color={"red"}>Échue</Tag>}
            </Descriptions.Item>
            {record?.data?.OptionalFiles && (
              <Descriptions.Item span={3} label="Justification">
                <a
                  href={record?.data?.OptionalFiles ?? "#"}
                  download={
                    "Justification-Absence-" +
                    record?.data?.fullName.replace(" ", "-") +
                    "-" +
                    dayjs(record?.data?.BeginingDate).format("DD-MM-YYYY")
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Tag
                    color={"blue"}
                    style={{ fontSize: "12px", padding: "5px 10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faFileArrowDown}
                      style={{ marginRight: "5px" }}
                    />
                    {"Justification-Absence-" +
                      record?.data?.fullName.replace(" ", "-") +
                      "-" +
                      dayjs(record?.data?.BeginingDate).format("DD-MM-YYYY")}
                  </Tag>
                </a>
              </Descriptions.Item>
            )}
            {record?.data?.status === "Rejected" && (
              <Descriptions.Item
                span={3}
                label="Motif de rejet"
                style={{ whiteSpace: "pre-wrap" }}
              >
                {record?.data?.rejected_reason ?? "N/A"}
              </Descriptions.Item>
            )}
            <Descriptions.Item span={3} label="Traité par">
              {record?.data?._answered_by ?? (
                <Tag color="orange">En attente</Tag>
              )}
            </Descriptions.Item>
          </Descriptions>
        ) : (
          <div className="row">
            <Descriptions bordered title="Détails de la demande">
              <Descriptions.Item
                span={3}
                label={<Skeleton.Input active className="w-100" size="large" />}
              >
                <Skeleton.Input active className="w-100" size="large" />
              </Descriptions.Item>

              <Descriptions.Item
                span={3}
                label={<Skeleton.Input active className="w-100" size="large" />}
              >
                <Skeleton.Input active className="w-100" size="large" />
              </Descriptions.Item>

              <Descriptions.Item
                span={3}
                label={<Skeleton.Input active className="w-100" size="large" />}
              >
                <Skeleton.Input active className="w-100" size="large" />
              </Descriptions.Item>

              <Descriptions.Item
                span={3}
                label={<Skeleton.Input active className="w-100" size="large" />}
              >
                <Skeleton.Input active className="w-100" size="large" />
              </Descriptions.Item>

              <Descriptions.Item
                span={3}
                label={<Skeleton.Input active className="w-100" size="large" />}
              >
                <Skeleton.Input active className="w-100" size="large" />
              </Descriptions.Item>

              <Descriptions.Item
                span={3}
                label={<Skeleton.Input active className="w-100" size="large" />}
              >
                <Skeleton.Input active className="w-100" size="large" />
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
      </div>
    </Modal>
  );
};

LeaveDetailsDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  record: PropTypes.shape({
    title: PropTypes.string,
    status: PropTypes.string,
    data: PropTypes.shape({
      _posted_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      fullName: PropTypes.string,
      _typeLeave: PropTypes.string,
      BeginingDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      EndingDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      DaysNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      _is_half_day: PropTypes.string,
      _duration: PropTypes.string,
      _motif: PropTypes.string,
      status: PropTypes.string,
      OptionalFiles: PropTypes.string,
      rejected_reason: PropTypes.string,
      _answered_by: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
};

LeaveDetailsDrawer.defaultProps = {
  loading: false,
  record: {},
};
export default LeaveDetailsDrawer;
