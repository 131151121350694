import { Image, Descriptions, Avatar, Space, Button, Tag, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import RequestChangeModal from "../../../Components/RequestChangeModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import ChangePasswordModal from "../../../Components/ChangePasswordModal";
import ImageModal from "../../../Components/ImageModal";
import PropTypes from 'prop-types';

const GeneralProfile = ({ data, setRefresh, refrech }) => {
  const [items, setItems] = useState({});
  const [visible, setVisible] = useState(false);

  const [changeModalTrigger, setChangeModalTrigger] = useState(false);
  const [passwordTrigger, setPasswordTrigger] = useState(false);


  const handleChangeModalTrigger = (ETriggerValue) => {
    setChangeModalTrigger(ETriggerValue);
  };

  const handleClickPasswordTrigger = () => {
    setPasswordTrigger(true);
  };
  const handlePasswordTrigger = (PTriggerValue) => {
    setPasswordTrigger(PTriggerValue);
  };

  const handleClickChangeModalTrigger = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const tempItems = {
      personalInfo: [
        {
          key: "1",
          label: "Numéro CIN",
          children: data?.CIN,
          span: 1,
        },
        {
          key: "6",
          label: "Adresse",
          children: data?.address,
          span: 1,
        },
        {
          key: "4",
          label: "Date de naissance",
          children: dayjs(data?.birthDate).format("DD/MM/YYYY"),
          span: 1,
        },
        {
          key: "5",
          label: "Lieu de naissance",
          children: data?.birthPlace,
          span: 1,
        },
      ],
      urgenceInfo: [
        {
          key: "1",
          label: "Nom et Prénom",
          children: data?._urgence_name,
          span: 1,
        },
        {
          key: "2",
          label: "Relation",
          children: data?._urgence_relation,
          span: 1,
        },
        {
          key: "3",
          label: "Numéro de téléphone",
          children: data?._urgence_phone,
          span: 1,
        },
      ],
      workInfo: [
        {
          key: "2",
          label: "Matricule",
          children: data?.matricule,
          span: 1,
        },
        {
          key: "3",
          label: "Rib",
          children: data?.Rib,
          span: 1,
        },
        {
          key: "4",
          label: "Poste",
          children: data?.Poste,
          span: 1,
        },
        {
          key: "5",
          label: "Contrat",
          children: data?.contractType,
          span: 1,
        },
        {
          key: "6",
          label: "Date d'embauche",
          children: dayjs(data?.embaucheDate).format("DD/MM/YYYY"),
          span: 1,
        },
      ],
      contact: [
        {
          key: "1",
          label: "Email professionnel",
          children: data?.email,
          span: 1,
        },
        {
          key: "2",
          label: "Email personnel",
          children: data?._personal_email,
          span: 1,
        },
        {
          key: "3",
          label: "Numéro de téléphone",
          children: data?.phoneNum,
          span: 1,
        },
      ]
    };
    setItems(tempItems);
  }, [data]);

  const handleRefrech = () => {
    setRefresh(!refrech)
  }

  return (
    <>
      <RequestChangeModal
        Trigger={changeModalTrigger}
        onClose={onClose}
        TriggerModal={handleChangeModalTrigger}
        visible={visible}
        variables={data}
        drawerSize={720}
      />
      <ChangePasswordModal
        Trigger={passwordTrigger}
        TriggerModal={handlePasswordTrigger}
      />

      <div className="d-flex align-items-center pb-2 mb-2">
        {data?.imgProfile ? (
          <Image
            src={data?.imgProfile}
            width={100}
            style={{ borderRadius: "50%" }}
          />
        ) : (
          <Avatar
            style={{
              borderRadius: "50%",
              width: "100px",
              height: "100px",
              fontSize: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data?.fullName[0]}
          </Avatar>
        )}
        <div className="d-flex flex-column  mx-2">
          <Typography.Paragraph style={{ fontWeight: "500", fontSize: "14px" }}>
            Salut {data?.fullName} !
            <br />
            Ça fait déjà <Tag color="blue" style={{ margin: "0 !important", fontWeight: "700" }}>{dayjs().diff(data?.embaucheDate, "day")}</Tag><span style={{ marginLeft: "0px" }}>jours</span> que tu as rejoint l'aventure Neopolis Development ! 🚀
            <br />
            Merci pour chaque instant passé à construire ensemble quelque chose d'extraordinaire. 🌟
          </Typography.Paragraph>
          <Space>
            <Button
              shape="round"
              size="small"
              className="my-1"
              onClick={handleClickChangeModalTrigger}
              type="primary"
            >
              <FontAwesomeIcon
                icon={faUserEdit}
                style={{ paddingRight: "6px" }}
              />
              Modifier profil
            </Button>
            <Button
              className="my-1"
              shape="round"
              size="small"
              onClick={handleClickPasswordTrigger}
              type="primary"
            >
              <FontAwesomeIcon icon={faKey} style={{ paddingRight: "6px" }} />{" "}
              Modifier mot de passe
            </Button>
            <ImageModal imageSrc={data?.imgProfile} setRefresh={handleRefrech} />
          </Space>
        </div>
      </div>

      <div className=" rounded-4 px-4 py-2 my-2">
        <Descriptions
          size="small"
          title="Informations personnelles"
          layout="vertical"
          items={items?.personalInfo}
        />
      </div>
      <div className=" rounded-4 px-4 py-2 my-2">
        <Descriptions
          size="small"
          title="Informations liées au travail"
          layout="vertical"
          items={items?.workInfo}
        />
      </div>
      <div className=" rounded-4 px-4 py-2 my-2">
        <Descriptions
          size="small"
          title="Informations de contact"
          layout="vertical"
          items={items?.contact}
        />
        <div className=" rounded-4 px-4 py-2 my-2">
          <Descriptions
            size="small"
            title="Personne a prévenir en cas d'urgence"
            layout="vertical"
            items={items?.urgenceInfo}
          />
        </div>
      </div>
    </>
  );
};

GeneralProfile.propTypes = {
  data: PropTypes.shape({
    CIN: PropTypes.string,
    address: PropTypes.string,
    birthDate: PropTypes.string,
    birthPlace: PropTypes.string,
    _urgence_name: PropTypes.string,
    _urgence_relation: PropTypes.string,
    _urgence_phone: PropTypes.string,
    matricule: PropTypes.string,
    Rib: PropTypes.string,
    Poste: PropTypes.string,
    contractType: PropTypes.string,
    embaucheDate: PropTypes.string,
    email: PropTypes.string,
    _personal_email: PropTypes.string,
    phoneNum: PropTypes.string,
    imgProfile: PropTypes.string,
    fullName: PropTypes.string
  }),
  setRefresh: PropTypes.func.isRequired,
  refrech: PropTypes.bool.isRequired
};

export default GeneralProfile;
