import {
  Form,
  Skeleton,
  Switch,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";

const OtherActions = () => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchData() {
      const { data } = await axiosInstance.get("/api/platform/validations");

      setActions(data.data);
      setLoading(false);
      return data;
    }

    fetchData();
  }, []);

  const handleChange = async (action, status) => {
    try {
      const { data } = await axiosInstance.patch(
        "/api/platform/validation?name=" + action,
        { status: status }
      );

      if (data.status === "success") {
        api["success"]({
          message: data.message,
        });
      }
    } catch (error) {
      api["error"]({
        message: error.response.data.message,
      });
    }
  };

  return (
    <div className="py-2">
      {/* <h5 className="mb-3">
        <span className=" ">Autres paramètres </span>
      </h5> */}
      {contextHolder}
      {loading ? (
        <>
          <div className="row">
            <div className="col-lg-4">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
            <div className="col-lg-2">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-4">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
            <div className="col-lg-2">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
            <div className="col-lg-2">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-4">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
            <div className="col-lg-2">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <Form
            form={form}
            layout="horizontal"
            className="col-lg-6 col-md-12 col-sm-12"
          >
            <Form.Item
              label={
                <Typography.Text strong>
                  Rappel de reporting quotidien
                </Typography.Text>
              }
            >
              <Switch
                defaultChecked={
                  actions?.find((elem) => elem.name === "daily-validation")
                    ?.status
                }
                onChange={(checked) => {
                  handleChange("daily-validation", checked);
                }}
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text strong>
                  Rappel du reporting du dernier jour
                </Typography.Text>
              }
            >
              <Switch
                defaultChecked={
                  actions?.find((elem) => elem.name === "validation-d-1")
                    ?.status
                }
                onChange={(checked) => {
                  handleChange("validation-d-1", checked);
                }}
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text strong>
                  Traitement des absences de reporting
                </Typography.Text>
              }
            >
              <Switch
                defaultChecked={
                  actions?.find((elem) => elem.name === "validation-d-2")
                    ?.status
                }
                onChange={(checked) => {
                  handleChange("validation-d-2", checked);
                }}
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text strong>
                  Traitement des demandes de congé
                </Typography.Text>
              }
            >
              <Switch
                onChange={(checked) => {
                  handleChange("requests-daily-validation", checked);
                }}
                defaultChecked={
                  actions?.find(
                    (elem) => elem.name === "requests-daily-validation"
                  )?.status
                }
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text strong>
                  Traitement des tâches planifiées
                </Typography.Text>
              }
            >
              <Switch
                onChange={(checked) => {
                  // handleChange("requests-daily-validation", checked);
                }}
                defaultChecked={
                  false
                  // actions?.find(
                  //   (elem) => elem.name === "requests-daily-validation"
                  // )?.status
                }
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text strong>
                  Traitement des tâches à faires
                </Typography.Text>
              }
            >
              <Switch
                onChange={(checked) => {
                  // handleChange("requests-daily-validation", checked);
                }}
                defaultChecked={
                  false
                  // actions?.find(
                  //   (elem) => elem.name === "requests-daily-validation"
                  // )?.status
                }
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
          </Form>

        </div>
      )}
    </div>
  );
};

export default OtherActions;
