import { message, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import dayjs from "dayjs";

const LeavePrecentage = ({ selectedDate }) => {
  const [precentage, setPrecentage] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await axiosInstance.get(
          "/api/leaves/team/precentage?date=" +
          dayjs(selectedDate).format("YYYY-MM-DD")
        );

        setPrecentage(data?.leave_precentage);
        setLoading(false);
      } catch (error) {
        message.error(error.response.data.message);
      }
    }
    fetchData();
  }, [selectedDate]);

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center p-3"
      style={{
        background: "rgba(30, 203, 68, .15)",
        borderRadius: "10px",
        color: "#1ECB44",
      }}
    >
      <h1 style={{ fontWeight: "400", fontSize: "18px", textAlign: "center" }}>
        Taux de disponibilité <br /> de l'équipe 🚀
      </h1>
      <div className="row justify-content-evenly align-items-center w-100">
        <div className="col-lg-5 d-flex flex-column justify-content-center align-items-center p-2">
          {loading ? (
            <Skeleton.Button active />
          ) : (
            <span style={{ fontWeight: "600", fontSize: "28px" }}>
              {Math.round(precentage)}%
            </span>
          )}
          <span
            style={{
              fontWeight: "400",
              fontSize: "14px",
              textAlign: "center",
            }}
          >
            Disponible
          </span>
        </div>
      </div>
    </div>
  );
};

export default LeavePrecentage;
