import { Button, message, Upload } from "antd";
import PropTypes from "prop-types";

const UploadInput = ({ setFile, danger, types }) => {
  const handleUpload = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        if (file.size < 2000000) {
          if (
            file.type === "application/pdf" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "application/zip"
          ) {
            file.base64 = e.target.result;
            setFile(file.base64);
          } else {
            info = null;
            message.error(
              "Seuls les fichiers PDF, JPG, JPEG, PNG et ZIP sont autorisés"
            );
          }
        } else {
          info = null;
          message.error("Le fichier ne doit pas dépasser 2 Mo");
        }
      };
      reader.readAsDataURL(file.originFileObj);
    });
  };

  return (
    <Upload
      type="file"
      style={{ marginBottom: "10px" }}
      maxCount={1}
      className="w-100"
      listType="picture"
      onChange={handleUpload}
      accept={types}
      beforeUpload={() => false}
    >
      <Button danger={danger} className="w-100">
        Joindre le fichier
      </Button>
    </Upload>
  );
};

UploadInput.propTypes = {
  setFile: PropTypes.func.isRequired,
  danger: PropTypes.bool,
  types: PropTypes.string,
};

UploadInput.defaultProps = {
  danger: false,
  types: "application/pdf, image/jpg, image/jpeg ,image/png",
};

export default UploadInput;
