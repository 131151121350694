import { useState, useEffect, useRef, useContext } from "react";

import { SearchOutlined } from "@ant-design/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFileArrowDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Space,
  Table,
  Tag,
  Typography,
  message,
  Form,
  Modal,
  Input,
  Tooltip,
  Popconfirm,
  ConfigProvider,
  Timeline,
} from "antd";
import dayjs from "dayjs";
import Highlighter from "react-highlight-words";
import axios from "../../../Utils/axios";
import OtherRequests from "../../Components/OtherRequests";
import GlobalContext from "../../../Context/GlobalContext";
import EquipmentsRequests from "./EquipmentsRequests";
import AccessRequests from "./AccessRequests";
import TeleworkRequests from "./TeleworkRequests";
import LeaveRequestsDrawer from "../../../Drawers/LeaveRequestsDrawer";
const { confirm } = Modal;

const { Text, Paragraph } = Typography;
const { TextArea } = Input;

const RequestsList = () => {
  const { profile, redirectData, rolePrevilleges } = useContext(GlobalContext);

  const size = "default";

  const optionsTab = [
    "fullName",
    "request",
    "status",
    "motif",
    "startDate",
    "endDate",
    "duration",
    "answered_by",
  ];

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const dateRange = {};
  const currentValue = "OTHERS";

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Soumis le",
      dataIndex: "_posted_date",
      sorter: (a, b) => dayjs(a._posted_date) - dayjs(b._posted_date),
      render: (_, leave) => (
        <Text strong>
          {leave?._posted_date
            ? dayjs(leave._posted_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Text>
      ),
    },
    optionsTab.find((elem) => elem === "fullName")
      ? {
        title: "Demandeur",
        dataIndex: "fullName",
        key: "1",
        ...getColumnSearchProps("fullName", "Nom et prénom"),
        render: (_, requestedLeaves) => (
          <Text>{requestedLeaves.fullName}</Text>
        ),
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "request")
      ? {
        title: "Demande",
        dataIndex: "_typeLeave",
        filters: [
          {
            text: "Congé Special",
            value: "Congé Special",
          },
          {
            text: "Congé Maladie",
            value: "Congé Maladie",
          },
          {
            text: "Congé Annuel",
            value: "Congé annuel",
          },
          {
            text: "Congé sans sold",
            value: "Congé sans sold",
          },
          {
            text: "Télétravail",
            value: "Télétravail",
          },
          {
            text: "Absence",
            value: "Absence",
          },
        ],
        onFilter: (value, record) => record._typeLeave.startsWith(value),
        filterSearch: true,
        key: "2",
        width: 200,
        render: (_, requestedLeaves) => (
          <>
            {requestedLeaves._special_leave ? (
              <Paragraph
                style={{ margin: 0 }}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "Plus",
                }}
              >
                {requestedLeaves._typeLeave +
                  `${requestedLeaves._special_leave &&
                  " - " + requestedLeaves._special_leave
                  }`}
              </Paragraph>
            ) : (
              <Text>{requestedLeaves._typeLeave}</Text>
            )}
          </>
        ),
      }
      : { width: 0, className: "d-none" },

    optionsTab.find((elem) => elem === "startDate")
      ? {
        title: "Date de début",
        dataIndex: "BeginingDate",
        // ...getColumnSearchProps("BeginingDate"),
        key: "5",
        sorter: (a, b) => dayjs(a.BeginingDate) - dayjs(b.BeginingDate),
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "endDate")
      ? {
        title: "Date de fin",
        dataIndex: "EndingDate",
        // ...getColumnSearchProps("EndingDate"),
        key: "6",
        sorter: (a, b) => dayjs(a.EndingDate) - dayjs(b.EndingDate),
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "duration")
      ? {
        title: "Durée",
        render: (_, requestedLeaves) => (
          <Tooltip
            color="cyan"
            title={
              requestedLeaves._typeLeave === "Télétravail" ? (
                <div>
                  Sauf:
                  <br />
                  {requestedLeaves._except.map((elem) => {
                    return <Tag color="blue">{elem}</Tag>;
                  })}
                </div>
              ) : requestedLeaves._typeLeave === "Autorisation" ? (
                requestedLeaves._duration
              ) : !requestedLeaves._is_half_day ? (
                requestedLeaves.DaysNumber + " Jour(s)"
              ) : (
                requestedLeaves._is_half_day
              )
            }
          >
            <Text>
              {requestedLeaves._typeLeave === "Autorisation"
                ? requestedLeaves._duration
                : !requestedLeaves._is_half_day
                  ? requestedLeaves.DaysNumber + " Jour(s)"
                  : requestedLeaves._is_half_day}
            </Text>
          </Tooltip>
        ),
      }
      : { width: 0, className: "d-none" },

    optionsTab.find((elem) => elem === "status")
      ? {
        title: "Statut",
        dataIndex: "status",
        key: "7",
        filters: [
          {
            text: <Tag color={"orange"}>En Attente</Tag>,
            value: "Pending",
          },

          {
            text: <Tag color={"green"}>Approuvée</Tag>,
            value: "Accepted",
          },

          {
            text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
            value: "Validating",
          },

          {
            text: <Tag color={"red"}>Rejeté</Tag>,
            value: "Rejected",
          },

          {
            text: <Tag color="red">Annulée</Tag>,
            value: "Canceled",
          },

          {
            text: <Tag color={"gold"}>Non encore justifié</Tag>,
            value: "Unjustified",
          },

          {
            text: <Tag>Échue</Tag>,
            value: "Failed",
          },
        ],
        defaultFilteredValue:
          redirectData?.type === "DEFAULT" && redirectData?.filters
            ? redirectData?.filters
            : [],
        onFilter: (value, record) => record.status.includes(value),
        render: (_, record) => (
          <>
            {record.status === "Pending" && (
              <Tag color={"orange"}>En attente</Tag>
            )}
            {record.status === "Accepted" && (
              <Tag color={"green"}>Approuvée</Tag>
            )}
            {record.status === "Validating" && (
              <Tag color={"cyan"}>En attente d'approbation</Tag>
            )}
            {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
            {record.status === "Canceled" && (
              <Tag color={"geekblue"}>Annuler</Tag>
            )}
            {record.status === "Unjustified" && (
              <Tag color={"gold"}>Non encore justifié</Tag>
            )}
            {record.status === "Failed" && (
              <Tooltip title="Considérée comme absence non justifiée">
                <Tag>Échue</Tag>
              </Tooltip>
            )}
          </>
        ),
      }
      : { width: 0, className: "d-none" },

    rolePrevilleges?.previllegesList?.find(
      (elem) => elem?.code === "PROCESS_LEAVES"
    )
      ? {
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Space>
            {record.status === "Pending" && (
              <Space>
                {/* eslint-disable-next-line */}
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#52C41A",
                    },
                  }}
                >
                  <Popconfirm
                    placement="topLeft"
                    okText="Oui"
                    title="Vous êtes sûr d'approuver la demande?"
                    onConfirm={() => {
                      handleActions({
                        _id: record._id,
                        value: "Accepted",
                      });

                      const newRecord = requestedLeaves.map((RL) => {
                        if (RL._id === record._id) {
                          RL.status = "Accepted";
                        }
                        return RL;
                      });

                      setRequestedLeaves(newRecord);
                    }}
                  >
                    <Tooltip title="Approuver la demande">
                      <Button size="small" shape="circle" type="primary">
                        <FontAwesomeIcon icon={faCheck} />
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </ConfigProvider>
                {/* eslint-disable-next-line */}
                <Popconfirm
                  placement="topLeft"
                  okText="Oui"
                  title="Vous êtes sûr de rejeter la demande?"
                  onConfirm={() => {
                    Modal.info({
                      title: "Rejet de la demande",
                      content: (
                        <Form form={form} layout="vertical">
                          <Form.Item
                            name="message"
                            label="Modif de la rejet"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Champ requis.",
                              },
                            ]}
                          >
                            <TextArea />
                          </Form.Item>
                        </Form>
                      ),
                      onOk: async () => {
                        form
                          .validateFields()
                          .then(async (res) => {
                            const formValues = form.getFieldsValue();
                            await axios.patch(
                              "/api/leaves/options/" + record._id,
                              {
                                message: formValues.message,
                                status: "Rejected",
                                by: "Admin",
                                self: false,
                              }
                            );
                            const newRecord = requestedLeaves.map((RL) => {
                              if (RL._id === record._id) {
                                RL.status = "Rejected";
                              }
                              return RL;
                            });

                            setRequestedLeaves(newRecord);
                          })
                          .catch((error) => {
                            message.error("Veuillez indiquer le motif du rejet");
                            return;
                          });
                      },
                    });
                  }}
                >
                  <Tooltip title="Rejeter la demande">
                    <Button type="primary" size="small" shape="circle" danger>
                      <FontAwesomeIcon icon={faXmark} />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </Space>
            )}
            {(record.status === "Accepted" ||
              record.status === "Rejected" ||
              record.status === "Failed" ||
              record.status === "Canceled") && (
                <Tooltip title="Valider la demande">
                  <Button shape="circle" type="primary" size="small" disabled>
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                </Tooltip>
              )}
            {record.status === "Unjustified" && (
              <Tooltip title="Valider la demande">
                <Button shape="circle" type="primary" size="small" disabled>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </Tooltip>
            )}
            {record.status === "Validating" && (
              <Tooltip title="Valider la demande">
                <Button
                  shape="circle"
                  onClick={() => {
                    confirm({
                      title: "Valider la demande de " + record.fullName,
                      footer: [
                        <div className="d-flex justify-content-between align-items-center">
                          <Tag>Èchap</Tag>
                          <Space>
                            <Button
                              type="primary"
                              danger
                              onClick={() => {
                                handleActions({
                                  _id: record._id,
                                  value: "Unjustified",
                                });
                              }}
                            >
                              Rejetée
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => {
                                handleActions({
                                  _id: record._id,
                                  value: "Accepted",
                                });
                              }}
                            >
                              Approuvée
                            </Button>
                          </Space>
                        </div>,
                      ],
                      content: (
                        <div className="pb-4">
                          <Typography.Paragraph>
                            Justification:
                          </Typography.Paragraph>
                          <Space>
                            <a
                              href={record.OptionalFiles ?? "#"}
                              download={
                                "Justification-Absence-" +
                                record?.fullName.replace(" ", "-") +
                                "-" +
                                dayjs(record?.BeginingDate).format(
                                  "DD-MM-YYYY"
                                )
                              }
                              target="_blank"
                              rel="noreferrer" // Add this line
                            >
                              <Tag
                                color={"blue"}
                                style={{
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faFileArrowDown}
                                  style={{ marginRight: "5px" }}
                                />
                                {"Justification-Absence-" +
                                  record?.fullName.replace(" ", "-") +
                                  "-" +
                                  dayjs(record?.BeginingDate).format(
                                    "DD-MM-YYYY"
                                  )}
                              </Tag>
                            </a>
                          </Space>
                        </div>
                      ),
                    });
                  }}
                  type="primary"
                  size="small"
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </Tooltip>
            )}
            <LeaveRequestsDrawer record={record} />
          </Space>
        ),
      }
      : { width: 0, className: "d-none" },
  ];

  const [loading, setLoading] = useState(true);
  const [requestedLeaves, setRequestedLeaves] = useState([]);

  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/options/" + options._id, {
        status: options.value,
        by: "Admin",
        self: false,
      });
      if (data.status === "success") {
        message.info(data.message);
        const newRequestedLeaves = requestedLeaves.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
          }
          return RL;
        });
        setRequestedLeaves(newRequestedLeaves);
      }
    } catch (error) { }
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`/api/leaves/monthly/pending`);
      const temp = data?.LeavesPerMonth?.filter(
        (elem) => elem?._typeLeave !== "Télétravail"
      );
      setRequestedLeaves(temp);
      setLoading(false);
      return data;
    }
    fetchData();
  }, []);
  return (
    <div className="p-4">
      <div className="mb-5">
        <Typography.Title level={4}>⌛ Demandes en cours :</Typography.Title>
      </div>

      <Timeline>
        {profile.sub_role !== "SYS_ADMIN" && (
          <Timeline.Item>
            <h5 style={{ fontWeight: "400" }}>✈️ Congés/Autorisations</h5>
            <Table
              columns={columns}
              loading={loading}
              dataSource={requestedLeaves}
              scroll={{ x: "max-content" }}
            />
          </Timeline.Item>
        )}

        {profile.sub_role !== "SYS_ADMIN" && (
          <Timeline.Item>
            <h5 style={{ fontWeight: "400" }}>🏡 Télétravail</h5>
            <TeleworkRequests />
          </Timeline.Item>
        )}

        <Timeline.Item>
          <h5 style={{ fontWeight: "400" }}>🔑 Accès</h5>
          <AccessRequests
            getColumnSearchProps={getColumnSearchProps}
            size={size}
          />
        </Timeline.Item>
        <Timeline.Item>
          <h5 style={{ fontWeight: "400" }}>💻 Equipements</h5>
          <EquipmentsRequests
            getColumnSearchProps={getColumnSearchProps}
            size={size}
          />
        </Timeline.Item>
        {profile.sub_role !== "SYS_ADMIN" && (
          <Timeline.Item>
            <h5 style={{ fontWeight: "400" }}>📝 Autre(s)</h5>
            <OtherRequests
              dateRange={dateRange}
              currentValue={currentValue}
              size={size}
              filteredData={
                redirectData?.type !== "DEFAULT" && redirectData?.filters
                  ? redirectData.filters
                  : []
              }
            />
          </Timeline.Item>
        )}
      </Timeline>
    </div>
  );
};

export default RequestsList;
