import { Card, Empty, Timeline } from "antd";
import React from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import switchOff from "../../../Assets/switch-off.png";

const UnavailableCollab = ({ todayLeaves, status }) => {
  const Navigate = useNavigate();
  return (
    <div className="w-100 my-4   px-3 py-2 rounded-3">
      <h1 style={{ fontWeight: "500", fontSize: "14px" }}>
        Collaborateur(s) Indisponible(s)
      </h1>
      <Card
        onClick={() => {
          if (!status) Navigate("team/calendar");
        }}
        hoverable={!status}
        style={{
          height: "234px",
          borderRadius: "10px",
          overflowY: "scroll",
          padding: "10px",
        }}
      >
        {todayLeaves?.length !== 0 ? (
          <Timeline className="">
            {todayLeaves.map((leave, idx) => {
              if (
                leave._typeLeave === "Autorisation" &&
                !dayjs(leave._duration.split(" - ")[1], "HH:mm").isAfter(
                  dayjs()
                )
              ) {
                return null;
              }
              return (
                leave._typeLeave && (
                  <Timeline.Item
                    key={idx}
                    dot={
                      <img
                        alt="switch-off"
                        src={switchOff}
                        style={{ height: "30px", marginTop: "3px" }}
                      />
                    }
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginLeft: 8,
                      }}
                      className="d-flex flex-row justify-content-between align-items-center"
                    >
                      <span>{leave.fullName}</span>
                      <span
                        style={{
                          backgroundColor: leave?._is_half_day
                            ? "#FFBE0B"
                            : leave?._typeLeave === "Congé annuel"
                              ? "#1ECB44"
                              : leave?._typeLeave === "Autorisation"
                                ? "#8338EC"
                                : leave?._typeLeave === "Congé sans solde"
                                  ? "#FB0707"
                                  : leave?._typeLeave === "Congé Special"
                                    ? "#FB33FF"
                                    : "#3A86FF",
                          borderRadius: "20px",
                          color: "#FFFFFF",
                          fontSize: "12px",
                          fontWeight: "500",
                          padding: "4px 7px",
                          textAlign: "center",
                        }}
                      >
                        {leave._is_half_day
                          ? "Congé 1/2"
                          : leave._typeLeave === "Congé annuel"
                            ? "Congé"
                            : leave._typeLeave === "Autorisation"
                              ? "Autorisation"
                              : "Congé"}
                      </span>
                    </div>
                  </Timeline.Item>
                )
              );
            })}
          </Timeline>
        ) : (
          <Empty description={"Aucun collaborateur indisponible"} />
        )}
      </Card>
    </div>
  );
};

export default UnavailableCollab;
