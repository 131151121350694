import React from "react";
import EquipmentForm from "../../Components/EquipmentForm";

import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const EquipmentsManagment = () => {
  const Navigate = useNavigate();

  const handleNavigate = () => {
    Navigate("/administration/equipments/list");
  };

  return (
    <div className="p-3">
      <Button
        shape="round"
        onClick={handleNavigate}
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
      >
        Liste des équipements
      </Button>
      <div className="row justify-content-between">
        <EquipmentForm />
      </div>
    </div>
  );
};

export default EquipmentsManagment;
