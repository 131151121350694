import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  DatePicker,
  Input,
  Select,
  Spin,
  TimePicker,
  Typography,
  Tooltip,
  Collapse,
  Modal,
  Button,
  Radio,
  message,
  Tag,
  Alert,
  theme as antdTheme,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { LoadingOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import axios from "../../Utils/axios";
import dayjs from "dayjs";
import GlobalContext from "../../Context/GlobalContext";
import OnLeaveList from "./OnLeaveList";

const { TextArea } = Input;
const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { confirm } = Modal;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const LeaveForm = () => {
  const {
    token: { colorBgContainer },
  } = antdTheme.useToken();
  const { profile } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [type, setType] = useState("");
  const [reasons, setReasons] = useState([]);
  const [subReason, setSubReason] = useState([]);
  const [yearlyLeaveType, setYearlyLeaveType] = useState("Standare");
  const [leaveSession, setLeaveSession] = useState("");
  const [intervalDates, setIntervalDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [onLeaveUsers, setOnLeaveUsers] = useState([]);
  const Navigate = useNavigate();

  const options = [
    {
      label: (
        <Tag
          color={yearlyLeaveType === "Standare" ? "blue" : "default"}
          style={{ fontSize: "13px", padding: "5px 10px" }}
        >
          Journée(s) entière(s)
        </Tag>
      ),
      value: "Standare",
    },
    {
      label: (
        <Tag
          color={yearlyLeaveType === "Half" ? "blue" : "default"}
          style={{ fontSize: "13px", padding: "5px 10px" }}
        >
          Demi journée
        </Tag>
      ),
      value: "Half",
    },
  ];

  const onChange3 = ({ target: { value } }) => {
    setYearlyLeaveType(value);
  };

  const onChangeX = ({ target: { value } }) => {
    setLeaveSession(value);
  };

  const onChangeType = (e) => {
    setType(e.target.value);
    setSubReason([]);
    form.setFieldsValue({
      BeginingDate: null,
      EndingDate: null,
      _special_leave: null,
      _event: null,
      _motif: null,
    });
  };

  const onChangeReason = (value) => {
    const newSubReason = reasons.find((Reason) => Reason.label === value);
    setSubReason(newSubReason);
  };

  useEffect(() => {
    async function fetchReasons() {
      const { data } = await axios.get("/api/types");
      setReasons(data.types);
      setLoading(false);
      return data.types;
    }

    fetchReasons();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (intervalDates?.startDate && intervalDates?.endDate) {
        try {
          const { data } = await axios.get(
            `/api/platform/users/leave?startDate=${dayjs(
              intervalDates?.startDate
            ).format("YYYY-MM-DD")}&endDate=${dayjs(
              intervalDates?.endDate
            ).format("YYYY-MM-DD")}`
          );
          const temp = data?.leaves?.map((elem) => {
            return {
              fullName: elem?.fullName,
              description: `En congé de ${dayjs(elem?.BeginingDate).format(
                "DD/MM/YYYY"
              )} à ${dayjs(elem?.EndingDate).format("DD/MM/YYYY")} ${elem?._is_half_day ? "(" + elem?._is_half_day + ")" : ""
                }`,
            };
          });
          setOnLeaveUsers(temp);
        } catch (error) {
          message.error(error?.response?.data?.message);
        }
      }
    }
    fetchData();
  }, [intervalDates]);

  const [beginingDate, setBeginingDate] = useState();

  const disabledDateSickness = (current) => {
    return current && current < dayjs().add(-2, "days");
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const disabledDateAuth = (current) => {
    return current && current < dayjs().add(-1, "days");
  };

  const disabledDateHalfDay = (current) => {
    const today = dayjs();
    const isBeforeDeadline = today.isSameOrBefore(
      dayjs().hour(14).minute(0).second(0)
    );
    return (
      current &&
      current < (isBeforeDeadline ? dayjs().add(-1, "days") : dayjs())
    );
  };

  const disabledEndDate = (current) => {
    return current && current < dayjs(beginingDate).endOf("day");
  };

  function disabledTime(date, type) {
    const selectedDate = dayjs(form.getFieldValue().BeginingDate).format(
      "DD/MM/YYYY"
    );
    const currentDate = dayjs().format("DD/MM/YYYY");

    if (currentDate === selectedDate) {
      if (type === "start") {
        return {
          disabledHours: () => {
            const hours = [];
            const currentHour = dayjs().hour();

            for (let i = 0; i < currentHour + 1; i++) {
              hours.push(i);
            }

            return hours;
          },
          disabledMinutes: () => {
            const minutes = [];
            const currentHour = dayjs().hour();

            if (currentHour === 17) {
              for (let i = 0; i < 60; i++) {
                minutes.push(i);
              }
            }

            return minutes;
          },
        };
      }
      return {
        disabledHours: () => {
          const hours = [];
          const currentHour = dayjs().hour();

          for (let i = 0; i < currentHour + 2; i++) {
            hours.push(i);
          }

          return hours;
        },
        disabledMinutes: () => {
          const minutes = [];
          const currentHour = dayjs().hour();

          if (currentHour === 16) {
            for (let i = 0; i < 60; i++) {
              minutes.push(i);
            }
          }

          return minutes;
        },
      };
    } else {
      if (type === "start") {
        return {
          disabledHours: () => {
            const hours = [];

            for (let i = 0; i < 8; i++) {
              hours.push(i);
            }

            for (let i = 18; i < 24; i++) {
              hours.push(i);
            }

            return hours;
          },
        };
      }
      return {
        disabledHours: () => {
          const hours = [];

          for (let i = 0; i < 7; i++) {
            hours.push(i);
          }

          for (let i = 18; i < 24; i++) {
            hours.push(i);
          }

          return hours;
        },
      };
    }
  }

  const handlePostLeave = () => {
    form
      .validateFields()
      .then(async (res) => {
        let formValues = form.getFieldsValue();
        setDisabled(true);
        processFormValues(formValues);
        try {
          const { data } = await axios.post("/api/leaves", formValues);
          handlePostSuccess(data);
        } catch (error) {
          handlePostError(error);
        }
      })
      .catch((error) => {
        setDisabled(false);
        return error;
      });
  };

  const isHalfDayLeave = () => yearlyLeaveType === "Half";
  const isStandardLeave = () => yearlyLeaveType === "Standare";

  const handleHalfDayLeaveSettings = (formValues) => {
    if (!leaveSession) {
      message.error("Veuillez choisir la seance");
      return false;
    }
    formValues.EndingDate = formValues.BeginingDate;
    formValues.typeAnnuel = yearlyLeaveType;
    formValues.typeHalf = leaveSession;
    return true;
  };

  const handleStandardLeaveSettings = (formValues) => {
    formValues.typeAnnuel = yearlyLeaveType;
    return true;
  };

  const processFormValues = (formValues) => {
    // Handle Authorization type
    if (selectedMenu === "Autorisation") {
      formValues._typeLeave = "Autorisation";
      formValues.EndingDate = formValues.BeginingDate;
      return;
    }

    // Check if it's an annual or sick leave
    const isAnnualOrSickLeave =
      (formValues._typeLeave === "Congé annuel" ||
        formValues._typeLeave === "Congé Maladie");

    // Check if it's unpaid leave
    const isUnpaidLeave = formValues._typeLeave === "Congé sans solde";

    // Process annual or sick leave
    if (isAnnualOrSickLeave) {
      if (isHalfDayLeave()) {
        return handleHalfDayLeaveSettings(formValues);
      } else if (isStandardLeave()) {
        return handleStandardLeaveSettings(formValues);
      }
    }

    // Process unpaid leave
    if (isUnpaidLeave) {
      if (isHalfDayLeave()) {
        return handleHalfDayLeaveSettings(formValues);
      } else if (isStandardLeave()) {
        return handleStandardLeaveSettings(formValues);
      }
    }
  };

  const handlePostSuccess = (data) => {
    if (data) {
      Modal.success({
        title: "Votre demande a été soumise avec succès !",
        content: <div style={{ whiteSpace: "pre-wrap" }}>{data.message}</div>,
      });
      let temp = profile;

      if (data?.newLeave?._typeLeave === "Congé annuel") {
        temp.sold -= data?.newLeave?.DaysNumber;
      }

      setDisabled(false);
      Navigate("/manage/self");
    }
  };

  const handlePostError = (error) => {
    Modal.error({
      title: "Impossible de soumettre la demande !",
      content: (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {error.response.data.message}
        </div>
      ),
    });
    setDisabled(false);
  };

  const showConfirm = () => {
    confirm({
      title: "Important : Assurez-vous de l'approbation !",
      icon: <ExclamationCircleFilled />,
      width: 600,
      content: (
        <Typography.Paragraph>
          Merci de veiller à l'approbation de votre supérieur hiérarchique via
          la plateforme NeoLink dans les délais convenus :
          <br />
          👉 Communiquez directement avec votre supérieur.
          <br />
          👉 Utilisez la relance pour garantir le traitement rapide de votre
          demande.
          <br />
          👉 Soyez assuré qu'un rappel automatique sera également envoyé à votre
          supérieur avant l'échéance.
          <br />
          <br />
          ⚠️ <span className="text-danger fw-bold">Attention</span> : Sans
          approbation à J-1 dans les horaires de travail, votre demande sera
          automatiquement rejetée.
          <br />
          <br />
          ⚠️ <span className="text-danger fw-bold">Attention</span> : Veuillez
          noter que si malgré le rejet de votre demande, vous choisissez de vous
          absenter, cette absence sera enregistrée comme non justifiée.
          <br />
          <br />
          Une absence non justifiée peut entraîner des mesures disciplinaires
          pouvant aller jusqu'au licenciement.
          <br />
          <br /> Il est impératif de respecter les procédures établies pour
          maintenir la discipline et la conformité aux règles de l'entreprise.
        </Typography.Paragraph>
      ),
      onOk() {
        handlePostLeave();
      },
      okText: "J'accepte",
    });
  };

  const showConfirmAuth = () => {
    confirm({
      title: "Rappel d'Autorisation",
      width: 600,
      icon: <ExclamationCircleFilled className="text-warning" />,
      content: (
        <Typography.Paragraph>
          📢 Toutes les demandes d'autorisation doivent être approuvées avant
          tout départ.
          <br />
          🚫 Ne quittez pas votre poste avant d'avoir obtenu la confirmation
          officielle.
          <br />
          🕒 Respectez scrupuleusement les délais approuvés pour assurer une
          gestion efficace.
        </Typography.Paragraph>
      ),
      onOk() {
        handlePostLeave();
      },
      okText: "J'accepte",
    });
  };

  const [selectedMenu, setSelectedMenu] = useState("");

  const onChange = (e) => {
    setSelectedMenu(e.target.value);
    setType(null);
    setSubReason([]);
    form.setFieldsValue({
      BeginingDate: null,
      EndingDate: null,
      _typeLeave: null,
      _special_leave: null,
      _event: null,
      _motif: null,
    });
  };

  const showConfirmMaladie = () => {
    confirm({
      title: "Confirmation Requise",
      width: 600,
      icon: <ExclamationCircleFilled className="text-warning" />,
      content: (
        <Typography.Paragraph>
          Votre demande de congé maladie dépasse 5 jours. Veuillez noter que :
          <br />
          <br />
          📄 Vous devez fournir un certificat médical pour valider votre
          demande.
          <br />
          ‼️ Ce congé sera considéré comme un congé sans solde.
          <br />
          ⏳ Tant que le certificat n'est pas fourni, ce congé sera considéré
          comme une absence non justifiée.
          <br />
          <br />
          Souhaitez-vous continuer avec l’envoi de votre demande ?
        </Typography.Paragraph>
      ),
      onOk() {
        handlePostLeave();
      },
      okText: "J'accepte",
    });
  };

  return (
    <div className="">
      {loading ? (
        <div className="d-flex justify-content-center ">
          <Spin size="large" />
        </div>
      ) : (
        <div className="p-4 ">
          <div>
            <div className="mb-1 d-flex justify-content-between">
              <Typography.Title level={4}>
                🆕 Nouvelle demande de congé/autorisation
              </Typography.Title>
              <Button
                type="primary"
                shape="round"
                onClick={() => Navigate("/manage/self")}
              >
                Mes demandes
              </Button>
            </div>
            <Alert
              description={
                <div>
                  <div className="justify-content-center">
                    <Typography.Title level={4} style={{ marginTop: "-5px" }}>
                      <Typography.Paragraph
                        className="d-flex flex-column"
                        style={{ fontSize: "12px" }}
                      >
                        <span
                          style={{
                            fontSize: "18px",
                            fontWeight: "500",
                            marginBottom: "10px",
                          }}
                        >
                          En cas de non-validation dans les délais convenus, la
                          demande sera automatiquement rejetée
                        </span>
                        <span>
                          <ul>
                            <li>
                              Nous vous encourageons vivement à garantir le
                              suivi de vos demandes en communiquant directement
                              avec vos supérieurs.
                            </li>
                            <li>
                              De plus, utilisez la fonction "Relancer" pour
                              déclencher un rappel automatique, assurant ainsi
                              une attention accrue à votre demande.
                            </li>
                          </ul>
                        </span>
                      </Typography.Paragraph>
                    </Typography.Title>
                  </div>
                </div>
              }
              type="warning"
              showIcon
            // action={
            //   <Button size="small" style={{ border: "1px solid #FAAD14" }}>
            //     Compris
            //   </Button>
            // }
            />

            <Alert
              className="mt-2"
              description={
                <div className="">
                  <Typography.Title level={3} style={{ marginTop: "-2px" }}>
                    <Typography.Paragraph
                      className="d-flex flex-column "
                      style={{ fontSize: "12px" }}
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                          marginBottom: "10px",
                        }}
                      >
                        Optimisez le traitement de vos demandes en les
                        soumettant à l'avance
                      </span>
                      <span style={{ fontSize: "12px" }}>
                        Cette approche offre aux approbateurs le temps
                        nécessaire pour les étudier minutieusement, favorisant
                        ainsi des décisions éclairées et efficaces.
                      </span>
                    </Typography.Paragraph>
                  </Typography.Title>
                </div>
              }
              type="warning"
              showIcon
            // action={
            //   <Button size="small" style={{ border: "1px solid #FAAD14" }}>
            //     Compris
            //   </Button>
            // }
            />
            <div className="p-2 row mt-2">
              <div
                className="col-lg-6 col-md-12 col-sm-12  mt-2 rounded-3 p-3"
                style={{ backgroundColor: colorBgContainer }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  size="large"
                  disabled={disabled}
                >
                  <Form.Item label="Choisir un type">
                    <Radio.Group onChange={onChange}>
                      <Radio value="Congé">
                        <Tag
                          color={selectedMenu === "Congé" ? "blue" : "default"}
                          style={{ fontSize: "13px", padding: "5px 10px" }}
                        >
                          Congé
                        </Tag>
                      </Radio>
                      <Radio value="Autorisation">
                        <Tag
                          color={
                            selectedMenu === "Autorisation" ? "blue" : "default"
                          }
                          style={{ fontSize: "13px", padding: "5px 10px" }}
                        >
                          Autorisation
                        </Tag>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  {selectedMenu === "Congé" && (
                    <Form.Item
                      name="_typeLeave"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez remplir le champ",
                        },
                      ]}
                      label="Type de congé"
                    >
                      <Radio.Group
                        onChange={onChangeType}
                        size="large"
                        value={type}
                      >
                        <Radio className="my-1" value="Congé annuel">
                          <Tag
                            color={type === "Congé annuel" ? "blue" : "default"}
                            style={{ fontSize: "13px", padding: "5px 10px" }}
                          >
                            Annuel
                          </Tag>
                        </Radio>
                        <Radio className="my-1" value="Congé Special">
                          <Tag
                            color={
                              type === "Congé Special" ? "blue" : "default"
                            }
                            style={{ fontSize: "13px", padding: "5px 10px" }}
                          >
                            {" "}
                            Spécial
                          </Tag>
                        </Radio>
                        <Radio className="my-1" value="Congé Maladie">
                          <Tag
                            color={
                              type === "Congé Maladie" ? "blue" : "default"
                            }
                            style={{ fontSize: "13px", padding: "5px 10px" }}
                          >
                            {" "}
                            Maladie
                          </Tag>
                        </Radio>
                        <Radio className="my-1" value="Congé sans solde">
                          <Tag
                            color={
                              type === "Congé sans solde" ? "blue" : "default"
                            }
                            style={{ fontSize: "13px", padding: "5px 10px" }}
                          >
                            Sans solde
                          </Tag>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}

                  {selectedMenu === "Congé" && type === "Congé Special" && (
                    <Form.Item
                      label="Congé Special"
                      name="_special_leave"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez remplir le champ",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Choisir un Congé"
                        onChange={onChangeReason}
                      >
                        {reasons.map((Reason, index) => {
                          return (
                            <Option key={index + "l"} value={Reason.label}>
                              {Reason.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}

                  {selectedMenu === "Congé" &&
                    subReason.label ===
                    "Congés exceptionnels pour raison de famille" && (
                      <>
                        <Form.Item
                          name="_event"
                          label="Motif"
                          rules={[
                            {
                              whitespace: true,
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                        >
                          <Select placeholder="Choisir un motif">
                            {subReason.value.map((Reason) => {
                              return (
                                <Option key={Reason._id} value={Reason.label}>
                                  {Reason.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="BeginingDate"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                          label="Date de début"
                        >
                          <DatePicker
                            disabledDate={disabledDate}
                            onChange={(dateString) => {
                              setBeginingDate(dateString);
                              setIntervalDates({
                                ...intervalDates,
                                startDate: dateString,
                              });
                            }}
                            format={"DD/MM/YYYY"}
                            style={{ width: "100%" }}
                            placeholder="Date de début"
                          />
                        </Form.Item>
                        <Form.Item
                          name="EndingDate"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                          label="Date de fin:"
                        >
                          <DatePicker
                            disabledDate={disabledEndDate}
                            format={"DD/MM/YYYY"}
                            onChange={(dateString) => {
                              setIntervalDates({
                                ...intervalDates,
                                endDate: dateString,
                              });
                            }}
                            style={{ width: "100%" }}
                            placeholder="Date de fin"
                          />
                        </Form.Item>
                      </>
                    )}
                  {selectedMenu === "Congé" &&
                    (subReason.label === "Maternité" ||
                      subReason.label === "Obligations militaires ") && (
                      <>
                        <Form.Item
                          name="BeginingDate"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                          label="Date de début"
                        >
                          <DatePicker
                            disabledDate={disabledDate}
                            onChange={(dateString) => {
                              setBeginingDate(dateString);
                              setIntervalDates({
                                ...intervalDates,
                                startDate: dateString,
                              });
                            }}
                            format={"DD/MM/YYYY"}
                            style={{ width: "100%" }}
                            placeholder="Date de début"
                          />
                        </Form.Item>
                        <Form.Item
                          name="EndingDate"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez remplir le champ",
                            },
                          ]}
                          label="Date de fin:"
                        >
                          <DatePicker
                            onChange={(dateString) => {
                              setIntervalDates({
                                ...intervalDates,
                                endDate: dateString,
                              });
                            }}
                            disabledDate={disabledEndDate}
                            format={"DD/MM/YYYY"}
                            style={{ width: "100%" }}
                            placeholder="Date de fin"
                          />
                        </Form.Item>
                      </>
                    )}

                  {selectedMenu === "Autorisation" && (
                    <>
                      <Form.Item
                        name="BeginingDate"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez remplir le champ",
                          },
                        ]}
                        label="Date"
                      >
                        <DatePicker
                          disabledDate={disabledDateAuth}
                          format={"DD/MM/YYYY"}
                          style={{ width: "100%" }}
                          placeholder="Date"
                        />
                      </Form.Item>
                      <Form.Item
                        name="_duration"
                        label="Durée"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez remplir le champ",
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          format="HH:mm"
                          className="w-100"
                          disabledTime={disabledTime}
                          minuteStep={30}
                        />
                      </Form.Item>
                      <Form.Item
                        name="_motif"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez remplir le champ",
                            whitespace: true,
                          },
                        ]}
                        label="Motif"
                      >
                        <TextArea
                          placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                          autoSize={{
                            minRows: 3,
                            maxRows: 3,
                          }}
                        />
                      </Form.Item>
                    </>
                  )}

                  {selectedMenu === "Congé" &&
                    (type === "Congé annuel" ||
                      type === "Congé sans solde") && (
                      <>
                        <Form.Item label="Durée">
                          <Radio.Group
                            options={options}
                            defaultValue={"Standare"}
                            onChange={onChange3}
                            value={yearlyLeaveType}
                          />
                        </Form.Item>
                        {yearlyLeaveType === "Standare" ? (
                          <>
                            <Form.Item
                              name="BeginingDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                },
                              ]}
                              label="Date de début"
                            >
                              <DatePicker
                                disabledDate={disabledDate}
                                onChange={(dateString) => {
                                  setBeginingDate(dateString);
                                  setIntervalDates({
                                    ...intervalDates,
                                    startDate: dateString,
                                  });
                                }}
                                format={"DD/MM/YYYY"}
                                style={{ width: "100%" }}
                                placeholder="Date de début"
                              />
                            </Form.Item>
                            <Form.Item
                              name="EndingDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                },
                              ]}
                              label="Date de fin:"
                            >
                              <DatePicker
                                onChange={(dateString) => {
                                  setIntervalDates({
                                    ...intervalDates,
                                    endDate: dateString,
                                  });
                                }}
                                disabledDate={disabledEndDate}
                                format={"DD/MM/YYYY"}
                                style={{ width: "100%" }}
                                placeholder="Date de fin"
                              />
                            </Form.Item>
                            <Form.Item
                              name="_motif"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                  whitespace: true,
                                },
                              ]}
                              label="Motif"
                            >
                              <TextArea
                                placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                                autoSize={{
                                  minRows: 3,
                                  maxRows: 4,
                                }}
                                maxLength={200}
                              />
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              name="BeginingDate"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                },
                              ]}
                              label="Date"
                            >
                              <DatePicker
                                disabledDate={disabledDateHalfDay}
                                onChange={(dateString) => {
                                  setBeginingDate(dateString);
                                }}
                                format={"DD/MM/YYYY"}
                                style={{ width: "100%" }}
                                placeholder="Date de début"
                              />
                            </Form.Item>
                            <Form.Item label="Séance">
                              <Radio.Group
                                defaultValue={"X8"}
                                onChange={onChangeX}
                                value={leaveSession}
                                size="large"
                              >
                                <Radio
                                  value={"X8"}
                                  disabled={dayjs().isSame(
                                    dayjs(form.getFieldValue().BeginingDate),
                                    "day"
                                  )}
                                >
                                  <Tag
                                    color={leaveSession === "X8" ? "blue" : "default"}
                                    style={{
                                      fontSize: "13px",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    Matin
                                  </Tag>
                                </Radio>
                                <Radio value={"X12"}>
                                  <Tag
                                    color={leaveSession === "X12" ? "blue" : "default"}
                                    style={{
                                      fontSize: "13px",
                                      padding: "5px 10px",
                                    }}
                                  >
                                    Aprés midi
                                  </Tag>
                                </Radio>
                              </Radio.Group>
                            </Form.Item>

                            <Form.Item
                              name="_motif"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez remplir le champ",
                                  whitespace: true,
                                },
                              ]}
                              label="Motif"
                            >
                              <TextArea
                                placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                                autoSize={{
                                  minRows: 3,
                                  maxRows: 4,
                                }}
                                maxLength={200}
                              />
                            </Form.Item>
                          </>
                        )}
                      </>
                    )}
                  {selectedMenu === "Congé" && type === "Congé Maladie" && (
                    <>
                      <Form.Item label="Durée">
                        <Radio.Group
                          options={options}
                          defaultValue={"Standare"}
                          onChange={onChange3}
                          value={yearlyLeaveType}
                        />
                      </Form.Item>
                      {yearlyLeaveType === "Standare" ? (
                        <>
                          <Form.Item
                            name="BeginingDate"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                            label="Date de début"
                          >
                            <DatePicker
                              disabledDate={disabledDateSickness}
                              onChange={(dateString) => {
                                setBeginingDate(dateString);
                                setIntervalDates({
                                  ...intervalDates,
                                  startDate: dateString,
                                });
                              }}
                              format={"DD/MM/YYYY"}
                              style={{ width: "100%" }}
                              placeholder="Date de début"
                            />
                          </Form.Item>
                          <Form.Item
                            name="EndingDate"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                            label="Date de fin:"
                          >
                            <DatePicker
                              onChange={(dateString) => {
                                setIntervalDates({
                                  ...intervalDates,
                                  endDate: dateString,
                                });
                              }}
                              disabledDate={disabledDateSickness}
                              format={"DD/MM/YYYY"}
                              style={{ width: "100%" }}
                              placeholder="Date de fin"
                            />
                          </Form.Item>
                        </>
                      ) : (
                        <>
                          <Form.Item
                            name="BeginingDate"
                            rules={[
                              {
                                required: true,
                                message: "Veuillez remplir le champ",
                              },
                            ]}
                            label="Date"
                          >
                            <DatePicker
                              disabledDate={disabledDateSickness}
                              onChange={(dateString) => {
                                setBeginingDate(dateString);
                              }}
                              format={"DD/MM/YYYY"}
                              style={{ width: "100%" }}
                              placeholder="Date de début"
                            />
                          </Form.Item>
                          <Form.Item label="Séance">
                            <Radio.Group
                              defaultValue={"X8"}
                              onChange={onChangeX}
                              value={leaveSession}
                              size="large"
                            >
                              <Radio
                                value={"X8"}
                                disabled={dayjs().isSame(
                                  dayjs(form.getFieldValue().BeginingDate),
                                  "day"
                                )}
                              >
                                <Tag
                                  color={leaveSession === "X8" ? "blue" : "default"}
                                  style={{
                                    fontSize: "13px",
                                    padding: "5px 10px",
                                  }}
                                >
                                  Matin
                                </Tag>
                              </Radio>
                              <Radio value={"X12"}>
                                <Tag
                                  color={leaveSession === "X12" ? "blue" : "default"}
                                  style={{
                                    fontSize: "13px",
                                    padding: "5px 10px",
                                  }}
                                >
                                  Aprés midi
                                </Tag>
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </>
                      )}

                      <Form.Item>
                        <Text type="danger" strong style={{ fontSize: "14px" }}>
                          Vous êtes tenus de joindre un justificatif à votre
                          demande dans un délai de 48 heures.{" "}
                          <Tooltip title="Consulter la rubrique 'Mes demandes' pour joindre un justificatif">
                            <FontAwesomeIcon
                              className="text-black-50"
                              icon={faExclamationCircle}
                            />
                          </Tooltip>
                        </Text>
                      </Form.Item>
                    </>
                  )}

                  <div>
                    <Button
                      onClick={() => {
                        if (selectedMenu === "Autorisation") {
                          showConfirmAuth();
                        } else if (
                          type === "Congé Maladie" &&
                          dayjs(intervalDates?.endDate).diff(
                            intervalDates?.startDate,
                            "day"
                          ) > 5
                        ) {
                          showConfirmMaladie();
                        } else {
                          showConfirm();
                        }
                      }}
                      shape="round"
                      disabled={disabled || !selectedMenu}
                      type="primary"
                    >
                      Soumettre !
                    </Button>

                    {disabled && (
                      <Spin
                        style={{ marginLeft: "10px" }}
                        size="large"
                        indicator={antIcon}
                      />
                    )}
                  </div>
                </Form>
              </div>

              {onLeaveUsers?.length > 0 && selectedMenu === "Congé" ? (
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <OnLeaveList data={onLeaveUsers} />{" "}
                </div>
              ) : (
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <h5 className="fw-bold py-2" style={{ color: "#2E79A8" }}>
                    Rèférences issues de la convention collective
                  </h5>
                  <Collapse
                    className="shadow-md rounded"
                    defaultActiveKey={["1"]}
                  >
                    {type === "Congé annuel" && (
                      <Panel
                        header={
                          <Text strong style={{ color: "#2E79A8" }}>
                            Article 34: Congés payés
                          </Text>
                        }
                        key="1"
                      >
                        <p style={{ fontSize: "14px" }}>
                          Tout salarié en activité a droit annuellement à un
                          congé payé proportionnel à la durée effective du
                          travail. La durée du congé annuel payé, correspondant
                          à une année de travail effectif, est ainsi fixée :
                          <ul>
                            <li>
                              21 jours calendaires pour le personnel d’exécution
                              auxquels s’ajoute un jour de congé par tranche de
                              cinq années d’ancienneté dans l’établissement,
                              sans que la durée totale du congé dépasse un mois
                              calendaire. Cette ancienneté est calculée à partir
                              du 1er mai 2000.
                            </li>
                            <li>
                              1 mois calendaire pour les agents de maîtrise et
                              les cadres.
                            </li>
                          </ul>
                          La période de congé annuel est fixée par le règlement
                          intérieur de l’établissement. Toutefois, en cas de
                          force majeure, le travailleur pourrait, sur sa
                          demande, obtenir que tout ou partie de son congé lui
                          soit accordé à tout autre dayjs.
                          <br />
                          <br />
                          La date de départ en congé est fixée d’un commun
                          accord entre l’employeur et les intéressés, compte
                          tenu des besoins du service, de la situation de
                          famille et de l’ancienneté du bénéficiaire.
                          <br />
                          <br />
                          L’ordre des départs en congé doit être affiché 15
                          jours au moins avant sa date d’entrée en application.
                          <br />
                          <br />
                          Le travailleur perçoit pendant son congé une indemnité
                          calculée sur la base de la durée de congé à laquelle
                          il a droit, d’une part et de l’intégralité du
                          traitement et des indemnités qu’il perçoit
                          habituellement pendant l’exercice effectif du travail,
                          d’autre part.
                          <br />
                          <br />
                          N’entrent pas en ligne de compte dans le calcul de la
                          période de congé payé :
                          <ol>
                            <li>Les jours fériés,</li>
                            <li>
                              Les journées d’interruption de travail pour causes
                              de maladie ou accident
                            </li>
                          </ol>
                        </p>
                      </Panel>
                    )}
                    {type === "Congé sans solde" && (
                      <Panel
                        header={
                          <Text strong style={{ color: "#2E79A8" }}>
                            Article 40 : Congé sans solde
                          </Text>
                        }
                        key="1"
                      >
                        <p style={{ fontSize: "14px" }}>
                          Un congé sans solde pourra être accordé par
                          l’employeur à tout travailleur qui en ferait la
                          demande, dans la limite des nécessités de service.
                          <br />
                          La durée de ce congé qui porte interruption du droit à
                          l’avancement et aux versements effectués aux
                          organismes d’assurances sociales, ne pourra excéder 90
                          jours par an, sauf dispositions contractuelles plus
                          favorables.
                        </p>
                      </Panel>
                    )}
                    {subReason.label ===
                      "Congés exceptionnels pour raison de famille" && (
                        <Panel
                          header={
                            <Text strong style={{ color: "#2E79A8" }}>
                              Article 35: Congés exceptionnels pour raison de
                              famille
                            </Text>
                          }
                          key="1"
                        >
                          <p style={{ fontSize: "14px" }}>
                            Le travailleur bénéficie de congés avec maintien
                            intégral de tous les éléments du salaire à l’occasion
                            d’évènements survenus dans la famille.
                            <br />
                            La durée de ces congés est fixée comme suit :
                            <ul>
                              <li>naissance d’un enfant : 2 jours ouvrables</li>
                              <li>décès du conjoint : 3 jours ouvrables</li>
                              <li>
                                décès d’un père, d’une mère ou d’un fils : 3 jours
                                ouvrables
                              </li>
                              <li>
                                décès d’un frère, d’une sœur, d’un petit-fils ou
                                d’une petite- fille, d’un grand père ou d’une
                                grande mère : 2 jours ouvrables
                              </li>
                              <li>mariage du travailleur : 3 jours ouvrables</li>
                              <li>mariage d’un enfant : 1 jour ouvrable</li>
                              <li>circoncision d’un enfant : 1 jour ouvrable</li>
                            </ul>
                            Le bénéficiaire de ces congés doit produire les
                            justificatifs de ces évènements dans un délai de 10
                            jours
                          </p>
                        </Panel>
                      )}
                    {subReason.label === "Maternité" && (
                      <Panel
                        header={
                          <Text strong style={{ color: "#2E79A8" }}>
                            Article 36: Congés de Maternité
                          </Text>
                        }
                        key="1"
                      >
                        <p style={{ fontSize: "14px" }}>
                          A l’occasion de son accouchement et sur production
                          d’un certificat médical le justifiant, la femme a
                          droit à un congé de repos de 30 jours. Ce congé peut
                          être prorogé chaque fois d’une période de 15 jours
                          après production de certificats médicaux.
                          <br />
                          <br />
                          La suspension du travail par la femme pendant la
                          période qui précède et suit l’accouchement ne peut
                          être, pour l’employeur, une cause de rupture du
                          contrat de travail sauf si elle se prolonge sans motif
                          valable au-delà de la période légale de congé de
                          maternité telle que définie à l’alinéa 1er du présent
                          article ; sinon l’employeur est astreint à payer des
                          dommages intérêts.
                          <br />
                          <br />
                          De même, la maladie de la femme du fait de la
                          grossesse ou de l’accouchement, étant soumise au
                          régime du congé de maladie ne produit les effets
                          énoncés dans l’alinéa cidessus que si elle prolonge au
                          de-là des limites fixées par les dispositions légales
                          en matière de congé de maladie ou de maladie de longue
                          durée.
                          <br />
                          <br />
                          Dans tous les cas, la femme qui allaite son enfant
                          aura droit, pendant une année à compter du jour de la
                          naissance, à deux repos d’une demi-heure chacun durant
                          les heures du travail. L’un est fixé pendant la séance
                          du matin, l’autre pendant la séance de l’après midi et
                          ce en accord avec l’employeur.
                          <br />A défaut d’accord, ces repos sont accordés au
                          milieu de chaque séance. Ces repos sont considérés
                          comme heures de travail et ouvrent droit à
                          rémunération
                          <br />
                          <br />
                          La travailleuse qui allaite son enfant est tenue de
                          présenter un certificat médical prouvant l’allaitement
                          naturel
                        </p>
                      </Panel>
                    )}
                    {selectedMenu === "Autorisation" && (
                      <Panel
                        header={
                          <Text strong style={{ color: "#2E79A8" }}>
                            Article 27 : Absence
                          </Text>
                        }
                        key="1"
                      >
                        <p style={{ fontSize: "14px" }}>
                          Aucune absence n’est tolérée sans l’autorisation
                          préalable de l’employeur.
                          <br />
                          <br />
                          L’employeur sera avisé des absences résultant d’un cas
                          fortuit le plus rapidement possible et au plus tard
                          dans les deux jours ouvrables consécutifs à l’absence
                          (48 heures).
                          <br />
                          <br />
                          Dans le cas d’absence prévisible, le travailleur ne
                          pourra s’absenter qu’après avoir obtenu au préalable
                          l’autorisation de l’employeur.
                          <br />
                          <br />
                          En cas d’absence du travailleur sans aucun motif et
                          sans présenter aucune pièce prouvant le motif de cette
                          absence, la direction de l’entreprise lui adresse une
                          lettre recommandée dans un délai de deux jours
                          ouvrables consécutifs à l’absence (48 heures). Si le
                          travailleur ne justifie pas son absence dans un délai
                          ne dépassant pas quinze jours ouvrables, il est
                          considéré démissionnaire de sa propre volonté.
                          <br />
                          <br />
                          Le travailleur est tenu d’informer l’employeur de tout
                          changement de son adresse.
                        </p>
                      </Panel>
                    )}
                    {type === "Congé Maladie" && (
                      <Panel
                        header={
                          <Text strong style={{ color: "#2E79A8" }}>
                            Article 38: Congés de maladie
                          </Text>
                        }
                        key="1"
                      >
                        <p style={{ fontSize: "14px" }}>
                          Le travailleur atteint d’une incapacité de travail par
                          suite de maladie est placé dans la position de congé
                          de maladie à condition qu’il fournisse dans les 48
                          heures, un certificat médical précisant la nature de
                          la maladie et sa durée probable.
                          <br />
                          <br />
                          L’employeur se réserve le droit de faire effectuer à
                          domicile du travailleur malade tout contrôle médical
                          qu’il juge utile.
                          <br />
                          <br />
                          Sera exclu du bénéfice des dispositions du 1er alinéa
                          du présent article tout travailleur qui :
                          <ul>
                            <li>
                              Interrompt son travail pour des raisons qui
                              résultent soit de son intempérance ou de son
                              inconduite, soit de blessures subies en dehors du
                              travail s’il est reconnu fautif
                            </li>
                            <li>
                              N’observe pas les prescriptions médicales ou
                              s’absente de son domicile sans autorisation du
                              médecin.
                            </li>
                            <li>
                              Étant malade, se livre à un autre travail rémunéré
                              ou non.
                            </li>
                            <li>
                              Prolonge la cessation du travail au-delà du délai
                              prescrit par les médecins. Il est alors considéré
                              comme étant en absence injustifiée et passible, à
                              ce titre, de sanctions disciplinaires.
                            </li>
                          </ul>
                          Le travailleur placé en position de congé de maladie,
                          bénéficie éventuellement, d’un régime complémentaire,
                          en sus du bénéfice des dispositions prévues par le
                          règlement de la Caisse Nationale de Sécurité Sociale.
                          Ce régime complémentaire est prévu par l’article 48 de
                          la présente convention
                        </p>
                      </Panel>
                    )}
                    {subReason.label === "Obligations militaires " && (
                      <Panel
                        header={
                          <Text strong style={{ color: "#2E79A8" }}>
                            Article 39: Congé pour obligations militaires
                          </Text>
                        }
                        key="1"
                      >
                        <p style={{ fontSize: "14px" }}>
                          Les travailleurs effectuant leur service militaire
                          légal seront considérés dans la position « sous
                          drapeaux » et sans solde, mais ils conservent leurs
                          droits à l’ancienneté et à l’avancement. Ils sont
                          réintégrés de plein droit dans leur catégorie, à leur
                          libération ou, en cas de maladie, dés l’envoi d’un
                          certificat médical.
                          <br />
                          <br />
                          Ils ont priorité pour être affectés aux postes qu’ils
                          occupaient avant leur départ pour l’armée.
                        </p>
                      </Panel>
                    )}
                  </Collapse>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaveForm;
