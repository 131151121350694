import CalendarMonth from "./CalendarMonth";
import CalendarDay from "./CalendarDay";
import Filters from "./Filters";
import axiosInstance from "../../Utils/axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Dropdown, Typography, message, theme } from "antd";
import { DownOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import LeavePrecentage from "./LeavePrecentage";
import MiniCalendar from "./MiniCalendar";

const TeamCalendar = () => {
  const [filters, setFilters] = useState([
    "Autorisation",
    [
      "Congé Maladie",
      "Congé sans solde",
      "Congé Special",
      "Congé annuel",
      "Congé demi-journée",
    ],
  ]);
  const [selectedDate, setSelectedDate] = useState();
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const mode = "MONTH";
  const Navigate = useNavigate();
  const {
    token: { colorBorderSecondary },
  } = theme.useToken();

  useEffect(() => {
    if (data?.length > 0) {
      // eslint-disable-next-line
      let temp = data?.filter((elem) => {
        if (elem?._is_half_day) {
          if (filters?.includes("Congé demi-journée")) {
            return elem;
          }
        } else if (filters?.includes(elem._typeLeave)) {
          return elem;
        }
      });
      setDisplayedData(temp);
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    axiosInstance
      .get("/api/leaves/team/calendar")
      .then((res) => {
        const temp = res.data.map((elem) => {
          elem.BeginingDate = dayjs(elem.BeginingDate).toDate();
          elem.EndingDate = dayjs(elem.EndingDate).add("23", "hour").toDate();
          return elem;
        });

        setData(temp);
        setDisplayedData(temp);
      })

      .catch((err) => {
        message.error(
          err?.response?.data?.message || "Something went so wrong !"
        );
        Navigate("/");
      });
    // eslint-disable-next-line
  }, []);

  function headerRender(value, mode) {
    if (mode === "year") {
      return null;
    } else {
      return <div style={{ textAlign: "center", padding: "10px 0" }}></div>;
    }
  }

  const items = [
    {
      key: "1",
      label: <Filters filters={filters} setFilters={setFilters} />,
    },
  ];

  return (
    <div>
      <div className="  p-4 w-100 h-100">
        <div className="d-flex justify-content-between align-items-center">
          <Typography.Title level={4}>
            📆 Calendrier de l'équipe
          </Typography.Title>
        </div>

        <div className="h-100 w-100 row flex-row flex-lg-row flex-md-column-reverse">
          <div className="col-lg-9 d-none d-lg-block d-md-block">
            <CalendarMonth
              data={displayedData}
              selectedDate={selectedDate}
              headerRender={headerRender}
            />
          </div>
          <div className="col-lg-3 ">
            <LeavePrecentage selectedDate={selectedDate} />
            <div className="py-3">
              <MiniCalendar
                onSelect={setSelectedDate}
                headerRender={headerRender}
              />
            </div>
            <div className="d-none d-lg-block d-md-block ">
              <Filters filters={filters} setFilters={setFilters} />
            </div>
          </div>
          <div>
            <Dropdown.Button
              className="d-block d-lg-none d-md-none d-flex my-3 justify-content-end "
              type="primary"
              menu={{ items }}
              icon={<DownOutlined />}
              placement="bottomLeft"
              trigger={["click"]}
            >
              Filtrer par
            </Dropdown.Button>
          </div>
        </div>
        <div className="d-block d-lg-none d-md-none">
          <CalendarDay
            data={displayedData}
            singleDate={selectedDate ? selectedDate : 0}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamCalendar;
