import { Card, Empty, Timeline, Typography } from "antd";
import React from "react";
import dayjs from "dayjs";
import house from "../../../Assets/house.png";
import PropTypes from 'prop-types';

const TeleworkCollab = ({ todayRemote }) => {
  return (
    <div className="w-100 my-4    px-3 py-2 rounded-3">
      <h1 style={{ fontWeight: "500", fontSize: "14px" }}>
        Collaborateur(s) en télétravail
      </h1>
      <Card
        style={{
          height: "234px",
          borderRadius: "10px",
          overflowY: "scroll",
          padding: "10px",
        }}
      >
        {todayRemote?.length !== 0 ? (
          <Timeline>
            {todayRemote.map((leave, idx) => {
              return (
                leave?._except?.find(
                  (val) => val === dayjs().format("dddd")
                ) === undefined && (
                  <Timeline.Item
                    key={idx}
                    dot={
                      <img
                        src={house}
                        alt="house"
                        style={{ height: "20px", marginTop: "-3px" }}
                      />
                    }
                  >
                    <Typography.Text strong>{leave.fullName}</Typography.Text>
                  </Timeline.Item>
                )
              );
            })}
          </Timeline>
        ) : (
          <Empty description={"Aucun collaborateur en télétravail"} />
        )}
      </Card>
    </div>
  );
};

TeleworkCollab.propTypes = {
  todayRemote: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      _except: PropTypes.arrayOf(PropTypes.string)
    })
  )
};

TeleworkCollab.defaultProps = {
  todayRemote: []
};

export default TeleworkCollab;
