import React, { useEffect, useState } from "react";
import {
  Button,
  Descriptions,
  Drawer,
  Image,
  Space,
  Spin,
  Tag,
  message,
} from "antd";
import dayjs from "dayjs";
import axiosInstance from "../Utils/axios";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
const InvitedUserDrawer = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [invitedUserData, setInvitedUserData] = useState({});
  const [loading, setLoading] = useState(true);

  const showDrawer = () => {
    setOpen(true);
    setClicked(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function fetchInvitedUserData() {
      try {
        const { data } = await axiosInstance.get(
          "/api/employees/invited/user/" + record?.matricule
        );
        setInvitedUserData({
          contract: data?.contract,
          access: data?.access ? data?.access : null,
          account: data?.account ? data?.account : null,
        });
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    if (clicked) fetchInvitedUserData();

    // eslint-disable-next-line
  }, [clicked]);
  return (
    <>
      <Button size="small" onClick={showDrawer}>
        Consulter
      </Button>
      <Drawer
        title={record?.fullName}
        placement="right"
        onClose={onClose}
        open={open}
        size={"large"}
      >
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <div className="border-bottom pb-3 mb-3 d-flex">
              <Image
                src={record?.imgProfile}
                width={130}
                style={{ borderRadius: "50%" }}
              />
              <Space direction="vertical" className="mx-3">
                <a href={record?.CV} download={"CV_" + record?.fullName}>
                  <Tag
                    color={"blue"}
                    style={{ fontSize: "12px", padding: "5px 10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faFileArrowDown}
                      style={{ marginRight: "5px" }}
                    />
                    {"CV_" + record?.fullName}
                  </Tag>
                </a>
                <a
                  href={record?._rib_File}
                  download={"RIB_" + record?.fullName}
                >
                  <Tag
                    color={"blue"}
                    style={{ fontSize: "12px", padding: "5px 10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faFileArrowDown}
                      style={{ marginRight: "5px" }}
                    />
                    {"RIB_" + record?.fullName}
                  </Tag>
                </a>
                <a
                  href={record?._cin_File}
                  download={"CIN_" + record?.fullName}
                >
                  <Tag
                    color={"blue"}
                    style={{ fontSize: "12px", padding: "5px 10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faFileArrowDown}
                      style={{ marginRight: "5px" }}
                    />
                    {"CIN_" + record?.fullName}
                  </Tag>
                </a>
              </Space>
            </div>
            <Descriptions title="Information Utilisateur" layout="vertical">
              <Descriptions.Item span={3} label="Poste">
                {record?.Poste}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Matricule">
                {record?.matricule}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Email personnel">
                {record?._personal_email}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Numéro de télèphone">
                {record?.phoneNum}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="CIN">
                {record?.CIN}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Rib">
                {record?.Rib}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Date de naissance">
                {dayjs(record?.birthDate).format("DD/MM/YYYY")}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Lieu de naissance">
                {record?.birthPlace}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="Adresse">
                {record?.address}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              title="Personne à prévenir en cas d'urgence"
              layout="vertical"
            >
              <Descriptions.Item span={2} label="Nom Complet">
                {record?._urgence_name}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Numéro de télèphone">
                {record?._urgence_phone}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Relation">
                {record?._urgence_relation}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Contrat" layout="vertical">
              <Descriptions.Item span={3} label="Type du contrat">
                {invitedUserData?.contract?.contractType}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Date d'embauche">
                {dayjs(invitedUserData?.contract?.embaucheDate).format(
                  "DD/MM/YYYY"
                )}
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Date de fin">
                {invitedUserData?.contract?.endContractDate
                  ? dayjs(invitedUserData?.contract?.endContractDate).format(
                    "DD/MM/YYYY"
                  )
                  : "N/A"}
              </Descriptions.Item>
            </Descriptions>
            {invitedUserData?.contract?.trail && (
              <Descriptions title="Period d'essai" layout="vertical">
                <Descriptions.Item span={2} label="Date de début">
                  {invitedUserData?.contract?.trail?.startDate
                    ? dayjs(invitedUserData?.contract?.trail?.startDate).format(
                      "DD/MM/YYYY"
                    )
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item span={2} label="Date de fin">
                  {invitedUserData?.contract?.trail?.endDate
                    ? dayjs(invitedUserData?.contract?.trail?.endDate).format(
                      "DD/MM/YYYY"
                    )
                    : "N/A"}
                </Descriptions.Item>
              </Descriptions>
            )}
            {invitedUserData?.access && (
              <Descriptions title="Accès utilisateur">
                <Descriptions.Item span={3} label="GitLab">
                  {invitedUserData?.access?.git?.email ? (
                    <Space>
                      <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                      {invitedUserData?.access?.git?.email}
                    </Space>
                  ) : (
                    "N/A"
                  )}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="ActiveCollab">
                  {invitedUserData?.access?.activeCollab?.email ? (
                    <Space>
                      <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                      {invitedUserData?.access?.activeCollab?.email}
                    </Space>
                  ) : (
                    "N/A"
                  )}
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Email Pro">
                  {invitedUserData?.account?.email ? (
                    <Space>
                      <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
                      {invitedUserData?.account?.email}
                    </Space>
                  ) : (
                    "N/A"
                  )}
                </Descriptions.Item>
              </Descriptions>
            )}
          </div>
        )}
      </Drawer>
    </>
  );
};

InvitedUserDrawer.propTypes = {
  record: PropTypes.shape({
    matricule: PropTypes.string,
    fullName: PropTypes.string,
    imgProfile: PropTypes.string,
    CV: PropTypes.string,
    _rib_File: PropTypes.string,
    _cin_File: PropTypes.string,
    Poste: PropTypes.string,
    _personal_email: PropTypes.string,
    phoneNum: PropTypes.string,
    CIN: PropTypes.string,
    Rib: PropTypes.string,
    birthDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    birthPlace: PropTypes.string,
    address: PropTypes.string,
    _urgence_name: PropTypes.string,
    _urgence_phone: PropTypes.string,
    _urgence_relation: PropTypes.string,
  }).isRequired,
};
export default InvitedUserDrawer;
