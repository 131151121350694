import { Drawer, Tag, Timeline, Typography } from "antd";
import dayjs from "dayjs";
import { useState } from "react";

const EquipmentHistoryDrawer = ({ timeLine }) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography.Text
        style={{ cursor: "pointer" }}
        onClick={showDrawer}
        size="small"
      >
        Historique
      </Typography.Text>

      <Drawer
        title="Historique de l'équipement"
        placement={"right"}
        closable={false}
        onClose={onClose}
        open={open}
        size="large"
        key={"left-drawer-history"}
      >
        <Timeline>
          {timeLine.map((elem) => {
            return (
              <Timeline.Item>
                <Typography.Text>
                  {elem._action}.
                  <br />
                  {elem._end_date === elem._start_date ? "Le " : "De "}
                  <Tag color="blue">
                    {dayjs(elem._start_date).format("DD/MM/YYYY")}
                  </Tag>
                  {elem._end_date ? (
                    elem._end_date === elem._start_date ? (
                      <></>
                    ) : (
                      <>
                        jusqu'a{" "}
                        <Tag color="blue">
                          {dayjs(elem._end_date).format("DD/MM/YYYY")}
                        </Tag>
                      </>
                    )
                  ) : (
                    <>
                      jusqu'a <Tag color="orange">Recording</Tag>
                    </>
                  )}
                </Typography.Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Drawer>
    </>
  );
};
export default EquipmentHistoryDrawer;
