import React from "react";
import { Calendar } from "antd";
import dayjs from "dayjs";
import PlusModal from "./PlusModal";
import PropTypes from "prop-types";

const CalendarMonth = ({ headerRender, data, selectedDate }) => {
  const getLeaveByDate = (value) => {
    // eslint-disable-next-line
    const returnedData = data?.filter((leave) => {
      if (
        dayjs(leave.BeginingDate).isBefore(dayjs(value)) &&
        dayjs(value).isBefore(dayjs(leave.EndingDate))
      ) {
        return leave;
      }
    });
    return returnedData || [];
  };

  const dateCellRender = (value) => {
    const leaves = getLeaveByDate(value);
    const dayOfWeek = value.day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return null; // Si la cellule est un samedi ou un dimanche, ne pas afficher de congé
    }
    return (
      <>
        {/* eslint-disable-next-line */}
        {leaves.map((elem, idx) => {
          if (idx < 2) {
            return (
              <div
                className="w-100  overflow-hidden"
                style={{
                  marginBottom: 2,
                  borderLeft: "3px solid grey",
                  paddingLeft: "7px",
                  borderColor: elem?._is_half_day
                    ? "#FFBE0B"
                    : elem?._typeLeave === "Congé annuel"
                      ? "#1ECB44"
                      : elem?._typeLeave === "Autorisation"
                        ? "#8338EC"
                        : elem?._typeLeave === "Congé sans solde"
                          ? "#FB0707"
                          : elem?._typeLeave === "Congé Special"
                            ? "#FB33FF"
                            : "#3A86FF",
                }}
                key={idx}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {capitalizeFirstLetter(elem?.fullName)}
                </span>
              </div>
            );
          }
        })}
        {leaves?.length > 2 && <PlusModal data={leaves} date={value} />}
      </>
    );
  };

  const capitalizeFirstLetter = (str) => {
    const capitalized = str[0].toUpperCase() + str.slice(1).toLowerCase();
    return capitalized;
  };
  return (
    <Calendar
      className="rounded-3"
      // validRange={[dayjs().startOf("month"), dayjs().endOf("month")]}
      headerRender={headerRender}
      value={selectedDate}
      dateCellRender={dateCellRender}
    />
  );
};

CalendarMonth.propTypes = {
  headerRender: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      BeginingDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      EndingDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      fullName: PropTypes.string,
      _is_half_day: PropTypes.bool,
      _typeLeave: PropTypes.string
    })
  ),
  selectedDate: PropTypes.object
};

CalendarMonth.defaultProps = {
  data: [],
  selectedDate: dayjs()
};


export default CalendarMonth;
