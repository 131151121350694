import { Modal, Table, Tag, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import PropTypes from "prop-types";

const SoldIncrementationPopupDetails = ({
    isModalOpen,
    setIsModalOpen,
    recordItem,
}) => {
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: "",
            dataIndex: "fullname",
            render: (_, record) => (
                <Typography.Text strong>
                    {record.fullname}{" "}
                    <Typography.Text mark>#{record.matricule}</Typography.Text>
                </Typography.Text>
            ),
        },
        {
            title: "S.initial",
            dataIndex: "startOfMonthLeaveBalance",
        },
        {
            title: "Jours non travaillés",
            children: [
                {
                    title: "J.Fériés",
                    dataIndex: "holidays",
                    render: (text) => (
                        <Typography.Text>
                            <Tag color="cyan">
                                <span>{text}</span> Jour(s)
                            </Tag>
                        </Typography.Text>
                    ),
                },
                {
                    title: "Cong.S",
                    dataIndex: "special",
                    render: (_, record) => (
                        <>
                            {record.special === 0 && (
                                <Tag color="green">
                                    <span>{record.special}</span> Jour(s)
                                </Tag>
                            )}
                            {record.special > 0 && record.special <= 2 && (
                                <Tag color="gold">
                                    <span>{record.special}</span> Jour(s)
                                </Tag>
                            )}
                            {record.special >= 3 && (
                                <Tag color="red">
                                    <span>{record.special}</span> Jour(s)
                                </Tag>
                            )}
                        </>
                    ),
                },
                {
                    title: "Cong.A",
                    dataIndex: "yearly",
                    render: (text) => (
                        <Tag color="cyan">
                            <span>{text}</span> Jour(s)
                        </Tag>
                    ),
                },
                {
                    title: "Cong.SS",
                    dataIndex: "sansSold",
                    render: (text) => (
                        <Tag color="cyan">
                            <span>{text}</span> Jour(s)
                        </Tag>
                    ),
                },
                {
                    title: "Cong.M",
                    dataIndex: "sickness",
                    render: (_, record) => (
                        <>
                            {record.sickness === 0 && (
                                <Tag color="green">
                                    <span>{record.sickness}</span> Jour(s)
                                </Tag>
                            )}
                            {record.sickness > 0 && record.sickness <= 2 && (
                                <Tag color="gold">
                                    <span>{record.sickness}</span> Jour(s)
                                </Tag>
                            )}
                            {record.sickness >= 3 && (
                                <Tag color="red">
                                    <span>{record.sickness}</span> Jour(s)
                                </Tag>
                            )}
                        </>
                    ),
                },
                {
                    title: "Absence",
                    dataIndex: "unjustified",
                    render: (_, record) => (
                        <>
                            {record.unjustified === 0 && (
                                <Tag color="green">
                                    <span>{record.unjustified}</span> Jour(s)
                                </Tag>
                            )}
                            {record.unjustified > 0 && record.unjustified <= 2 && (
                                <Tag color="gold">
                                    <span>{record.unjustified}</span> Jour(s)
                                </Tag>
                            )}
                            {record.unjustified >= 3 && (
                                <Tag color="red">
                                    <span>{record.unjustified}</span> Jour(s)
                                </Tag>
                            )}
                        </>
                    ),
                },
                {
                    title: "Total",
                    dataIndex: "absenceDaysDisplay",
                    render: (_, record) => (
                        <>
                            {record.absenceDaysDisplay === 0 && (
                                <Tag color="green">
                                    <span className="fw-bold">{record.absenceDaysDisplay}</span>{" "}
                                    Jour(s)
                                </Tag>
                            )}
                            {record.absenceDaysDisplay > 0 &&
                                record.absenceDaysDisplay <= 3 && (
                                    <Tag color="gold">
                                        <span className="fw-bold">{record.absenceDaysDisplay}</span>{" "}
                                        Jour(s)
                                    </Tag>
                                )}
                            {record.absenceDaysDisplay > 3 && (
                                <Tag color="red">
                                    <span className="fw-bold">{record.absenceDaysDisplay}</span>{" "}
                                    Jour(s)
                                </Tag>
                            )}
                        </>
                    ),
                },
            ],
        },
        {
            title: (
                <Typography.Text style={{ top: "0" }}>Jours travaillés</Typography.Text>
            ),
            dataIndex: "workedDaysDisplay",
            render: (_, record) => (
                <>
                    {record.workedDaysDisplay >= 16 && record.workedDaysDisplay && (
                        <Tag color="green">
                            <span className="fw-bold">{record.workedDaysDisplay}</span>{" "}
                            Jour(s)
                        </Tag>
                    )}
                    {record.workedDaysDisplay < 16 && record.workedDaysDisplay > 8 && (
                        <Tag color="gold">
                            <span className="fw-bold">{record.workedDaysDisplay}</span>{" "}
                            Jour(s)
                        </Tag>
                    )}
                    {record.workedDaysDisplay <= 8 && record.workedDaysDisplay >= 0 && (
                        <Tag color="red">
                            <span className="fw-bold">{record.workedDaysDisplay}</span>{" "}
                            Jour(s)
                        </Tag>
                    )}
                    {record.workedDaysDisplay < 0 && (
                        <Tag color="red">
                            <span>{0}</span> Jour(s)
                        </Tag>
                    )}
                </>
            ),
        },
        {
            title: "S.fin du mois",
            dataIndex: "endOfMonthLeaveBalance",
        },
        {
            title: "S.cumulé",
            dataIndex: "sold",
            render: (_, record) => (
                <Tooltip title={record.exactValue}>{record.sold}</Tooltip>
            ),
        },
    ];

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: "Type",
                dataIndex: "_typeLeave",
            },
            {
                title: "Répondu par",
                dataIndex: "_answered_by",
                render: (_, requestedLeaves) => {
                    let responseElement;

                    if (requestedLeaves.status === "Pending") {
                        responseElement = <Tag color={"orange"}>En attente</Tag>;
                    } else if (requestedLeaves._answered_by) {
                        responseElement = (
                            <Tooltip
                                title={
                                    "Répondu à " +
                                    dayjs(requestedLeaves._answered_at).format("DD/MM/YYYY HH:mm")
                                }
                            >
                                <Typography.Text>
                                    {" "}
                                    {requestedLeaves._answered_by}{" "}
                                </Typography.Text>
                            </Tooltip>
                        );
                    } else {
                        responseElement = <Typography.Text>N/A</Typography.Text>;
                    }

                    return responseElement;
                },
            },
            {
                title: "Date début",
                dataIndex: "BeginingDate",
                render: (text) => (
                    <Typography.Text>{dayjs(text).format("DD/MM/YYYY")}</Typography.Text>
                ),
            },
            {
                title: "Date fin",
                dataIndex: "EndingDate",
                render: (text) => (
                    <Typography.Text>{dayjs(text).format("DD/MM/YYYY")}</Typography.Text>
                ),
            },
            {
                title: "Durée",
                render: (_, requestedLeaves) => {
                    let durationText;

                    if (requestedLeaves._typeLeave === "Autorisation") {
                        durationText = requestedLeaves._duration;
                    } else if (!requestedLeaves._is_half_day) {
                        durationText = requestedLeaves.DaysNumber + " Jour(s)";
                    } else {
                        durationText = requestedLeaves._is_half_day;
                    }

                    return <Typography.Text>{durationText}</Typography.Text>;
                },
            },
            {
                title: "Statut",
                dataIndex: "status",
                render: (_, record) => {
                    let statusElement;

                    if (record.status === "Pending") {
                        statusElement = <Tag color={"orange"}>En attente</Tag>;
                    } else if (record.status === "Accepted") {
                        statusElement = <Tag color={"green"}>Approuvée</Tag>;
                    } else if (record.status === "Validating") {
                        statusElement = <Tag color={"cyan"}>En attente d'approbation</Tag>;
                    } else if (record.status === "Rejected") {
                        statusElement = <Tag color={"red"}>Rejeté</Tag>;
                    } else if (record.status === "Canceled") {
                        statusElement = <Tag color={"geekblue"}>Annuler</Tag>;
                    } else if (record.status === "Unjustified") {
                        statusElement = <Tag color={"gold"}>Non encore justifié</Tag>;
                    } else if (record.status === "Failed") {
                        statusElement = (
                            <Tooltip title="Considérée comme absence non justifiée">
                                <Tag>Échue</Tag>
                            </Tooltip>
                        );
                    }

                    return statusElement;
                },
            },
        ];

        return (
            <Table
                rowKey={record.key}
                columns={columns}
                dataSource={record.monthlyLeaves}
                pagination={false}
            />
        );
        // }
    };

    return (
        <Modal
            width={1200}
            title={recordItem?.title}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Table
                columns={columns}
                expandable={{
                    expandedRowRender,
                }}
                scroll={{ x: "max-content" }}
                dataSource={recordItem.data}
                pagination={false}
            />
            <Typography.Paragraph className="pt-3 fs-6">
                <Typography.Text mark className="fs-6">
                    S.Cumulé
                </Typography.Text>{" "}
                ={" "}
                <Typography.Text mark className="fs-6">
                    (Jours travaillés - J.Fériés)
                </Typography.Text>{" "}
                /{" "}
                <Typography.Text mark className="fs-6">
                    (Jours travaillés + Total de jours non travaillés - J.Fériés)
                </Typography.Text>{" "}
                * 2.5
            </Typography.Paragraph>
        </Modal>
    );
};

SoldIncrementationPopupDetails.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    recordItem: PropTypes.shape({
        title: PropTypes.string,
        data: PropTypes.arrayOf(
            PropTypes.shape({
                fullname: PropTypes.string,
                matricule: PropTypes.string,
                startOfMonthLeaveBalance: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                holidays: PropTypes.number,
                special: PropTypes.number,
                yearly: PropTypes.number,
                sansSold: PropTypes.number,
                sickness: PropTypes.number,
                unjustified: PropTypes.number,
                absenceDaysDisplay: PropTypes.number,
                workedDaysDisplay: PropTypes.number,
                endOfMonthLeaveBalance: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                sold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                exactValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                monthlyLeaves: PropTypes.arrayOf(
                    PropTypes.shape({
                        _typeLeave: PropTypes.string,
                        _answered_by: PropTypes.string,
                        _answered_at: PropTypes.string,
                        status: PropTypes.string,
                        BeginingDate: PropTypes.string,
                        EndingDate: PropTypes.string,
                        DaysNumber: PropTypes.number,
                        _duration: PropTypes.string,
                        _is_half_day: PropTypes.oneOfType([
                            PropTypes.bool,
                            PropTypes.string,
                        ]),
                    })
                ),
            })
        ),
    }).isRequired,
};

export default SoldIncrementationPopupDetails;
