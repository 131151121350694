import { Spin, message, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

const DownloadFile = ({ path, name, disabled }) => {
  return (
    <a
      href={!disabled && path}
      download={name}
      target="_blank"
      rel="noreferrer" // Add this line
    >
      <Tag
        color={disabled ? "default" : "blue"}
        style={{ fontSize: "12px", padding: "5px 10px" }}
      >
        <FontAwesomeIcon
          icon={faFileArrowDown}
          style={{ marginRight: "5px" }}
        />
        {name}
      </Tag>
    </a>
  );
};


const Documents = () => {
  const [data, setData] = useState({});
  const [otherData, setOtherData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // async function fetchOtherData() {
    //   try {
    //     const response = await axiosInstance.get("/api/docs/get/me");

    //   } catch (error) {
    //     message.error(error?.response?.data?.message);
    //   }
    // }
    async function fetchData() {
      try {
        const response = await axiosInstance.get("/api/employees/profile");

        setData(response.data?.employee);
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
    // fetchOtherData();
  }, []);

  return (
    <div className="p-4">
      <div style={{ minHeight: "80vh" }}>
        <div style={{ maxHeight: "82vh", overflowY: "scroll" }}>
          <div className="container">
            <Typography.Title level={4}>🗂️ Mes Documents :</Typography.Title>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spin size="large" />
              </div>
            ) : (
              <div className="row">
                <div className="col-auto my-2">
                  <DownloadFile
                    path={data?._cin_File ?? null}
                    name={"cin-" + data?.fullName.split(" ").join("-")}
                    disabled={data?._cin_File ? false : true}
                  />
                </div>
                <div className="col-auto my-2">
                  <DownloadFile
                    path={data?._rib_File ?? null}
                    name={"rib-" + data?.fullName.split(" ").join("-")}
                    disabled={data?._rib_File ? false : true}
                  />
                </div>
                <div className="col-auto my-2">
                  <DownloadFile
                    path={data?.CV ?? null}
                    name={"cv-" + data?.fullName.split(" ").join("-")}
                    disabled={data?.CV ? false : true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
