import React, { useState } from "react";
import { Button, Form, Input, Modal, Select, message, Tooltip } from "antd";
import axiosInstance from "../../../../../Utils/axios";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const SysAdminApproveModal = ({
    record,
    index,
    refresh,
    setRefresh,
    availableEquipments,
    setAvailableEquipments,
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setLoading(true);
        try {
            const res = await form.validateFields();

            try {
                await axiosInstance.patch(
                    `api/equipments/affect/${res?.id_equipment}`,
                    {
                        _current_holder: record?.sender_id,
                    }
                );

                message.success("L'équipement a été affecté à " + record?.sender);
                const temp = availableEquipments.filter(
                    (elem) => elem?._id !== res?.id_equipment
                );
                setAvailableEquipments(temp);

                try {
                    const response = await axiosInstance.patch(
                        `/api/request/access/sysadmin/${record?._id}`,
                        {
                            status: true,
                            motif: res?.motif,
                        }
                    );

                    message.success(response?.data?.message);
                    setIsModalOpen(false);
                    setRefresh(!refresh);
                } catch (error) {
                    message.error(error?.response?.data?.message);
                }
            } catch (error) {
                message.error(error?.response?.data?.message);
            }
        } catch (error) {
            message.error(error?.errorFields[0]?.errors[0]);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Tooltip title="Approuver la demande">
                <Button
                    size="small"
                    shape="circle"
                    type="primary"
                    disabled={
                        record?.answered_by.length !== index || record?.status !== "PENDING"
                    }
                    onClick={showModal}
                >
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
            </Tooltip>
            <Modal
                title="Etes-vous sûr d'approuvé la demande ?"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Soumettre"
            >
                <Form layout="vertical" form={form}>
                    <Form.Item label="Équipements disponibles" name="id_equipment">
                        <Select>
                            {availableEquipments.map((elem) => {
                                return (
                                    <Select.Option value={elem?._id} key={elem?._id}>
                                        {elem?._categorie +
                                            " " +
                                            elem?._brand +
                                            " (" +
                                            elem?._ref +
                                            ")"}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Description" name="motif">
                        <Input.TextArea placeholder="Description..." rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

SysAdminApproveModal.propTypes = {
    record: PropTypes.shape({
        _id: PropTypes.string,
        sender: PropTypes.string,
        sender_id: PropTypes.string,
        answered_by: PropTypes.array,
        status: PropTypes.string,
    }),
    index: PropTypes.number,
    refresh: PropTypes.bool,
    setRefresh: PropTypes.func,
    availableEquipments: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string,
            _categorie: PropTypes.string,
            _brand: PropTypes.string,
            _ref: PropTypes.string,
        })
    ),
    setAvailableEquipments: PropTypes.func,
};
export default SysAdminApproveModal;
