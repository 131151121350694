import React, { useEffect, useState } from "react";
import {
    Button,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Tooltip,
} from "antd";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const EditLeaveModal = ({ record }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form
            .validateFields()
            .then((values) => {
                setIsModalOpen(false);
            })
            .catch((error) => {
                message.error("Veuillez valider tous les champs");
            });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        form.setFieldsValue({
            BeginingDate: dayjs(record?.BeginingDate),
            EndingDate: dayjs(record?.EndingDate),
        });
    }, [record]);

    const getDurationText = () => {
        if (record?._typeLeave !== "Autorisation") {
            const halfDayText = record?._is_half_day
                ? ` (${record?._is_half_day})`
                : "";
            return `${record?.DaysNumber} Jour(s)${halfDayText}`;
        } else {
            return record?._duration;
        }
    };

    return (
        <>
            <Tooltip title="Modifier la demande">
                <Button type="primary" size="small" shape="circle" onClick={showModal}>
                    <FontAwesomeIcon icon={faPen} />
                </Button>
            </Tooltip>
            <Modal
                title={`Modifier la demande de << ${record?._typeLeave} >>`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Divider orientation="left">Recapulatif de la demande</Divider>
                <Descriptions bordered>
                    <Descriptions.Item span={3} label={"Demandeur"}>
                        {record?.fullName}
                    </Descriptions.Item>
                    <Descriptions.Item span={3} label="Date de soumission">
                        {dayjs(record?._posted_date).format("DD/MM/YYYY HH:mm")}
                    </Descriptions.Item>
                    <Descriptions.Item
                        span={3}
                        label={
                            record?._typeLeave !== "Autorisation" ? "Date de début" : "Date"
                        }
                    >
                        {dayjs(record?.BeginingDate).format("DD/MM/YYYY")}
                    </Descriptions.Item>
                    {record?._typeLeave !== "Autorisation" && (
                        <Descriptions.Item span={3} label="Date de fin">
                            {dayjs(record?.EndingDate).format("DD/MM/YYYY")}
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item span={3} label="Durée">
                        {getDurationText()}{" "}
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
                <Form form={form} layout="vertical">
                    <Form.Item
                        label={"Date de début"}
                        name="BeginingDate"
                        rules={[
                            {
                                required: true,
                                message: "Veuillez saisir une date de début",
                            },
                        ]}
                    >
                        <DatePicker className="w-100" />
                    </Form.Item>
                    <Form.Item
                        label={"Date de fin"}
                        name="EndingDate"
                        rules={[
                            {
                                required: true,
                                message: "Veuillez saisir une date de fin",
                            },
                        ]}
                    >
                        <DatePicker className="w-100" />
                    </Form.Item>
                    <Form.Item
                        label="Motif de la modification"
                        name="edit_motif"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: "Veuillez saisir le motif de la modification",
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

EditLeaveModal.propTypes = {
    record: PropTypes.shape({
        BeginingDate: PropTypes.string,
        EndingDate: PropTypes.string,
        _typeLeave: PropTypes.string,
        fullName: PropTypes.string,
        _posted_date: PropTypes.string,
        DaysNumber: PropTypes.number,
        _is_half_day: PropTypes.string,
        _duration: PropTypes.string,
    }),
};
export default EditLeaveModal;
